.cart-page {
  height: 100%;

  .container-cart {
    padding: 86px 24px 16px;

    .check_out_product {
      height: calc(100% - 70px);

      .product_summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        padding-bottom: 24px;

        .total {
          display: flex;

          .shoppingCart {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: var(--global-button-background-color, #db4d29);
            margin-right: 5px;
          }

          .quantity {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #282828;

            .total-quantity {
              padding-right: 5px;
            }
          }
        }

        .add {
          display: flex;
          align-items: center;

          .add_icon {
            cursor: pointer;
            display: flex;

            svg {
              path {
                stroke: var(--global-button-background-color, #DB4D29);
              }
            }
          }

          .add_button {
            margin-left: 14px;

            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--global-button-background-color, #DB4D29);
            cursor: pointer;
          }
        }

        .disabled-btn-add {
          cursor: not-allowed;
          opacity: 0.5;

          .add_icon,
          .add_button {
            cursor: not-allowed;
          }
        }
      }

      .product_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        height: 48px;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 12px;
        padding-left: 0px;
      }

      .product_summary,
      .product_title {
        background-color: #fff;
      }

      .product_title.product-title-mobile {
        display: none;
      }

      .product_detail {
        overflow-y: auto;
        height: calc(var(--window-height) - 222px);

        .noProductInCart {
          margin-top: 127px;
          text-align: center;
        }

        .noProductInCartText {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-top: 24px;
        }

        .checkout-order-item-theme-2 .order-item .box-combo {
          width: 100%;
        }
      }

      .product_detail::-webkit-scrollbar {
        display: none;
      }
    }

    .button-create-order-and-payment {
      * {
        font-family: inherit;
      }

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      padding: 12px 16px;

      .create-order-button {
        width: 100%;
        height: 48px;
        background: #db4d29;
        border-radius: 12px;

        span {
          color: #FFF;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .create-order-button:disabled {
        background: #ecb9ac;
      }
    }
  }
}

.order-detail-pos-page {
  .order-item {
    .order-item-info {
      flex: 3;
    }

    .order-item-quantity .order-item-total {
      flex: 1;
    }
  }

  .loading-full-screen {
    .overlay-loading-full-screen {
      left: 0px;
    }
  }
}

.notify-dialog-confirm-check-login {
  .ant-modal-content {
    .ant-modal-header {
      background: var(--global-button-background-color, #db4d29);
    }

    .ant-modal-body {
      padding: 18px;

      .notification-dialog-content {
        .track-your-order {
          color: #282828;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.15px;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .continue-without-login-btn {
        background: none;
        color: var(--global-button-background-color, #db4d29);
        border: none;
        outline: none;
        cursor: pointer;
        box-shadow: none;
        text-align: center;
      }

      .login-btn {
        width: 100%;
        max-width: 230px;
        background: var(--global-button-background-color, #FFF);
        color: var(--global-button-text-color, #db4d29);
        border: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .container-cart {
    padding: 16px 24px;

    .check_out_product {
      .product_summary {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 12px;

        .total {

          .shoppingCart,
          .quantity {
            font-size: 18px !important;
            line-height: normal;
          }
        }
      }

      .product_detail {
        overflow-y: auto;
        height: calc(var(--window-height) - 218px) !important;

        .checkout-order-item-theme-2 {
          &:last-child {
            margin-bottom: 16px;
            padding-bottom: 32px;
          }
        }
      }

      .product_title.product-title-mobile {
        display: flex !important;
      }

      .product_title.product-title-web {
        display: none;
      }
    }
  }

  .cart-page {
    .container-cart {
      padding: 86px 16px 16px;

      .check_out_product {
        .product_summary {
          gap: 4px;

          .total {

            .shoppingCart,
            .quantity {
              line-height: normal;
            }
          }

          .add {
            .add_button {
              margin-left: 6px;
            }
          }
        }

        .product_detail {
          .checkout-order-item-theme-2 {
            .order-item {
              .order-item-info {
                gap: 8px;
              }
            }
          }
        }
      }
    }
  }

  .order-detail-pos-page {
    .product_summary,
    .product_title {
      transition: all .5s ease;
    }

    &.hide-summary {
      transition: top 1s ease-in-out;

      .container-cart {
        .check_out_product {

          .product_summary,
          .product_title {
            opacity: 0;
          }
        }
      }
    }
  }
}