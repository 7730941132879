.popover-wrapper-overwrite {
    position: fixed;
    top: 100px;
    left: 25%;
  
    .ant-modal {
      .ant-modal-content {
        max-height: 85dvh;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 16px;
        padding: 24px 16px;
        overflow: hidden;
        transform: translate(0, 0);
  
        .ant-modal-body {
          width: 100%;
          height: fit-content;
          overflow: auto;

          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: transparent;
          }
          
          &::-webkit-scrollbar {
            width: 4px;
            height: 0;
          } 
          
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #eeeeee;
          }
        }
      }
    }
  }
  
  @media (max-width: 1199px) and (min-width: 576px) {
    .popover-wrapper-overwrite {
      .ant-modal {
        width: 100dvw !important;
        max-width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        height: 100dvh !important;
        top: 0px;
  
        .ant-modal-content {
          width: 100dvw !important;
          padding: 24px 16px;
          position: fixed;
          bottom: 0px;
          max-height: 90%;
          overflow: scroll;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          animation-delay: 0s;
          animation-duration: 0.8s;
          animation-name: drawer-animation;
    
          @keyframes drawer-animation {
            from {
              transform: translate(0, 200%);
            }
            to {
              transform: translate(0, 0);
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 575px) {
    .popover-wrapper-overwrite {
      .ant-modal {
        width: 100dvw !important;
        max-width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        height: 100dvh !important;
        top: 0px;
  
        .ant-modal-content {
          width: 100dvw !important;
          padding: 24px 16px;
          position: fixed;
          bottom: 0px;
          max-height: 90%;
          gap: 20px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
  
          .ant-modal-header {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  