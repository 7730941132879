.star-rating {
  height: 32px;
  display: flex;
  .value-rate {
    color: #282828;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    margin-left: 8px;
  }
  .number-of-review {
    color: #959595;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
    margin-left: 4px;
  }
}
