.select-background-component {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #282828;
  margin-top: 32px;

  .background-color {
    display: flex;
  }
}
