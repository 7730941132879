.banking-detail {
  background: #000;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  padding: 16px 12px;
  border-radius: 12px;
  .banking-detail-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
  }
  .banking-detail-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    div {
      display: flex;
      .bank-content-title {
        color: #959595;
      }
      .bank-content-description {
        font-weight: 500;
        height: 28px;
        &.copy-content {
          display: flex;
          justify-content: space-between;
        }
      }
      &.bank-name,
      &.bank-account-number,
      &.bank-account-holder-name,
      &.bank-account-content {
        flex-direction: column;
        gap: 10px;
      }
      .copy-icon {
        border: none;
        background: none;
        box-shadow: none;
        svg {
          width: 20px;
        }
      }
    }
    span {
      font-size: 16px;
    }
  }
  .qr-code {
    display: flex;
    justify-content: center;
  }
}
