$PRIMARY_COLOR: #db4d29;

.blog-theme2 {
  .pagination-blog-theme2 {
    margin-bottom: 32px;

    .ant-pagination .ant-pagination-item-active {
      border-color: unset !important;
      border: none;
      color: $PRIMARY_COLOR;
      text-decoration: underline;
      background-color: unset !important;

      a {
        color: $PRIMARY_COLOR;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      display: none;
    }
  }

  .session-post-content {
    .filter-post {
      margin-left: 48px;

      .post-recent-blog-theme2-desktop {
        position: sticky;
        top: 80px;
      }
    }

    .post-search-theme2 {
      flex-direction: column;

      .input-search {
        margin-top: 12px;
        width: 396px;
        flex: 1;
      }
    }

    .post-detail-theme2 {
      .post-content-detail-theme2 {
        .post-content {
          background-color: unset !important;
          border-radius: 0px !important;
        }
      }
    }
  }

  .no-post-content {
    .no-post {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30vh;

      .text-no-post {
        color: #282828;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 16px;
      }
    }
  }

  #blogList {
    .filter-post {
      .post-recent-blog-theme2-desktop {
        position: sticky;
        top: 80px;
      }
    }

    .filter-post-blog-detail-theme2 {
      padding-top: 26px;
    }
  }
}

@media (max-width: 1199px) {
  .blog-theme2 {
    #blogList {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      flex-direction: unset !important;
      padding-top: 0px;

      .post-content-list {
        display: flex;
        flex-direction: column;
        max-width: 90vw;
      }

      .post-content-theme2 {
        width: 100%;

        .post-content {

          .blog-list-page-content,
          .description {
            width: 100%;
            word-break: break-word;
            max-width: 100vh;
          }

          .filter-post {
            padding: 0px !important;
          }

          .label {
            max-width: 100vh;
          }
        }
      }

      .filter-post {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 16px;
        margin-left: 0px !important;

        .post-search-theme2 {
          .input-search {
            width: 100%;

            .input-search-blog {
              width: 100% !important;
            }
          }
        }
      }

      .post-search-blog-theme2 {
        width: 100%;
      }

      .blog-list-theme2-not-desktop {
        display: flex;
        flex-direction: column-reverse;

        .post-content-list {
          .post-content-theme2 {}
        }

        .filter-post {

          .filter-post-blog-theme2,
          .post-category-theme2 {
            margin-bottom: 24px;
            width: 90vw;
          }

          .post-category-theme2 {
            margin-bottom: 24px;
            width: 90vw;
          }
        }
      }

      .blog-detail-theme2-by-id {
        display: flex;
        flex-direction: column;
      }

      .filter-move-on-top-theme2 {
        display: flex;
        flex-direction: column-reverse !important;
      }

      .post-category-theme2 {
        .category-list .category {
          flex-direction: row !important;
          flex-flow: wrap;
          justify-content: center;

          .category-item {
            margin-right: 8px;
          }
        }
      }

      .post-recent-blog-theme2 {
        width: 90vw;

        .post-viewed-most-theme2 {
          margin-bottom: 16px;
        }
      }

      .no-post-content {
        .no-post {
          margin-right: 0px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 48px;
        }
      }
    }

    .session-post-content {
      .filter-post {
        width: 90vw;

        .filter-post-blog-theme2,
        .post-category-theme2,
        .post-viewed-most-theme2 {
          border-radius: 0px !important;
          padding: 0px !important;
          margin-bottom: 8px;
          background-color: unset !important;
        }
      }

      .post-content-list {

        .post-detail-theme2,
        .post-content-detail-theme2 {
          width: 90vw;
        }

        .post-content-detail-theme2 {

          .thumbnail,
          .thumbnail-url {
            width: 90vw;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .blog-theme2 {
    margin-bottom: 16px;

    .session-post-content {
      padding-top: 0px !important;

      .filter-post {
        margin-left: 0px;
      }

      .post-search-theme2 {
        .input-search {
          width: 365px !important;
        }
      }
    }

    #blogList {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      flex-direction: unset !important;

      .post-content-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .post-content-theme2 {
        .post-content {
          width: 90vw;

          .blog-list-page-content,
          .description {
            width: 100%;
            word-break: break-word;
            max-width: 90vw;
          }

          .filter-post {
            padding: 0px !important;
          }

          .label {
            max-width: 90vw;

            .label-text {
              max-width: 82vw;
            }
          }
        }
      }

      .filter-post {
        margin-left: 0px !important;

        .filter-post-blog-theme2 {
          .post-search-blog-theme2 {
            width: 100% !important;
          }
        }

        .post-search-theme2 {
          .input-search {
            width: 100%;

            .input-search-blog {
              min-width: auto !important;
              width: 100% !important;
            }
          }
        }
      }

      .blog-list-theme2-not-desktop {
        display: flex;
        flex-direction: column-reverse;
      }

      .blog-detail-theme2-by-id {
        display: flex;
        flex-direction: column;
      }

      .filter-move-on-top-theme2 {
        display: flex;
        flex-direction: column-reverse !important;
      }

      .post-category-theme2 {
        .category-list .category {
          flex-direction: row !important;
          flex-flow: wrap;
          justify-content: center;

          .category-item {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .blog-theme2 {
    #blogList {
      .blog-list-content {
        padding-top: 60px;
      }
    }
  }
}