.reserve-table-wrapper {
  height: 100%;

  .container-fluid {
    width: 100%;
  }
  .container {
    width: 80%;
    margin: 0 auto;
  }

  .reserve-table-section {
    padding-bottom: 80px;
  }

  .reserve-table-header {
    width: 100%;
    height: 322px;
    text-align: center;
    background-size: cover !important;
  }
  .reserve-table-header h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #fff;
    padding-top: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.reserve-table-header-customize-theme-2 {
}
