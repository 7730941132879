.product-category {
  scroll-margin-top: 108px;
  .product-category-header {
    height: 40px;
    color: var(--color-customize-title-text-color-1, #282828);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 0px 0px 12px 0px;
    margin-left: 20px;
    margin-bottom: 24px;
    .product-quantity {
      margin-left: 5px;
      color: var(--color-customize-title-text-color-1, #282828);
    }
  }
  .product-category-body {
    width: 100%;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 12px 24px;
    .custom-col {
      padding: 12px;
      border-radius: 21.395px;
      border: 1.337px solid var(--color-default-border-background, #e6e6e6);
      background: var(--color-default-body-text-1, #fff);
    }
    .custom-col:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 575px) {
  .product-category {
    scroll-margin-top: 0px;
    margin-top: 0px;
    .product-category-header {
      padding: 8px 0px;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      margin-left: 15px;
      margin: 0px 0px 0px 0px;
      width: fit-content;
      margin-left: 20px;
      margin-bottom: 28px;
    }
    .product-category-body {
      border-radius: 24px;
      padding: 0px 16px;
    }
  }
}
@media (max-width: 431px) {
  .product-category {
    .product-category-body {
      border-radius: 24px;
      padding: 12px;

      .product-list {
        margin: 0 !important;

        div.product-detail:nth-child(2n+1) {
          padding: 0 8px 0 0 !important;
        }

        div.product-detail:nth-child(2n) {
          padding: 0 0 0 8px !important;
        }
      }
    }
  }
}
