.fnb-select-multiple {
  width: 100%;
  .ant-select-selector:hover {
    border: none !important;
  }
  .ant-select-selector {
    border-radius: 12px !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
    background: #ffffff !important;
    min-height: 60px !important;
    border: none !important;
  }

  .ant-select-selector .ant-select-selection-search {
    width: 100% !important;

    .ant-select-selection-search-input {
      height: 60px !important;
      font-size: 18px !important;
      padding-left: 5px !important;
    }
  }

  .ant-select-selector .ant-select-selection-placeholder {
    line-height: 60px !important;
    font-size: 18px !important;
    padding-left: 5px !important;
  }

  .ant-select-selector .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    letter-spacing: 0.3px !important;
    color: #000000 !important;
    padding-left: 10px !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  .ant-select-arrow {
    user-select: all !important;
    padding-right: 5px !important;
  }
}

.fnb-select-multiple.ant-select-focused {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px #50429b !important;
  border-radius: 12px !important;
}

.fnb-select-multiple.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
}

.fnb-select-multiple.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: none !important;
}

.fnb-select-multiple.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #f6f6f6 !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.fnb-select-multiple-dropdown {
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 12px !important;
}

.fnb-select-multiple-dropdown .ant-select-item-option {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: 0.3px !important;
  color: #5a5a5a !important;
  padding: 10px 15px 0px 15px !important;
  height: 48px !important;
}

.fnb-select-multiple-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #817cba !important;
  font-weight: 600 !important;
  background-color: #ffffff !important;
}

.fnb-select-multiple-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #f9fbff !important;
  border-radius: 6px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.fnb-select-multiple .ant-select-selector {
  min-height: 60 !important;
  height: auto !important;
}

.fnb-select-multiple .ant-select-selection-item {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  position: relative;
}

.fnb-select-multiple .ant-select-selection-item-content {
  padding: 12px 16px;
  padding-right: 30px;
  height: 60px;
  background: #f9f8ff;
  border-radius: 16px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.fnb-select-multiple .ant-select-selection-item-remove {
  position: absolute;
  right: 15px !important;
  background: #d9d9d9;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
