$white-color: #fff;
$light-orange-color: #d6b570;
$width: 368px;

@mixin background-premium-icon {
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  width: 40px;
  background: transparent;
  background-image: url("../../../../assets/images/premium-quality.png");
  background-size: contain;
   background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  position: relative;
  top: 2px;
}

.loyalty {
  color: $white-color;
  
  font-style: normal;
  margin: 0px 12px 36px 0px;
  .loyalty-point,
  .loyalty-not-point {
    width: $width;
    height: 286px;
    background-image: url("../../../../assets/images/loyalty-point.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px;

    .member-info {
      display: flex;
      height: 64px;
      width: 100%;

      .upload {
        max-width: 56px;
        max-height: 56px;
        .profile-image {
          position: relative;

          .upload-profile-img {
            position: absolute;
            right: -4px;
            bottom: -4px;
            cursor: pointer;
            width: 20px;
            height: 20px;
          }

          .profile-img {
            border-radius: 60px;
            width: 56px;
            height: 56px;
          }
        }
      }

      .name-point {
        padding-left: 8px;

        .name {
          font-size: 20px;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-width: calc($width - 60px);
          width: 100%;
        }
        .point {
          display: flex;
          align-items: center;
          .rank-icon {
            display: flex;
            justify-items: center;
            align-items: center;

            .thumbnail-url-rank {
              width: 24px;
              height: 24px;
              border-radius: 56px;
            }
          }

          .rank-points {
            min-width: 150px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 40px;
            letter-spacing: 0.3px;
            margin-left: 4px;

            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &:hover {
              cursor: pointer;
            }
          }
          .rank {
            display: flex;
            align-items: end;
            padding-bottom: 8px;
            button {
              min-width: unset;
              width: 97px;
              height: 32px;
              background: #d6b570;
              border-radius: 26.4183px;
              border: none;
              outline: none;
              color: $white-color;
              
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              padding: 0px 8px;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .bar-code {
      max-width: 344px;
      max-height: 80.8px;
      width: 100%;
      background-color: $white-color;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 16px 0px;
      flex-direction: column;
      padding-bottom: 8px;

      .value-code {
        width: 100px;
        height: 16px;
        
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #282828;
        letter-spacing: 0.24em;
      }
    }

    .range-slider {
      margin: 24px 0px;
      .fnb-range-slider {
        width: 100%;
      }

      .fnb-range-slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        cursor: pointer;
        outline: none;
        border-radius: 15px;
        height: 6px;
        background: #ccc;
        -webkit-transition: .2s;
        transition: opacity .2s;
      }

      .fnb-range-slider::-webkit-slider-thumb {
        @include background-premium-icon;
      }

      /* Thumb: Firefox */
      .fnb-range-slider::-moz-range-thumb {
        @include background-premium-icon;
      }

      .fnb-range-slider-max::-webkit-slider-thumb {
        @include background-premium-icon;
        right: -10px;
      }
      .fnb-range-slider-max::-moz-range-thumb {
        @include background-premium-icon;
        right: -10px;
      }
      .fnb-range-slider-min::-webkit-slider-thumb {
        @include background-premium-icon;
        left: -10px;
      }
      .fnb-range-slider-min::-moz-range-thumb {
        @include background-premium-icon;
        left: -10px;
      }
    }

    .need-point-text {      
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      .score {
        color: #d6b570;
      }
    }
  }

  .loyalty-not-point {
    width: $width;
    height: 211px;
    background-image: url("../../../../assets/images/loyalty-not-point.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px 8px;

    .member-info {
      .name-point {
        .name {
          min-height: 24px !important;
          font-size: 20px;
        }
        .point {
          margin-top: 8px;
          .rank {
            button {
              height: 28px !important;
              max-width: 200px;
              width: 100% !important;
              min-width: 100px;
            }
          }
        }
      }
    }

    .bar-code {
      max-width: 360px;
      height: 372px;
      margin-top: 24px;
    }
  }
}

@media (max-width: 576px) {
  .loyalty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;

    .loyalty-point,
    .loyalty-not-point {
      background-origin: content-box;
      .member-info {
        padding: 12px;
        .name-point {
          .name {
            max-width: 260px;
            min-height: 24px !important;
            .fnb-typorapyh-ellipsis {
              max-width: 220px !important;
            }
          }
          .point {
            .rank-points {
              min-width: 120px;
            }
          }
        }
      }

      .bar-code {
        max-width: 310px;
        margin: 0 auto;
        margin-top: 22px;
      }
      .range-slider,
      .need-point-text {
        padding: 0px 12px;
      }
    }

    .loyalty-not-point {
      .member-info {
        .name-point {
          .name {
          }
          .point {
            .rank-points {
              min-width: 100px;
            }
          }
          .rank {
            margin-left: 0px !important;
            button {
              max-width: 200px;
              width: 100% !important;
            }
          }
        }
      }
      .bar-code {
        height: 372px;
        margin-top: 28px;
      }
    }

    .loyalty-point {
      .range-slider {
        margin-bottom: 12px;
      }
    }
  }
}
