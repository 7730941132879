.collapse-customize {
  .ant-collapse-expand-icon {
    margin: 0 !important;
    display: flex;
    order: 0 !important;
    svg {
      margin-right: 0 !important;
    }
  }
  .ant-collapse-item {
    margin: 0 0 24px 0 !important;
  }
  .ant-collapse-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px 12px 16px !important;
    height: 60px !important;
    color: #50429b;
    background: #f7f5ff;
    border-radius: 12px !important;
    fill: #50429b;

    // checkbox eye icon
    .ant-checkbox {
      background: transparent;
      border: unset;
    }
    .ant-checkbox-inner {
      border: unset;
      border-color: unset;
      background: unset;
      background-image: url(../../assets/icons/eye_open.svg);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      border: unset;
      border-color: unset;
      background: unset;
      background-image: url(../../assets/icons/eye-close-icon.svg);
    }
    .checkbox-checked::after {
      border-color: transparent;
    }
    .ant-checkbox-checked::after {
      border-color: transparent;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: none;
    }

    .visible-component {
      .ant-checkbox-inner {
        background-image: url(../../assets/icons/eye-close-icon.svg);
        height: 24px;
        display: inline-block;
        padding: 0 0 0 0px;
        width: 50px !important;
        background-repeat: no-repeat;
        border: none !important;
        z-index: 1000;
        background-color: transparent;
      }
      .ant-checkbox-checked::after,
      .ant-checkbox-checked .ant-checkbox-inner {
        background: url(../../assets/icons/eye_open.svg) no-repeat !important;
        height: 24px;
        display: inline-block;
        padding: 0 0 0 0px;
        width: 50px !important;
        background-repeat: no-repeat;
        border: none !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border: none !important;
      }
      span.ant-checkbox.ant-checkbox-checked {
        background-color: transparent;
      }
      span.ant-checkbox {
        background-color: transparent;
      }
      .icon-right-svg-hover:hover path {
        fill: #50429b;
      }
    }
  }
  .ant-collapse-header-text {
    color: #50429b;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    width: 100%;
    margin-left: 0 !important;
    .ant-row {
      margin-left: 0 !important;
    }
    .mr-16 {
      margin-left: 16px !important;
    }
    .text-heard {
      padding-left: 0 !important;
      margin-left: 0 !important;
    }
  }
  .ant-collapse-item.ant-collapse-item-active {
    .ant-collapse-header {
      background: #50429b;
      fill: #fff;
      color: #fff;
    }
    .ant-collapse-header-text {
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }
    .ant-col.color-icon {
      svg > circle,
      svg > path {
        stroke: white !important;
      }
    }
  }
  .icon-collapse {
    margin: 18px 16px 18px;
  }
  .ant-row {
    .ant-collapse {
      .ant-collapse-item {
        margin-left: 0 !important;
      }
    }
  }
}
.ant-collapse {
  background-color: #fff !important;
}
.collapse-sub .ant-collapse-content-box {
  padding: 0 0 0 24px !important;
}
.header-collapse {
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
  }
}
.color-icon:hover {
  cursor: pointer;
}

.collapse-sub .ant-collapse-item-disabled > .ant-collapse-header,
.collapse-sub .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
