.header-cart-checkout-scan-qr {
  background-color: #db4d29;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;

  .header-cart-checkout-scan-qr-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    span {
      font-weight: 700;
      font-size: 20px;
    }
  }

  svg {
    path {
      fill: #fff;
    }
  }

  .header-cart-checkout-scan-qr-right {
    :where(.css-dev-only-do-not-override-snrpu6).ant-btn-default {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

@media (max-width: 575px) {
  .header-cart-checkout-scan-qr {
    .header-cart-checkout-scan-qr-left {
      gap: 12px;

      a {
        display: flex;
      }

      span {
        font-size: 18px;
      }
    }
  }
}