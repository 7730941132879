.action-button-group {
  padding: 10px;
  font-style: normal;
  .action-button {
    border-radius: 18px;
  }
  .padding-left-5px {
    padding-left: 5px !important;
  }

  .btn-small {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 15px;
    height: 100%;
    .icon {
      padding: 10px;
      margin: auto;
      border-radius: 90px;
      width: 70px;
    }
    .text-wrapper {
      padding-top: 5px;
      letter-spacing: 0;
      position: relative;
    }
  }

  .btn-large {
    font-size: 22px;
    font-weight: 700;
    padding: 48px 20px;
    height: 100%;
    .icon {
      padding: 10px;
      margin: auto;
      border-radius: 90px;
      width: 75%;
      height: 75%;
    }
    .text-wrapper {
      padding-top: 10px;
      letter-spacing: 0;
      position: relative;
    }
  }
}

@media (min-width: 769px) {
  .action-button-group {
    .btn-small {
      font-size: 24px;
      padding: 20px 30px;
      .icon {
        padding: 15px;
        width: 100px;
      }
      .text-wrapper {
        padding-top: 15px;
        font-size: 16px;
      }
    }

    .btn-large {
      padding: 72px 60px;
      .icon {
        padding: 30px;
      }
      .text-wrapper {
        padding-top: 10px;
        font-size: 22px;
      }
    }
  }

}
