// Color: https://www.figma.com/file/XBzTWl8VKGqsHTIg4OtGw6/%5BAdmin-Portal%5D-UI-Components-Kit?node-id=1%3A2&mode=dev
/*
NOTE:
- If add more colors in this file, please also add 
 in go-food-beverage\src\theme.jsx

*/
// Default 
$black: #2F2D39;
$white: #fff;
$bg-disable: #D8DAE1;

// Primary
$fnb-200 : #40367D;
$fnb-100: #50429B;
$fnb-90: #5A4BAF;
$fnb-80: #6E5DC8;
$fnb-70: #7461DB;
$fnb-60: #8574E7;
$fnb-50: #9B8CF2;
$fnb-40: #B9AEFA; 
$fnb-30: #E1DBFF;
$fnb-20: #F5F2FF;

//Secondary
$secondary-200 : #833E00;
$secondary-100: #AF5300;
$secondary-90: #DA6800;
$secondary-80: #FF7D07;
$secondary-70: #FF8C24;
$secondary-60: #FFA350;
$secondary-50: #FFBA7C;
$secondary-40: #FFD1A7;
$secondary-30: #FFE4CC;
$secondary-20: #FFFAF5;

//Success
$success-200 : #024C1B;
$success-100: #036523;
$success-90: #047E2C;
$success-80: #059735;
$success-70: #05A83B;
$success-60: #06C946;
$success-50: #1AF864;
$success-40: #5BFA90;
$success-30: #B5FDCC;
$success-20: #DEFEE9 ;


//Warning
$warning-200 : #A4610E;
$warning-100: #B4720E;
$warning-90: #D08B0B;
$warning-80: #E2A108;
$warning-70: #F7B801;
$warning-60: #FEC92E;
$warning-50: #FED662;
$warning-40: #FFE18A;
$warning-30: #FFEBB1;
$warning-20: #FFF5D8;

//Critical
$critical-200 : #580B0B;
$critical-100: #820014;
$critical-90: #780F0F;
$critical-80: #BA1717;
$critical-70: #DB1B1B;
$critical-60: #E73737;
$critical-50: #EB5A5A;
$critical-40: #f18989;
$critical-30: #f6b8b8;
$critical-20: #FCE7E7;

//GRAY SCALE
$gray-200 : #45434F;
$gray-100: #585664;
$gray-90: #6B6879;
$gray-80: #767387;
$gray-70: #8B8899;
$gray-60: #A5A3B0;
$gray-50: #B8B6C1;
$gray-40: #CBCAD2;
$gray-30: #DFDEE3;
$gray-20: #F2F2F4;
$gray-10: #F2F2F2;


// Colors
$color-primary: #fc9434;
$color-active: #e47712;

$color-white: #ffffff;
$color-light-white: #fbfbfb;
$color-purple: #463796;
$color-light-purple-1: #50429b;
$color-light-purple-2: #817cba;
$color-light-white-purple: #ecf1f9;

$color-black: #000000;
$color-light-black-1: #212121;
$color-light-black-2: #2c2c2c;
$color-light-black: #454545;

$color-red: #fc0d1b;
$color-light-red: rgba(219, 27, 27, 0.2);
$color-green: #33b530;
$color-light-green: rgba(51, 181, 48, 0.2);

$color-light-blue: #428bc1;

$bg-header-color: #f7f7f7;
$bg-btn-cancel-color: #f9f9f9;
$bg-overlay-color-1: rgba(0, 0, 0, 0.5);
$bg-overlay-color-2: rgba(0, 0, 0, 0.7);

/// Primary & secondary color
$color-50429B: #50429b;

$color-2B2162: #2b2162;
$color-817CBA: #817cba;
$color-A5ABDE: #a5abde;
$color-ECF1F9: #ecf1f9;
$color-FF8C21: #ff8c21;
$color-FF7B00: #ff7b00;
$color-FAE6D6: #fae6d6;

/// Extra color
$color-F96E43: #f96e43;
$color-FFF0EC: #fff0ec;
$color-F9CC66: #f9cc66;
$color-FFFAF0: #fffaf0;
$color-33B530: #33b530;
$color-ECF5ED: #ecf5ed;
$color-FC0D1B: #fc0d1b;
$color-FFE6E7: #ffe6e7;
$color-F9FBFF: #f9fbff;
$color-F7F5FF: #f7f5ff;

///Font size
$font-size-20: 20px;


$main-color: #50429b;
$text-placeholder: #9F9F9F;

//status
$color-new: #022BFF;
$bg-new: #B1E8FF;