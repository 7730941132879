.login_content_theme2 {
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 0px;
  .item-content {
    display: flex;
    width: 100%;
    align-items: start;
    padding: 16px;
    .title {
      overflow: hidden;
      color: #282828;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .icon {
    cursor: pointer;
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: start;
    color: #000000;
    cursor: pointer;
    margin-left: 5px;
  }
}
