.my-profile-address-list {
  font-style: normal;
  .btn-primary {
    width: 135px;
    height: 48px;
    background: #db4d29;
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #fef7ed;
    border: none;
    cursor: pointer;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 40px;
    .address-card-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 21px;
    }
    .title {
    }
    .btn-addnew {
    }
  }
  .content {
    min-height: 314px;
    height: fit-content;
    width: 100%;
    .no-data-found-container {
      min-height: inherit;
      display: flex;
      align-items: center;
      .no-data-found {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
          margin: 0;
          padding: 0;
          text-align: center;
        }
        //   img {
        //     width: auto;
        //     aspect-ratio: 312/255;
        //   }
      }
    }
    .address-container {
      padding: 0 0px 40px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: auto;
      height: 100%;
      .address-table-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6e6;
        padding: 0px 16px;
        padding-bottom: 16px;
        .address-item {
          padding-right: 16px;
          .address-name-type {
            display: flex;
            gap: 16px;
            .address-icon {
              height: 24px;
              width: 24px;
            }
            .address-info-container {
              display: flex;
              flex-direction: column;
              gap: 10px;
              .address-label {
                overflow-wrap: break-word;
                word-break: break-word;
              }
              .work-lable {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
              }
              .home-lable {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
              }
              .address-name {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
              }
              .default-lable {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #db4d29;
                border: 1px solid #db4d29;
                border-radius: 26.4183px;
                width: fit-content;
                padding: 4px 12px;
              }
            }
          }
        }
        .address-navigation {
          flex: none;
          display: flex;
          flex-direction: column;
          align-items: end;
          gap: 10px;
          .default-setting {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 28px;
            background: #f9f1df;
            border-radius: 26.4183px;
            height: 28px;
            width: 137px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

@media (max-width: 740px) {
  .profile-page-background .profile-page-wrapper .mobile-address-content {
    margin-top: 60px;
  }
  .profile-right-side {
    .header {
      position: absolute;
      right: 0;
      top: 140px;
      z-index: 10;
      border-bottom: none;
      .address-card-title {
        display: none;
      }
    }
    .content {
      .no-data-found {
        img {
          max-width: 300px;
          height: auto;
        }
      }
      .address-container {
        .address-table-item {
          flex-direction: column;
          .address-item {
            .address-name-type {
              svg {
                flex-shrink: 0;
              }
            }
          }
          .address-navigation {
            align-items: start;
            margin-left: 40px;
            margin-top: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .profile-right-side {
    .content {
      .no-data-found {
        img {
          max-width: 224px;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 428px) {
  .profile-page-background {
    .page-container {
      width: calc(100% - 30px);
    }
    .profile-page-wrapper {
      .navigate-profile-page {
        margin-right: 16px;
        margin-left: 16px;
      }
    }
  }
  .profile-right-side {
    .header {
      top: 247px;
    }
  }
}
