.reservation-status-theme2 {
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.reservation-status-wait-to-confirm-theme2 {
    color: #428BC1;
    background: #E4EFF6;
}

.reservation-status-confirmed-theme2 {
    background: #DBD6FF;
    color: #2B2162;
}

.reservation-status-serving-theme2 {
    background: #FFE8D2;
    color: #FF8C21;
}

.reservation-status-completed-theme2 {
    background: #E7F9E7;
    color: #33B530;
}

.reservation-status-cancelled-theme2 {
    background: #FDE7E7;
    color: #FC0D1B;
}

