.order-detail-popover-content {
  .discount-item-popover {
    padding: 4px 0px;
  }
}
.discount-item-popover {
  padding: 4px 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .name {
    .discountItem-ellipse {
      margin-right: 7px;
    }
    .discountItem-loyaltyPoint {
      color: #ff8c21;
    }
  }
  .discount-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #282828;
    user-select: none;
    width: fit-content;
    max-width: 65%;
    height: fit-content;
    .discountItem-ellipse {
      margin-right: 7px;
    }
    .discount-code-popover {
      border-radius: 8px;
      border: 1px solid #cdc6ff;
      background: #ece8fe;
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 8px;
      .discount-code-name {
        color: #50429b;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
  .discount-amount {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #282828;
    user-select: none;
    margin: auto 0;
  }
}
