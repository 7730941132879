.total-review {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #494949;
  text-transform: uppercase;
}

.description-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #494949;
  margin-top: 32px;
  margin-bottom: 12px;
}

.description-content {
  font-weight: 400;
  font-size: 24px;
  line-height: 30.24px;
  color: #494949;
}
