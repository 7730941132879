.order-status-to-confirm {
  color: #428BC1;
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #E4EFF6;
}
.order-status-processing {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #ffeaeb;
  color: #ee010f;
}
.order-status-delivering {
  color: #366dfb;
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #dee7ff;
}
.order-status-completed {
  color: #50429b;
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #b8b4d0;
}
.order-status-canceled {
  color: #bcbcbc;
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
}
.order-status-draft {
  color: #bcbcbc;
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
}
