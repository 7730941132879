.store-branch-address-selector {
  max-height: 761px;
  border-radius: 12px;
  .store-branch-address-selector-child {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    border-radius: 20px 20px 0 0;
    cursor: pointer;
  }

  .store-branch-address-selector-header {
    font-size: 18px;
    font-weight: 700;
    margin-left: 10px;
  }

  .ant-modal-content {
    padding: 0;
    background-color: transparent;
    height: 761px;
    border-radius: 12px;
    box-shadow: none;
  }
  .ant-modal-header {
    height: 72px;
    border-radius: 12px 12px 0px 0px;
    margin: 0;
    display: flex;

    .ant-modal-title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff !important;
      text-transform: unset !important;
      margin: auto;
      display: flex;

      span,
      svg {
        margin: auto;
      }

      span {
        margin-left: 15px;
      }
    }
  }
  .ant-modal-body {
    max-height: 665px;
    border-radius: 0 0 12px 12px;
    .ant-radio-group {
      width: 100%;
    }

    //add scrool
    height: 665px;

    .store-branch-address-selector-title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 2%;
      .store-branch-address-selector-title-text {
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
  background-color: #ffffff;
  .store-branch-addresses-parent {
    padding: 24px;
    .store-branch-addresses {
      .ant-radio-wrapper:nth-child(1) {
        margin-top: 32px;
      }

      .ant-radio-wrapper:nth-child(2) {
        margin-top: 54px;
      }
      .text-branch-info {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        /* Sub txt/18-Re */
        color: #625b5b;
        position: absolute;
        top: -32px;
        left: 0;
        z-index: 99;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */
        display: flex;
        align-items: center;
        text-transform: uppercase;

        color: #282828;
      }
      overflow-x: auto;
      max-height: 615px;
      width: 100%;
      .ant-radio-wrapper:hover .ant-radio-inner {
        border: 1px solid #dddddd;
      }
      .ant-radio-wrapper {
        display: block;
        margin: 0;
        margin-bottom: 24px;
        width: 100%;
        padding: 24px 24px 0px 24px;
        background: rgba(255, 255, 255, 0.7);
        border: 1px solid #e2e2e2;
        border-radius: 12px;

        .ant-radio {
          top: 40px;
          float: right;
          .ant-radio-inner {
            height: 24px;
            width: 24px;
          }
        }

        span:last-child {
          display: flex;
        }
      }
      .ant-radio-wrapper:last-child {
        margin-bottom: 0;
      }

      .ant-radio-wrapper-checked {
        padding: 24px;
        gap: 24px;
        height: 156px;

        background: #f4f4f4;
        border-radius: 12px;

        .ant-radio {
          .ant-radio-inner {
            border-color: #db4d29 !important;
            background-color: unset;
          }
          .ant-radio-inner::after {
            background-color: #db4d29;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            inset-block-start: -1px;
            inset-inline-start: -1px;
          }
        }
      }
      .store-branch-address-item {
        display: flex;
      }
    }
  }

  .store-branch-address-item {
    width: 100%;
    .d-flex {
      display: flex;
      align-items: center;
    }
    .address-detail {
      margin-left: 20px;

      font-style: normal;

      .address-label {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;
        display: flex;
        align-items: center;
        color: #282828;
        margin: 0;
      }

      .distance {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        display: flex;
        align-items: center;
        color: #db4d29;
      }
      .address {
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;

        display: flex;
        align-items: center;
        color: #282828;
        width: auto;
      }
    }
    .address-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      .outline {
        width: 36px;
        height: 36px;
        position: absolute;
        border-radius: 50px;
        background: #c53d1a;
        left: 5px;
        top: auto;
        margin-bottom: 12px;
      }
      .address-index {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #ffffff;

        margin: auto;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0dede;
  }
}

@media screen and (max-width: 575px) {
  .store-branch-address-selector {
    max-height: 80vh;
    border-radius: 12px;
    .store-branch-address-selector-child {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      border-radius: 20px 20px 0 0;
      cursor: pointer;
    }

    .store-branch-address-selector-header {
      font-size: 18px;
      font-weight: 700;
      margin-left: 10px;
    }

    .ant-modal-content {
      padding: 0;
      background-color: transparent;
      height: 761px;
      border-radius: 12px;
      box-shadow: none;
    }
    .ant-modal-header {
      height: 72px;
      border-radius: 12px 12px 0px 0px;
      margin: 0;
      display: flex;

      .ant-modal-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff !important;
        text-transform: unset !important;
        margin: auto;
        display: flex;

        span,
        svg {
          margin: auto;
        }

        span {
          margin-left: 15px;
        }
      }
    }
    .ant-modal-body {
      max-height: 665px;
      border-radius: 0 0 12px 12px;
      .ant-radio-group {
        width: 100%;
      }

      //add scrool
      height: 665px;

      .store-branch-address-selector-title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 2%;
        .store-branch-address-selector-title-text {
          text-align: center;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
    .store-branch-addresses-parent {
      padding: 24px;
      .store-branch-addresses {
        overflow-x: auto;
        max-height: 52vh;
        height: auto;
        width: 100%;
        .ant-radio-wrapper:hover .ant-radio-inner {
          border: 1px solid #dddddd;
        }
        .ant-radio-wrapper {
          display: block;
          margin: 0;
          margin-bottom: 24px;
          width: 100%;
          padding: 24px 24px 0px 24px;
          background: rgba(255, 255, 255, 0.7);
          border: 1px solid #e2e2e2;
          border-radius: 12px;

          .ant-radio {
            display: none;
            top: 60px;
            float: right;
            .ant-radio-inner {
              height: 24px;
              width: 24px;
            }
          }
          .ant-radio-checked {
            display: block;
          }
          span:last-child {
            display: flex;
          }
        }
        .ant-radio-wrapper:last-child {
          margin-bottom: 0;
        }

        .ant-radio-wrapper-checked {
          padding: 24px;
          padding-bottom: 0px;
          gap: 24px;
          height: auto;

          background: #f4f4f4;
          border-radius: 12px;

          .ant-radio {
            .ant-radio-inner {
              border-color: #db4d29 !important;
              background-color: unset;
            }
            .ant-radio-inner::after {
              background-color: #db4d29;
              width: 40px;
              height: 40px;
              border-radius: 40px;
              inset-block-start: -1px;
              inset-inline-start: -1px;
            }
          }
        }
        .store-branch-address-item {
          display: flex;
        }
      }
    }

    .store-branch-address-item {
      width: 100%;
      .d-flex {
        display: flex;
      }
      .address-detail {
        margin-left: 20px;
        width: auto;
        font-style: normal;

        .address-label {
          font-size: 18px;
          font-weight: 700;
          line-height: 32px;
          text-align: left;
          padding: 0px;
          gap: 8px;
          margin: 0;
        }

        .distance {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          display: flex;
          align-items: center;
          color: #db4d29;
        }
        .address {
          margin-top: 12px;
          margin-bottom: 12px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;

          display: flex;
          align-items: center;
          color: #282828;
          width: auto;
        }
      }
      .d-flex {
        align-items: center;
      }
      .address-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        .outline {
          width: 36px;
          height: 36px;
          position: absolute;
          border-radius: 50px;
          background: #c53d1a;
          left: 5px;
          top: auto;
          margin-bottom: 14px;
        }
        .address-index {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #ffffff;

          margin: auto;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e0dede;
    }
  }
}
