.cancel-order-by-status {
  padding: 12px 16px;
  height: 48px;
  background: #ffffff;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6a6a6a;
  width: auto;
  border: 1px solid #6a6a6a;
  cursor: pointer;
  border-radius: 12px;
}
.cancel-order-by-status:hover {
  border-color: #6a6a6a !important;
  color: #6a6a6a !important;
}
.re-order-by-status {
  padding: 12px 16px;
  height: 48px;
  background: #db4d29;
  border-radius: 12px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fef7ed;
  width: auto;
  min-width: auto;
  border: none;
  cursor: pointer;
}
