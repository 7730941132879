.mode-view-product-list {
  display: flex;
  gap: 4px;
  padding: 0px 16px;
  justify-content: flex-end;
  position: sticky;
  right: 10px;
  z-index: 100;
  transform: translateY(60%);

  .active-view {
    svg {
      path {
        fill: #DB4D29;
      }
    }
  }

  .no-active-view {
    svg {
      path {
        fill: #959595;
      }
    }
  }
}