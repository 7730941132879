.available-point-switch {
  margin-top: 2px;
  .switch-button {
    background: unset;
    .ant-switch-inner {
      background: #cccccc;
      border-radius: 5px;
      width: 33px;
      height: 10px;
    }
    .ant-switch-handle {
      inset-inline-start: 0px;
    }
  }
}

.ant-switch-checked {
  background: unset !important;
  .ant-switch-inner {
    height: 10px !important;
    width: 23px;
    background: #ffd79a !important;
    border-radius: 5px !important;
  }
  .ant-switch-handle {
    inset-inline-start: calc(100% - 27px) !important;
  }
  .ant-switch-handle::before {
    background-color: #ffa318;
  }
}

.ant-switch:hover:not(.ant-switch-disabled) {
  background: unset;
}

.confirm-modal-config-switch-button {
  .confirmation-modal-theme2 {
    background: #ffffff;
    border-radius: 12px;
    font-style: normal;
    padding-bottom: 0;
    width: 378px !important;
    margin-top: 150px;
    .ant-modal-content {
      border-radius: 13px;
      padding: 0;
      .ant-modal-header {
        background: #db4d29;
        border-radius: 12px 12px 0 0;
        margin: 0;
        min-height: 60px;
        display: flex;
      }
      .ant-modal-header .ant-modal-title {
        color: #ffffff;
        margin: auto;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }

      .ant-modal-body {
        
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        margin-bottom: 24px;
      }

      .ant-modal-footer {
        width: 100%;
        margin: 0;
        padding-right: 24px;
        padding-left: 24px;
        padding-bottom: 24px;
        text-align: center;

        button {
          height: 48px;
          border-radius: 12px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        .ant-btn-default {
          display: none;
          min-width: fit-content;
          background: #ffffff;
        }

        .ant-btn-primary {
          padding: 12px 16px;
          gap: 16px;

          width: 86px;
          height: 48px;

          background: #db4d29;
          border-radius: 12px;
        }
      }
    }

    .confirmation-dialog-content {
      margin: auto;
    }
  }
}

@media (max-width: 575px) {
  .switch-button {
    right: 5%;
  }
  .confirmation-modal-theme2 {
    width: calc(100vw - 52px) !important;
    background: transparent !important;
    .ant-modal-content {
      width: 100% !important;
      left: 0 !important;
    }
  }
}
