.product-detail-image-swiper {
  .swiper-slide,
  .slide-image {
    width: 100% !important;
    height: 100% !important;
  }
}
.image-zoom-in {
  width: 100% !important;
  height: 100% !important;
}
.image-zoom-out {
  width: 100px;
  height: 100px;
}
.image-zoom-out-slide {
  width: auto !important;
  margin-right: 32px !important;
  opacity: 1 !important;
}
.swiper-slide-thumb-active {
  opacity: 1 !important;
}
.mySwiper {
  margin-top: 32px;
}

/* Mobile - ant-xs */
@media (max-width: 575px) {
  .product-detail-image-swiper {
    .swiper-slide,
    .slide-image {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .image-zoom-out-slide {
    width: auto !important;
    margin-right: 16px !important;
    opacity: 0.5;
  }
  .image-zoom-in {
    width: 100% !important;
    height: 100% !important;
  }
  .image-zoom-out {
    width: 80px !important;
    height: 80px !important;
  }
  .mySwiper {
    margin-top: 16px;
  }
}
