.notification-dialog {
  background: none;
  border-radius: 20px;
  font-style: normal;
  padding-bottom: 0;
  width: 685px !important;

  .ant-modal-content {
    background: none;
    border-radius: 20px;
    padding: 0;
    .ant-modal-header {
      border-radius: 20px 20px 0 0;
      margin: 0;
      display: flex;
      background: #ffffff;
      padding-top: 32px;
    }
    .ant-modal-header .ant-modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: auto;
      color: #026f30;
    }

    .ant-modal-body {
      padding: 12px;
      background-color: #ffffff;
      border-radius: 0px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
      .notification-dialog-content {
        width: 100%;
        margin: auto;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-around;
      margin: 0;
      text-align: center;
      padding: 24px 24px 16px 24px;
      background: #026f30;
      border-radius: 0px 0px 20px 20px;
      background-image: url("./Union.png");
      background-repeat: no-repeat;
      background-position: top;
      background-size: 100%;
      button {
        background: none;
        border: unset;
        box-shadow: unset;
        color: white;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        font-weight: bolder;
        white-space: pre-wrap;
        height: fit-content;
        padding: 0px;
      }
    }
  }
}

@media (max-width: 575px) {
  .notification-dialog {
    width: 100% !important;
    .ant-modal-content {
      .ant-modal-header {
        padding-top: 12px;
      }
      .ant-modal-header .ant-modal-title {
        font-size: 17px;
        line-height: 21px;
        padding: 0px 16px;
      }

      .ant-modal-body {
        font-size: 15px;
        line-height: 16px;
        padding: 8px 16px;
      }

      .ant-modal-footer {
        padding: 16px;
        button {
          font-weight: 700;
          font-size: 17px;
          line-height: 24px;
          height: fit-content;
        }
      }
    }
  }
}
