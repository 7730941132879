.flash-sale-slider {
  position: unset;
  .product-main-theme2 {
    width: 273px !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 12px;
    z-index: 1;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    opacity: 1;
  }
  .slick-list {
    .slick-slide {
      display: flex;
      justify-content: center;
    }
  }
  .swiper-button-prev.swiper-button-disabled {
    opacity: 1;
  }
  .swiper-button-prev::after {
    content: url("../../../../assets/icons/swiper-icon-prev.svg") !important;
    padding-bottom: 15px;
  }
  .swiper-button-next::after {
    content: url("../../../../assets/icons/swiper-icon-next.svg") !important;
    padding-bottom: 15px;
  }
  .swiper-button-prev.swiper-button-disabled::after {
    content: url("../../../../assets/icons/swiper-icon-prev-disabled.svg") !important;
  }
  .swiper-button-next.swiper-button-disabled::after {
    content: url("../../../../assets/icons/swiper-icon-next-disabled.svg") !important;
  }

  .swiper-button-prev {
    margin-left: -50px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  .swiper-button-next {
    margin-right: -50px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  .swiper-slide {
    width: auto;
    margin-right: 12px !important;
    height: unset;
  }
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
  }
  .quantity-bar {
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 24px;
    width: 200px;
    height: 36px;
    border-radius: 100px;
    background: #ffd39f;
    .fire {
      position: absolute;
      top: -20px;
      left: 10px;
      margin-bottom: 20px;
      z-index: 2;
    }
    .quantity-bar-progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 100px;
      z-index: 1;
      background: #ffd39f;
      width: 100%;
    }
    .quantity-bar-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.3px;
      color: #ffffff;
      z-index: 3;
    }
    .quantity-bar-number {
      font-weight: 700;
      font-size: 18px;
      padding-left: 6px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.3px;
      color: #ffffff;
      z-index: 3;
    }
  }
  .sold-out {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 200px;
    height: 36px;
    border-radius: 100px;
    background: #ffd39f;
    border: 1px solid #b2b2b2;
    background: #ffffff;
    .quantity-bar-text {
      color: #b2b2b2;
    }
  }
  .ended {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 200px;
    height: 36px;
    border-radius: 100px;
    background: #ffd39f;
    border: 1px solid #f7931e;
    background: #f7931e;
    .quantity-bar-text {
      color: #ffffff;
    }
  }
}

/* Mobile - ant-xs */
@media (max-width: 575px) {
  .flash-sale-slider {
    .product-main-theme2 {
      width: 189px !important;
      margin: 0 !important;
      border-radius: 16px;
    }
    .quantity-bar {
      width: 153px;
      max-width: 100%;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 36px;
      height: 36px;
    }
    .swiper-button-prev {
      margin-left: -46px;
    }
    .swiper-button-next {
      margin-right: -46px;
    }
  }
}
