.pos-discount-code-instore {
  .customize-dialog-modal {
    * {
      font-family: inherit;
    }
    width: 532px !important;
    top: unset;
    .ant-modal-body {
      padding: 24px 0px !important;
    }
  }
}
