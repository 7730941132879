.radio-group--type-button {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .radio-item {
    padding: 16px 24px;
    border-radius: 12px;
    border: 2px solid #f9f9f9;
    background: #f9f9f9;
    .radio-content {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: column;
      .name,
      .value {
        color: #282828;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  .radio-item--button {
    margin: 0px;
    .ant-radio {
      display: none;
    }
    span.ant-radio + * {
      padding-inline-start: 0px;
      padding-inline-end: 0px;
    }
  }
  .ant-radio-wrapper-checked {
    .radio-item {
      padding: 16px 24px;
      border-radius: 12px;
      border: 2px solid #db4d29;
      background: #f9f9f9;
      .radio-content {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: column;
        .name,
        .value {
          color: #db4d29;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
  }
}
