.shopping-cart-flash-sale-theme2 {
  width: 100%;
  padding-top: 100%;
  position: relative;
  cursor: pointer;
  .flash-sale-border {
    position: absolute;
    z-index: 1;
    top: -6px;
    left: -6px;
    border-top-right-radius: 12.31px;
    border-top-left-radius: 12.31px;
    width: 80px;
    height: 80px;
    border-left: 1.76px solid #c41b0e;
    border-top: 1.84px solid #c41b0e;
    border-right: 1.76px solid #c41b0e;
    border-bottom: 12.23px solid #c41b0e;
  }
  .display-image {
    width: 69.54px;
    height: 69.54px;
    border-radius: 12.3077px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    align-items: center;
    padding: 3.39px 3.47px 3.39px 3.47px;
  }
  .flash-sale {
    border-top-right-radius: 6px;
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 55.71px;
    height: 8.36px;
    object-fit: cover;
    .flash-sale-logo {
      width: 100%;
      height: 100%;
    }
    z-index: 2;
  }
  .promotion-label {
    padding: 4px 8px;
    position: absolute;
    width: fit-content;
    height: 28px;
    left: 4px;
    top: 4px;
    background: #ffa318;
    border-radius: 30px;
    z-index: 1;
    transform: none;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-left: 0px;
    }
  }
}

/* Mobile */
@media (max-width: 575px) {
  .shopping-cart-flash-sale-theme2 {
    .flash-sale-border {
      position: absolute;
      z-index: 1;
    }
    .flash-sale {
      border-top-right-radius: 6px;
      position: absolute;
      left: 0;
      object-fit: cover;
      .flash-sale-logo {
        width: 100%;
        height: 100%;
      }
      z-index: 2;
    }
    .promotion-label {
      padding: 4px 8px;
      position: absolute;
      width: fit-content;
      height: 28px;
      left: 4px;
      top: 4px;
      background: #ffa318;
      border-radius: 30px;
      z-index: 1;
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
  }
}
