.theme2-checkout-payment-method {
  width: 100%;
  .ant-card-head {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    padding-left: 16px;
    padding-right: 16px;
    .ant-card-head-wrapper .ant-card-head-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .ant-card-body {
    padding: 16px;
    .payment-method-radio-group {
      width: 100%;
      .ant-space.ant-space-vertical {
        gap: 18px !important;
        width: 100%;
      }
      .payment-method-radio {
        width: 100%;
        display: flex;
        align-items: center;
        margin-right: 0px;
        .ant-radio-checked {
        }
        .ant-radio + span {
          width: 100%;
          padding-right: 0px;
        }
        span .payment-method-radio-label {
          width: 100% !important;
          display: flex !important;
          align-items: center !important;
          justify-content: space-between !important;

          .payment-method-radio-name {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #282828;
            margin-right: 8px;
          }
          .payment-method-radio-icon {
            display: flex;
            height: 28px;
            .payment-method-radio-image {
              width: 28px;
            }
            svg {
              height: 28px;
            }
          }
        }
      }

      .ant-space {
        .ant-space-item {
          .ant-radio-wrapper-disabled {
            .ant-radio-disabled {
              .ant-radio-input {
                cursor: not-allowed;
              }
            }
            .payment-method-radio-label {
              .payment-method-radio-name,
              .payment-method-radio-icon{
                color: #939393;
              }
            }
          }
        }
      }
    }
  }

  .bank-transfer-payment-checkout-theme2 {
    .content-bank-account {
      .qr-code {
        margin-top: 8px;
      }
    }
  }
}

@media (max-width: 1336px) {
  .theme2-checkout-payment-method {
      .bank-transfer-payment-checkout-theme2 {
      .content-bank-account {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        .qr-code {
          margin-right: 32px;
        }
      }
    }
  }
}