.confirmation-modal-theme2 {
  background: #ffffff;
  border-radius: 12px;
  font-style: normal;
  padding-bottom: 0;
  width: 378px !important;
  margin-top: 150px;
  .ant-modal-content {
    border-radius: 13px;
    padding: 0;
    .ant-modal-header {
      background: #db4d29;
      border-radius: 12px 12px 0 0;
      margin: 0;
      min-height: 60px;
      display: flex;
    }
    .ant-modal-header .ant-modal-title {
      color: #ffffff;
      margin: auto;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }

    .ant-modal-body {
      padding: 24px;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
    }

    .ant-modal-footer {
      width: 100%;
      margin: 0;
      padding-right: 24px;
      padding-left: 24px;
      padding-bottom: 24px;
      text-align: center;

      button {
        height: 48px;
        border-radius: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .ant-btn-default {
        min-width: fit-content;
        background: #ffffff;
      }

      .ant-btn-primary {
        min-width: fit-content;
        background: #db4d29;
      }
    }
  }

  .confirmation-dialog-content {
    margin: auto;
    height: fit-content;
    .hotline-cancel-notification {
      display: flex;
      justify-content: center;
      color: #000000;
      font-weight: 700;
      padding: 20px 10px 10px 10px;
      text-decoration-line: underline;
    }
  }
}

/*Mobile:  <576px*/
@media (max-width: 575px) {
  .confirmation-modal-theme2 {
    width: calc(100vw - 52px) !important;
    background: transparent !important;
    .ant-modal-content {
      width: 100% !important;
      height: 100%;
      left: 0 !important;
    }
  }
}
