.card-list-container {
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  .card-list {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 12px;
    .card-detail {
      min-width: 356px;
      min-height: 166px;
      margin-right: 20px;
      background-color: rgb(255, 255, 255);
    }
  }
}

.discount-session-container {
  width: 100%;
  max-width: var(--max-width-container);
  max-height: 340px;
  margin: 0 auto;
}
.promotion-section-background {
  width: 100%;
  max-width: var(--max-width-container);
  max-height: 340px;
  margin: 0 auto;
}
.promotion-section-components {
  border-radius: 15px;
}
.discount-session-home-page {
  $ant-tabs-content-holder-padding-top-bottom: 32px;
  $ant-tabs-content-holder-padding-left-right: 20px;
  $crossbar-count-down-height: 0px;
  $ant-tabs-nav-height: 307px;

  margin-top: 25px;
  margin-bottom: 0px;
  position: relative;

  .z-index-3 {
    z-index: 3 !important;
  }

  .ant-tabs-tabpane {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ant-tabs {
    border-radius: 15px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    .ant-tabs-nav::before {
      border-bottom: none;
    }
    .ant-tabs-nav {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-bottom: 0px;
      padding-left: 25px;
      .ant-tabs-nav-list {
        text-align: center;
        align-items: center;
        .ant-tabs-tab {
          margin-left: 0px;
          text-align: center;
          align-items: center;
          justify-content: center;
          .ant-tabs-tab-btn {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #282828;
            padding-right: 12px;
            padding-left: 12px;
          }
        }

        .ant-tabs-tab::after {
          content: "";
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: #e6e6e6; /* Màu xám cho tab không được chọn */
        }

        .ant-tabs-tab-active {
          text-align: center;
          align-items: center;
          justify-content: center;
          .ant-tabs-tab-btn {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            padding-right: 12px;
            padding-left: 12px;
            color: #db4d29;
          }
        }

        .ant-tabs-tab-active .tab-item-time {
          font-weight: 700;
        }

        .ant-tabs-tab-active::after {
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: red; /* Màu đỏ cho tab được chọn */
        }

        .ant-tabs-ink-bar {
          opacity: 0;
        }
      }
    }

    .ant-tabs-content-holder {
      padding: 30px 24px;
      padding-top: 36px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

.extra-content {
  padding-right: 24px;
  padding-top: 24px;
  .button-extra {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 34px;
    min-width: 40px;
    border-radius: 12px;
    border: none;
    background-color: #ffffff;
    .red-icon {
      color: #db4d29 !important;
    }
  }
  .button-extra:disabled {
    cursor: auto;
    background: none;
    border: none;
    color: #282828;
    .red-icon {
      color: #282828 !important;
    }
  }
}

.storeDiscountInfo {
  font-size: 12px;
  line-height: 20px;
}

@media (max-width: 834px) {
  .extra-content {
    display: none;
  }
  .card-list-container {
    width: 100%;
    overflow-x: scroll;
  }
}

/* Mobile - ant-xs */
@media (max-width: 575px) {
  .card-list-container {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .flash-sale-container {
    padding: 0;
  }
  .discount-session-home-page {
    $ant-tabs-content-holder-padding-top-bottom: 0px;
    $ant-tabs-content-holder-padding-left-right: 20px;
    $crossbar-count-down-height: 0px;
    $ant-tabs-nav-height: 307px;

    margin-top: 0px;
    margin-bottom: -40px;
    position: relative;
    width: 100%;
    .z-index-3 {
      z-index: 3 !important;
    }

    .ant-tabs-tabpane {
      padding-left: 0px;
      padding-right: 0px;
    }

    .ant-tabs {
      border-radius: 15px;
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
      .ant-tabs-nav {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding-left: 16px;
        padding-right: 16px;
        .ant-tabs-nav-list {
          text-align: center;
          align-items: center;
          .ant-tabs-tab {
            margin-left: 0px;
            text-align: center;
            align-items: center;
            justify-content: center;
            .ant-tabs-tab-btn {
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 32px;
              color: #282828;
            }
          }

          .ant-tabs-tab::after {
            content: "";
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #e6e6e6; /* Màu xám cho tab không được chọn */
          }

          .ant-tabs-tab-active {
            text-align: center;
            align-items: center;
            justify-content: center;
            .ant-tabs-tab-btn {
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 32px;
              color: #db4d29;
            }
          }

          .ant-tabs-tab-active .tab-item-time {
            font-weight: 700;
          }

          .ant-tabs-ink-bar {
            opacity: 0;
          }

          .ant-tabs-tab-active::after {
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: red; /* Màu đỏ cho tab được chọn */
          }
        }
      }

      .ant-tabs-content-holder {
        padding: 36px 16px 16px 16px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }

  .storeDiscountInfo {
  }

  .theme-promotion-section-theme2 {
  }
}
