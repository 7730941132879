.post-search-theme2 {
  display: flex;
  flex-direction: column;
  .title {
    color: #db4d29;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 12px;
    padding-left: 12px;
  }
  .input-search-blog {
    width: 396px !important;

    &.fnb-input-customize {
      .ant-input:placeholder-shown {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #959595;
      }
    }
    
  }
}

@media (max-width: 1336px) {
  .post-search-theme2 {
    .title {
      padding-left: 0px;
    }
  }
}

@media (max-width: 576px) {
  .post-search-theme2 {
    .input-search-blog {
      min-width: 300px !important;
      width: 100% !important;
    }
  }
}