.point-history-theme2 {
  padding: 24px;

  .point-history-title {
    color: #282828;

    font-size: 20px;
    
    font-weight: 700;
    line-height: 28px;
  }
}

@media (max-width: 576px) {
  .point-history-theme2 {
    padding: 12px !important;
  }
}

