$white-color: #ffffff;
$primary-color: #ffc4b6;
$border-radius: 20px;
$green-color: #026f30;

.my-voucher-card-selected {
  background-color: #db4d29 !important;
}
.my-voucher-card-disabled {
  background-color: #ededed !important;
}
.my-voucher-card {
  font-style: normal;
  padding: 16px 32px;
  gap: 12px;
  background: $primary-color;
  position: relative;
  overflow: hidden;
  .cart-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    background: $white-color;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    .content-left {
      width: 280px;
      .title {
        text-align: left;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        height: 48px;
        color: #282828;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
      }
      .discount-code {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px 8px;
        gap: 8px;
        background: #fdf6f4;
        border-radius: 12px;
        width: fit-content;
        align-items: center;
        .code {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #db4d29;
        }
      }
      .name-of-discount-type {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        text-align: left;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      .footer {
        padding-top: 8px;
        border-top: 1px dashed #c1c1c1;
        display: flex;
        align-items: flex-end;
        .date-time {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-left: 14px;
          color: #282828;
          width: fit-content;
        }
        .clock-icon {
          display: flex;
        }
      }
    }
    .content-right {
      width: calc(100% - 280px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-apply {
        float: right;
        background: #ffffff;
        border: 1px solid #db4d29;
        border-radius: 50px;
        align-items: center;
        padding: 4px 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
        color: #db4d29;
        cursor: pointer;
      }
      .btn-apply.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      .btn-remove {
        float: right;
        background: #db4d29;
        border: 1px solid #db4d29;
        border-radius: 50px;
        align-items: center;
        padding: 4px 12px;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .ant-card-body {
      padding: 0px;
      width: 100%;
    }
  }
  .cart-content.disabled {
    opacity: 0.4;
  }

  $circleDiameter: 24px;
  .circle {
    width: $circleDiameter;
    height: $circleDiameter;
    border-radius: 50%;
    background-color: $white-color;
  }
  .right-circle {
    display: grid;
    width: calc($circleDiameter / 2);
    position: absolute;
    float: left;
    right: 0px;
    top: 0px;
    height: calc(100% + $circleDiameter);
    transform: translate(0px, -$circleDiameter);
  }
  .left-circle {
    display: grid;
    width: calc($circleDiameter / 2);
    position: absolute;
    float: left;
    left: 0px;
    top: 0px;
    height: calc(100% + $circleDiameter);
    transform: translate(-$circleDiameter / 2, -$circleDiameter);
  }
}

@media screen and (max-width: 576px) {
  .my-voucher-card {
    font-style: normal;
    padding: 12px 20px;
    gap: 12px;
    background: $primary-color;
    position: relative;
    overflow: hidden;
    .cart-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px;
      background: $white-color;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      .content-left {
        width: 31px;
        text-align: left;
        svg {
          width: 22.5px;
          height: 22.5px;
        }
      }
      .content-left {
        width: 200px;
        .title {
          text-align: left;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          height: 32px;
          color: #282828;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .discount-code {
          margin-top: 8px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 4px 8px;
          gap: 5.6px;
          background: #fdf6f4;
          border-radius: 12px;
          width: fit-content;
          align-items: center;
          svg {
            width: 12px;
            height: 12px;
          }
          .code {
            font-style: normal;
            font-weight: 700;
            font-size: 9.87631px;
            line-height: 12px;
            color: #db4d29;
          }
        }
        .name-of-discount-type {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 17px;
          color: #282828;
          text-align: left;
          margin-top: 4px;
          margin-bottom: 4px;
        }
        .footer {
          padding-top: 3px;
          border-top: 1px dashed #c1c1c1;
          display: flex;
          align-items: center;
          .date-time {
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 17px;
            margin-left: 9.8px;
            color: #282828;
          }
          .clock-icon {
            display: flex;
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .content-right {
        width: calc(100% - 200px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-apply {
          background: #ffffff;
          border: 0.705451px solid #db4d29;
          border-radius: 35.2725px;
          border-radius: 50px;
          align-items: center;
          padding: 8px;

          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          align-items: center;
          color: #db4d29;
        }
        .btn-remove {
          background: #db4d29;
          border: 0.705451px solid #db4d29;
          border-radius: 35.2725px;
          border-radius: 50px;
          align-items: center;
          padding: 8px;

          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          align-items: center;
          color: #ffffff;
        }
      }
    }

    $circleDiameter: 15.9px;
    .circle {
      width: $circleDiameter;
      height: $circleDiameter;
      border-radius: 50%;
      background-color: $white-color;
    }
    .right-circle {
      display: grid;
      width: calc($circleDiameter / 2);
      position: absolute;
      float: left;
      right: 0px;
      top: 0px;
      height: calc(100% + $circleDiameter);
      transform: translate(0px, -$circleDiameter);
    }
    .left-circle {
      display: grid;
      width: calc($circleDiameter / 2);
      position: absolute;
      float: left;
      left: 0px;
      top: 0px;
      height: calc(100% + $circleDiameter);
      transform: translate(-$circleDiameter / 2, -$circleDiameter);
    }
  }
}
