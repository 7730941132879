.toast-message-add-update-to-cart {
  width: 425px;
  max-width: 425px;
  height: 48px;
  right: calc(50% - 845px);
  top: 92px;
  border-radius: 12px;
  position: fixed;
  transform: translate(-50%, 0);
  z-index: 999999;
  background: linear-gradient(
    88.17deg,
    rgba(255, 255, 255, 0.8) 1.13%,
    rgba(255, 255, 255, 0.08) 21.19%,
    rgba(255, 255, 255, 0.8) 98.26%
  );
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20.5px);
  display: flex;
  align-items: center;
  -moz-animation: cssAnimation 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 5s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  svg {
    margin: 12px 0px 12px 16px
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #282828;
    margin: 12px;
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(0, 0);
    visibility: visible;
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    visibility: visible;
    opacity: 1;
  }
}

/* Mobile: 1200px */
@media (max-width: 1200px) {
  .toast-message-add-update-to-cart {
    width: calc(20% - 107px);
    min-width: calc(60% - 150px);
    min-height: 55px;
    left: 78%;
    top: 84px;
    .toast-message-add-update-to-cart-check {
      min-width: 23px;
      min-height: 23px;
      margin-left: 10px;
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #282828;
      margin-left: 10px;
    }
  }
}

/* Mobile: 575px */
@media (max-width: 575px) {
  .toast-message-add-update-to-cart {
    width: 92vw;
    left: 0px;
    top: 16px;
    right: 50%;
    left: 50%;
    .toast-message-add-update-to-cart-check {
      min-width: 23px;
      min-height: 23px;
      margin-left: 6px;
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #282828;
      margin-left: 6px;
    }
  }
}
