@media screen and (max-width: 576px) {
  .verify-cancel-order-notification-dialog-content {
    .ant-modal-content {
      .ant-modal-body {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
  
}
