.banner-menu-special-swiper {
  .swiper-slide {
    border: unset;
    height: auto;
  }
  .swiper-initialized { 
    padding-right: 10px;
  }
  .product-main-theme2 {
    margin-bottom: 0px;
    .product-name,
    .product-description {
      text-align: left;
    }
    .product-main-content-theme2 {
      margin-bottom: 0px;
      padding-bottom: 0px;

      .price-box {
        margin-bottom: 18px;
      }
    }
  }
}

.banner-menu-special-swiper .swiper-initialized {
  padding-bottom: 30px;
}

.menu-special-ul li {
  float: left;
}

.menu-special-ul li.menu-special-li-arrow-down {
  float: right;
}

.popover-dropdown-menu-today-menu li {
  float: none;
}

@media screen and (max-width: 639px) {
  .banner-menu-special-swiper {
    .product-main-theme2 {
      width: 100%;
      margin-left: 0 !important;
    }
  }
}

@media screen and (max-width: 575px) { 
  .banner-menu-special-swiper {
    .product-main-theme2 {
      width: 189px;
      .product-main-content-theme2 {
        .price-box {
          margin-bottom: 12px !important;
        }
      }
    }
    .swiper-slide {
      width: 189px !important;
      height: 354px !important;
      max-height: 100%;
    }
  }
  .popover-dropdown-menu-today-menu {
    width: 100% !important;
    li {
      min-height: 45px;
    }
  }
}
