.radio-option-horizontal {
  .group-product-option-item {
    height: 48px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: unset;
    border-bottom: 1px solid #e6e6e6;
    gap: 5px;

    .option-name {
      font-size: 16px;
      font-weight: 500;
      //margin-left: 16px;
      white-space: nowrap;
    }

    .option-level-value {
      display: inline-flex;
      //margin-right: 16px;

      .option-level-value-item {
        height: 32px;
        border-radius: 7px;
        background: #fff;
        color: #000;
        min-width: 82px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border: unset;
        white-space: nowrap;

        .ant-radio-button-checked {
          background-color: #db4d29;
          border-radius: 7.5px;
          border: 1px solid #db4d29;

          .ant-radio-button-input:after {
            content: '';
            width: 12px;
            height: 12px;
            background: red;
            position: absolute;
            top: 4px;
            left: 4px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
        }

        .ant-radio-button:last-child {
          border: unset;
        }
      }

      .ant-radio-button-wrapper-checked {
        color: #fff;
        font-size: 14px;
      }

      .option-level-value-item:not(:first-child)::before {
        background-color: unset !important;
      }
    }
  }
}
.radio-option-vertical {
  .options {
    .radio-option {
      color: #969696;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      height: 100%;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #9f9f9f !important;
    }
  }

  /* Mobile - ant-xs */
  @media (max-width: 575px) {
    .options {
      .radio-option {
        color: #969696;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        height: 100%;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #9f9f9f !important;
      }
    }
  }
}
