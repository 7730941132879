.overlay-loading-full-screen {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  img {
    margin: auto;
    width: 50px;
    height: 50px;
  }
}
