.address-component {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  .customer-delivery-address-wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 24px;
    isolation: isolate;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 12px;

    .my-address-container {
      .title-container {
        padding-bottom: 12px;

        .title-text {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          text-align: left;
          height: 28px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          display: flex;
          align-items: center;

          color: #282828;
        }
      }

      .address-list-container {
        overflow: auto;
        max-height: 252px;
        gap: 12px;
        display: grid;

        .address-detail-box {
          cursor: pointer;
          display: grid;
          justify-content: flex-start;
          align-items: center;
          grid-template-columns: auto auto;
          gap: 16px;
          .left-content {
            svg {
              width: 24px;
              height: 24px;
            }
          }

          .right-content {
            .address-title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
            }

            .address-detail-text {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              text-align: left;
            }

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }

  .header-change-branch {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    border-radius: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: left;
    text-transform: uppercase;
    justify-content: space-between;
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .address-store-picked {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    height: 156px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    display: grid;
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: auto auto;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;

      .outline {
        width: 36px;
        height: 36px;
        position: absolute;
        border-radius: 50px;
        background: #c53d1a;
        top: auto;
        margin-bottom: 12px;
      }
    }

    .store-infomation {
      display: grid;
      gap: 8px;

      .store-title {
        height: 32px;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;

        display: flex;
        align-items: center;
        color: #282828;
      }

      .store-content {
        height: 24px; //styleName: Text 2 (Medium - 16);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #282828;
      }

      .distance {
        height: 28px;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        margin-top: 12px;
        display: flex;
        align-items: center;
        color: #db4d29;
      }
    }
  }
}
.store-branch-component {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  .store-branch-address-parent {
    .store-branch-address-selector {
      max-height: 600px;
      background-color: transparent;
      .store-branch-addresses-parent {
        padding: 0px;
        background-color: transparent;
      }
    }
  }
  .header-change-branch {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    border-radius: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: left;
    text-transform: uppercase;
    gap: 16px;
    cursor: pointer;
    padding-bottom: 16px;
  }
}
.show {
  opacity: 1;
  height: auto;
}
// Adapt mobile screen
@media screen and (max-width: 576px) {
  .store-branch-component {
    .store-branch-address-parent {
      .store-branch-address-selector {
        .store-branch-addresses-parent {
          .store-branch-addresses {
            height: 56vh;
          }
        }
      }
    }
    .header-change-branch {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.5px;
      text-align: left;
    }
  }
  .address-component {
    .address-store-picked {
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 24px;
      height: fit-content;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 12px;
      display: grid;
      justify-content: flex-start;
      align-items: center;
      grid-template-columns: auto auto;

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        .outline {
          width: 36px;
          height: 36px;
          position: absolute;
          border-radius: 50px;
          background: #c53d1a;
          top: auto;
          margin-bottom: 12px;
        }
      }

      .store-infomation {
        display: grid;
        gap: 8px;

        .store-title {
          height: fit-content;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 32px;
          display: flex;
          align-items: center;
          color: #282828;
        }

        .store-content {
          height: auto;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #282828;
        }

        .distance {
          height: 28px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          display: flex;
          align-items: center;
          color: #db4d29;
          margin-top: 12px;
        }
      }
    }

    .customer-delivery-address-wrapper {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 24px;
      isolation: isolate;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 12px;

      .my-address-container {
        .title-container {
          padding-bottom: 12px;

          .title-text {
            height: 28px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            display: flex;
            align-items: center;

            color: #282828;
          }
        }

        .address-list-container {
          overflow: auto;
          max-height: 30vh;
          gap: 12px;
          display: grid;

          .address-detail-box {
            display: grid;
            justify-content: flex-start;
            align-items: center;
            grid-template-columns: auto auto;
            gap: 16px;
            .left-content {
              svg {
                width: 24px;
                height: 24px;
              }
            }

            .right-content {
              .address-title {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
              }

              .address-detail-text {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
              }

              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
