.check_out_page {
  width: 100%;
  height: 100%;
  margin: auto;
}

.modal_login_theme2 {
  .modal_login_message {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
  .modal_login_button {
    text-transform: capitalize;

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
    color: #ffffff;
    background: #026f30;
    border-radius: 12px;
    height: fit-content;
    width: 50%;
  }
}

.modal_login_theme2 {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center;
        text-transform: capitalize;

        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #000000;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .check_out_page {
    display: flex;
    flex-flow: column nowrap;
    overflow-x: hidden;
  }
}
