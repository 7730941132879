.notification-badge {
    .ant-badge-count {
        color: #000000;
        background-color: #ffffff;
        font-size: 12px;
    }


    .notification-icon {
        cursor: pointer;

        svg {
            width: 24px;
            height: 24px;
        }
    }
}

.notification-list {
    width: 375px;
    max-width:  calc(100dvw - 20px);
    border-radius: 12px;
    background-color: #ffffff;
    overflow: hidden;

    .notification-loading {
        background-color: #ffffff;
        width: calc(100% - 4px);
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 12px 16px;
        border-radius: 0 0 12px 12px;

        .ant-skeleton-header {
            padding-inline-end: 12px;

            .ant-skeleton-avatar-circle {
                width: 36px;
                height: 36px;
                border-radius: 10px;
            }
        }

        .ant-skeleton-content {
            .ant-skeleton-title {
                height: 10px;
                margin-block-start: 2px;
            }

            .ant-skeleton-paragraph {
                margin-block-start: 12px;

                &>li {
                    height: 10px;

                    &:last-child {
                        width: 70%;
                    }
                }

                &>li+li {
                    margin-block-start: 12px;
                }
            }
        }
    }

    .ant-list-header {
        display: flex;
        justify-content: flex-end;
        padding: 12px 16px;
        border-block-end: none;
       
        .notification-header {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 15px;
            font-weight: 400px;
            line-height: 18px;
            color: #000000;
            cursor: pointer;

            svg {
                width: 17px;
                height: 17px;
            }
        }
    }

    .unread-notification {
        display: flex;
        align-items: flex-start;
        padding: 12px 8px;
        margin: 0 16px;
        border-block-end: none;
        cursor: pointer;

        &:hover {
            border-radius: 10px;
            background-color: #FFE5DE;
        }

        .ant-list-item-meta {
            .ant-list-item-meta-avatar {
                .custom-avatar {
                    width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FFE5DE;
                    border-radius: 10px;
    
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
                
            }

            .ant-list-item-meta-content {
                .ant-list-item-meta-title {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #DB4D29;
                    word-break: break-word;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    margin-bottom: 8px;
                }

                .ant-list-item-meta-description {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;

                    .notification-description {
                        color: #000000;
                        word-break: break-word;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        margin-bottom: 8px;
                    }

                    .notification-timespan {
                        color: #979797;
                    }
                }
            }
        }

        .dot-unread {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #DB4D29;
        }
    }

    .read-notification {
        display: flex;
        align-items: flex-start;
        padding: 12px 8px;
        margin: 0 16px;
        border-block-end: none;
        cursor: pointer;

        &:hover {
            border-radius: 10px;
            background-color: #FCFCFC;
        }

        .ant-list-item-meta {
            .ant-list-item-meta-avatar {
                .custom-avatar {
                    width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FCFCFC;
                    border-radius: 10px;
    
                    svg {
                        width: 16px;
                        height: 16px;
    
                        path {
                            fill: #C4C4C4;
                        }
                    }
                }
            }

            .ant-list-item-meta-content {
                .ant-list-item-meta-title {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #000000;
                    word-break: break-word;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    margin-bottom: 8px;
                }

                .ant-list-item-meta-description {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;

                    .notification-description {
                        color: #000000;
                        word-break: break-word;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        margin-bottom: 8px;
                    }

                    .notification-timespan {
                        color: #979797;
                    }
                }
            }
        }
    }

    .notification-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        user-select: none;

        img {
            width: 150px;
            height: 150px;
        }

        .empty-text {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                color: #9E9E9E;
                
                &:first-child {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 27px;
                    margin-bottom: 8px;
                    color: #303030;
                }
            }
        }
    }

    .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-vertical {
        width: 4px !important;
        background-color: #DFDFDF;

        .rc-virtual-list-scrollbar-thumb {
            width: 4px !important;
            background-color: #A7A7A7 !important;
            margin: 6px 0;
        }
    }

    .rc-virtual-list-holder {
        margin-bottom: 8px;
    }
}

.overlay-notification-list {
    @media screen and (max-width: 576px) {
        .ant-popover-content {
            margin-top: -10px;
            margin-right: 10px;
        }
    }
}