.reservation-card {
    margin-bottom: 36px;
    width: 100%;

    .reservation-card-header {
        width: 100%;
        padding: 12px 16px;
        background: #f9f9f9;
        border-radius: 20px 20px 0px 0px;

        .reservation-card-header-left {
            display: flex;
            width: 100%;
            .icon {
                display: flex;
                width: 60px;
                height: 60px;
                align-items: center;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .reservation-info {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                padding-left: 12px;

                .my-reservation-delivery-name {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #959595;
                }

                .my-reservation-code {
                    height: 28px;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                    display: flex;
                    align-items: center;
                    color: #282828;
                }

                .my-reservation-code:hover {
                    cursor: pointer;
                }

                .my-reservation-status {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    // display: flex;
                    color: #ff8c21;
                }
            }
        }

        .reservation-card-header-right {
            display: flex;
            justify-content: flex-end;

            .reservation-info {
                .platform {
                    height: 36px;

                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    letter-spacing: 0.3px;
                    color: #282828;
                    justify-content: flex-end;
                }

                .time-reservation {
                    height: 20px;

                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: #959595;
                    justify-content: flex-end;
                }
            }
        }
    }

    .reservation-card-content {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        background: #ffffff;
        padding: 12px 16px;

        .reservation-item-content {
            width: 100%;

            .reservation-item-info {
                .reservation-item-arrival-time {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: end;
                 }
                
                .reservation-item-title {
                    color: #959595;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 8px;
                }

                .reservation-item-order-id {
                    color: #137FE3;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;

                    a {
                        margin-top: 2px;
                    }
                }

                .text-content {
                    color: #282828;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }

                .text-content-branch {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 365px;
                    width: 100%;
                }

                .arrival-time {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    border-radius: 8px;
                    background: #FF8C21;
                    padding: 4px 8px;
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                }

                .reservation-item-number-of-guest {
                    padding-left: 12px;
                }
            }

            .reservation-item-info-wait-to-confirm {
                padding-bottom: 12px;
            }
        }

        .reservation-button-cancel {
            padding-top: 12px;
            text-align: end;
            border-top: 1px solid #ccc;

            button {
                border-radius: 12px;
                padding: 12px 16px;
                border: 1px solid #959595;
                background: #FFF;
                color: #959595;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                cursor: pointer;
                font-family: unset;
            }
        }
    }

    .border-radius-bottom {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.reservation-card:last-child {
    margin-bottom: 0px;
}

@media (max-width: 575px) {
    .reservation-card {
        margin-bottom: 18px;

        .reservation-card-header {
            height: auto;

            .reservation-card-header-left .reservation-info {
                padding-left: 12px;
                display: grid;
                width: 100%;
                grid-template-columns: repeat(2, 1fr);
                gap: 12px;

                .my-reservation-status {
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;
                    border-radius: 8px;
                    position: absolute;
                    right: 0px;

                    .reservation-status-to-confirm {
                        height: 36px;
                        padding: 4px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .reservation-card-content {
            background: #ffffff;
            padding: 12px 16px;

            .reservation-item-content {
                width: 100%;

                .reservation-item-info {
                    display: flex;
                    flex-direction: column;

                    .reservation-item {
                        display: flex;
                        max-width: 100%;
                        align-items: center;
                        .reservation-item-title {
                            min-width: 40vw;
                        }
                    }

                    .reservation-item-number-of-guest {
                        padding-left: 0px;
                    }
                    
                    .reservation-item-arrival-time {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .reservation-item-title {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        .border-radius-bottom {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
}