.product-list-header-theme2-customize,
.product-list-header-theme2 {
  width: 100%;
  height: 322px;
  background: #fef7ed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  .title {
    color: #282828;

    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin: auto;
    text-align: center;
    cursor: pointer;
    overflow-wrap: break-word;
    user-select: none;
    width: 1200px;
    max-width: 100%;
  }

  .overall-review-button {
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
    border: 0.5px dashed #f7931e;
    background-color: #fffcf9;
    height: 42px;
    max-width: 232px;
    align-items: center;
    text-align: center;
    .star-icon {
      margin-top: 2px;
      width: 18px;
      height: 18px;

      svg path {
        fill: #ff8c21;
      }
    }
    .overall-score {
      border-right: 1px solid #000000;
      padding-right: 16px;
      padding-left: 8px;
      font-weight: 700;
    }
    .total-review {
      padding-left: 16px;
      font-weight: 500;
      color: #007aff;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.5px;
      text-align: right;
      text-transform: lowercase;
    }
  }
}

.product-list-header-theme2-customize:active {
  border: 3px solid blue;
}

@media screen and (max-width: 639px) {
  .product-list-header-theme2 {
  }
}
