.empty-order {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    height: 60vh;

    .empty-order-text {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 12px;
        margin-bottom: 24px;
        max-width: 200px;
    }

    .empty-order-btn {
        border-radius: 12px;
        background: var(--global-button-background-color, #DB4D29);
        padding: 8px 16px;
        color: var(--global-button-text-color, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
    }

    .disable-add-item-btn {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
}

@media (max-width: 575px) {
    .empty-order {
        .empty-order-icon-receipt {
            svg {
                width: 260px;
                height: 260px;
            }
        }
    }
}