@import "../../stylesheets/variable.scss";

.fnb-checkbox {
  .ant-checkbox {
    background: $color-white;
    border: $color-A5ABDE;
    border-radius: 6px;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $fnb-100;
    background: $fnb-100;
    border-radius: 6px;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 1px solid #98979B;
    border-radius: 6px;
  }

  .ant-checkbox-inner::after {
    width: 6px;
    height: 12px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    border: 1px solid $white;
    width: 5px;
    height: 10px;
    border-top: 0;
    border-left: 0;
    transform: rotate(40deg) scale(1.1) translate(-35%, -65%);
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    .ant-checkbox-indeterminate {
      .ant-checkbox-inner::after {
        background-color: #98979B;
        width: 10px;
        height: 2px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0%) scale(1);
      }
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: $fnb-100;
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }

  .ant-checkbox-disabled {
    background: #e4e4e4;
    border: #f4f4f4;
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }

  .ant-checkbox-wrapper-disabled .ant-checkbox-inner,
  .ant-checkbox-disabled:hover .ant-checkbox-inner {
    background: #e4e4e4;
    border: #f4f4f4;
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }
}
