.status-ordered {
    width: fit-content;
    padding: 8px 16px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 16px;
    margin-bottom: 4px;
}

.status-ordered-new {
    background-color: #E4EFF6;
    color: #428BC1;
}

.status-ordered-serving {
    background-color: #FFE8D2;
    color: #FF8C21;
}

.status-ordered-completed {
    background-color: #E7F9E7;
    color: #33B530;
}

.status-ordered-cancelled {
    background-color: #FFF7F7;
    color: #FC0D1B;
}