$white-color: #ffffff;
$primary-color: #db4d29;
$border-radius: 20px;
$green-color: #026f30;

.fnb-promotion-card {
  height: 100%;
  min-width: 397px;
  min-height: 166px;
  width: 358px;
  font-style: normal;
  color: $white-color;
  padding: 0px 39px 0px 0px;
  .row-header-card {
    width: 100%;
    .col-header-card {
      width: 256px;
      .header-card {
        display: flex;
        .card-title-discount {
          display: flex;
          align-items: center;
          padding: 12px 12px 7.5px 12px;
          background-color: $primary-color;
          border-top-right-radius: $border-radius;
          border-top-left-radius: $border-radius;
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 43.5px;
          text-overflow: ellipsis;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            width: 100%;
            text-overflow: ellipsis;
          }
        }
        .card-title-store {
          display: flex;
          align-items: center;
          padding: 12px 12px 7.5px 12px;
          background-color: $primary-color;
          border-top-right-radius: $border-radius;
          border-top-left-radius: $border-radius;
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 64px;
          text-overflow: ellipsis;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            width: 100%;
            text-overflow: ellipsis;
            margin-bottom: 0px;
          }
        }
      }
      .discount-code {
        background-color: $primary-color;
        padding-left: 12px;
        position: relative;
        height: 20.5px;
        .discount {
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 241px;
          height: 24px;
          background: #fdf6f4;
          border-radius: 12px;
          color: $primary-color;
          font-weight: 700;
          font-size: 14px;
          z-index: 2;
          position: absolute;
          padding: 0px 8px;
          .icon {
            display: flex;
            align-items: center;
          }
          .code {
            margin-left: 8px;
          }
        }
      }
    }
    .col-button-redeem {
      width: 102px;
      .card-icon {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
        .card-icon-content {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px;
          height: 100%;
          width: 100%;
          border-bottom-left-radius: $border-radius;
          .btn-redeem {
            border: 1px solid $primary-color;
            width: 84px;
            height: 40px;
            background: $white-color;
            border-radius: 50px;
            font-weight: 600;
            font-size: 14px;
            color: $primary-color;
            cursor: pointer;
          }
        }
      }
      .card-icon:before {
        content: "";
        display: block;
        width: 100%;
        height: 150%;
        position: absolute;
        border-radius: 26px;
        bottom: 4px;
        left: 0;
        box-shadow: -50px 50px 0 0 #db4d29;
        pointer-events: none;
      }
    }
  }

  .content-card {
    padding: 0px 26px;
    height: 106px;
    transform: translateY(-4px);
    background-color: $primary-color;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    .discount-amount-parent{
      margin-top: 7.5px;
    }
    ul li {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      
    }
    p {
      padding: 0px;
      margin-bottom: 0px;
      transform: translateX(-12px);
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.3px;
    }
    .discount-amount {
      border: 1px solid $white-color;
      border-radius: 4px;
      width: fit-content;
      padding: 2px 12px 2px 12px;
      margin-top: 3px;
      
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
    .discount-type {
      margin-left: 20px;
      padding-top: 11.25px;
    }
    .end-date-discount {
      position: relative;
      margin-top: 7.5px;
    }
    .end-date-store {
      position: relative;
      margin-top: 11.25px;
    }
  }
  .content-card-discount {
    height: 106px;
    border-top-right-radius: 20px;
    padding: 0px 26px;
    transform: translateY(-4px);
    .end-date-store {
      p {
        padding: 0px;
      }
    }
    .end-date-discount {
      p {
        padding: 0px;
      }
    }
    .discount-type {
      margin-left: 20px;
      padding-top: 16px;
      margin-bottom: 0px;
    }
    .discount-amount-parent {
      margin-top: 7.5px;
      margin-left: 6px;
      .discount-amount {
        border: 1px solid $white-color;
        border-radius: 4px;
        width: fit-content;
        padding: 2px 12px 2px 12px;
        margin-top: 0px;
        
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
    }
    .fnb-promotion-card .content-card ul li {
      
    }
  }
}

.discount-code-style {
  .card-title-discount-code {
    display: unset;
  }
  .card-title-discount {
    display: unset !important;
    cursor: pointer;
  }
  .card-title-store {
    display: unset !important;
  }
}

.store-code-style {
  .header-card {
    .card-title-discount {
      p {
        -webkit-line-clamp: 2;
      }
    }
    .card-icon {
      height: auto;
    }
  }
}

.voucher-store-promotion-card {
  margin: 40px;

  font-style: normal;
  max-width: 480px;
  min-height: 190px;
  padding: 16px 32px;
  gap: 12px;
  background: $primary-color;
  position: relative;
  .modal-card-promotion {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    gap: 24px;
    max-width: 420px;
    min-height: 158px;
    background: $white-color;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    .ant-card-body {
      padding: 0px;
      .header-card {
        display: flex;
        margin-bottom: 10px;
        .discount-icon {
          svg {
            width: 34px;
            height: 34px;
          }
        }
        .title-text {
          max-width: 330px;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #282828;
          padding: 0px 12px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .applied-icon {
          position: absolute;
          float: right;
          right: 15px;
        }
      }

      .content-card {
        font-style: normal;
        color: #282828;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .dahsed-line {
        width: 100%;
        max-width: 340px;
        height: 1px;
        border: 1px dashed #c1c1c1;
        position: relative;
      }

      .footer-card {
        display: flex;
        height: 40px;
        align-items: flex-end;
        .date-time {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-left: 8px;
          color: #282828;
        }
        .clock-icon {
          display: flex;
        }
      }
    }
  }
  .left-half-circle {
    float: left;
    position: absolute;
    z-index: 2;
    transform: translate(-32px, -15px);
  }

  .right-half-circle {
    width: 12px;
    position: absolute;
    float: left;
    right: 0px;
    transform: translate(0px, -14px);
    li {
      &::after {
        border-radius: 12px 0px 0px 12px;
      }
    }
  }
  ul li {
    margin: 10px 0px;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 12px;
      height: 20px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 0px 12px 12px 0px;
      background-color: $white-color;
    }
    &::marker {
      display: none;
      content: "";
    }
  }
}

.voucher-promotion-card {
  background: #ffc4b6;
  .header-card {
    .title-text {
      padding-left: 0px !important;
    }
  }
  .content-card {
    display: flex;
    justify-content: space-between;
    .left-content {
      display: flex;
      flex-direction: column;
      height: 60px;
      justify-content: space-between;
      .discount-code {
        display: flex;
        padding: 4px 8px;
        gap: 8px;
        max-width: 241px;
        height: 24px;
        background: #fdf6f4;
        border-radius: 12px;
        .code {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.3px;

          color: $primary-color;
        }
      }
      .text-content {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        max-width: 300px;
      }
    }
    .btn-redeem {
      width: 100%;
      max-width: 84px;
      max-height: 40px;
      background: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 50px;
      float: right;
      position: absolute;
      right: 10px;

      button {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: $white-color;
        outline: none;
        border: none;
        background: none;
        line-height: 40px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .btn-remove {
      width: 100%;
      max-width: 84px;
      max-height: 40px;
      background: $white-color;
      border: 1px solid $primary-color;
      border-radius: 50px;
      float: right;
      position: absolute;
      right: 10px;

      button {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: $primary-color;
        outline: none;
        border: none;
        background: none;
        line-height: 40px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .promotion-section-background{
    max-width: fit-content;
  }
  .voucher-promotion-card {
    .modal-card-promotion {
      .ant-card-body {
        .content-card {
          .btn-redeem {
            padding: 5.64361px 8.46541px;
            gap: 5.64px;
            width: 59.26px;
            height: 28.29px;
            border: 0.705451px solid $primary-color;
            border-radius: 35.2725px;
            top: 44%;
            button {
              font-size: 9.87631px;
              line-height: 17px;
              display: flex;
              align-items: center;
              color: $white-color;
            }
          }
        }
      }
    }
  }

  .card-list-container {
    height: 140px;
    .card-list {
      
    }
  }
}
