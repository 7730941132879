.btn-select-discount-code {
  width: 100%;
  height: 56px;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  margin-bottom: 24px;
  padding-left: 16px;
  padding-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #282828;
    width: calc(100% - 48px);
    float: left;
    padding: 0 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .suffix-icon {
    width: 24px;
  }
  .prefix-icon {
    width: 24px;
    display: flex;
    align-items: center;
  }
}
.btn-select-discount-code-apply{
  background-color: rgba(255, 163, 24, 0.10);
}
