.search-discount-code {
  padding: 0px 24px 20px 24px;
  .ant-form-item {
    margin-bottom: 0px;
    .ant-form-item-explain-error {
      text-align: left;
      color: #ff0000;
    }
  }
  .btn-apply {
    background-color: #db4d29;
    height: 40px;
    padding: 8px 12px;
    align-items: center;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    border-radius: 12px;
  }
  :where(.css-dev-only-do-not-override-10ed4xt).ant-btn-default:not(:disabled):hover {
    color: #ffffff;
    border-color: #d9d9d9;
  }
  .title-discount-code {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.promotion-list {
  padding: 0px 24px;
  max-height: 600px;
  overflow-y: scroll;
  gap: 16px;
  .title {
    position: relative;
    .title-text {
      position: relative;
      z-index: 2;

      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      height: 32px;
      color: #282828;
      margin: auto;
      margin-bottom: 12px;
      width: fit-content;
      background-color: #ffffff;
      padding: 0 12px;
    }
    .title-line {
      z-index: 1;
      position: absolute;
      top: 15px;
      width: 100%;
      height: 1px;
      border: 1px dashed #cdcdcd;
    }
  }
  .voucher-store-promotion-card {
    width: 100%;
    margin: 0px;
    max-width: 100%;
    .right-half-circle {
      right: 6px;
    }
  }
  .vertical-line {
    border-left: 1px solid black;
    height: 100%;
    margin: 0 10px;
  }
  .promotion-in-store:not(:last-of-type),
  .my-voucher-item:not(:last-child) {
    margin-bottom: 16px;
  }
  .discount-default {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 600px;
    .discount-default-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .search-discount-code {
    padding: 0px 16px 14px 16px;
    .btn-apply {
      background-color: #db4d29;
      height: 32px;
      padding: 8px;
      align-items: center;

      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      border-radius: 8.46541px;
    }
    .title-discount-code {
      font-size: 12px;
      line-height: 20px;
    }
  }
  .promotion-list {
    padding: 0 16px;
    .discount-default {
      height: 300px;
    }
    .promotion-in-store:not(:last-of-type),
    .my-voucher-item:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
