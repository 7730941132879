.theme2-checkout-delivery-method {
  width: 100%;

  .ant-card-body {
    padding: 16px;
    padding-bottom: 4px;

    .delivery-method-radio-group {
      width: 100%;

      .radio-button-wrapper {
        margin-bottom: 24px;
        .delivery-method-grab-express {
          margin-top: 4px;
          color: #282828;
          font-weight: 400;
          line-height: normal;
          font-size: 12px;
        }
      }

      .ant-space.ant-space-vertical {
        gap: unset !important;
        width: 100%;
      }

      .ant-radio-wrapper:hover .ant-radio-inner {
        border: 1px solid #dddddd;
      }

      .ant-radio-wrapper {
        width: 100%;

        span:last-child {
          width: 100%;
        }

        .ant-radio {
          .ant-radio-inner {
            height: 24px;
            width: 24px;
          }
        }
      }

      .delivery-method-radio {
        width: 100%;
        display: flex;
        align-items: center;
        margin-right: 0px;

        .delivery-method-radio-label {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .delivery-method-radio-name {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #282828;
          }

          .delivery-price {
            display: flex;
            .delivery-method-radio-price-original {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              text-decoration-line: line-through;
              color: #959595;
              margin-right: 10px;
            }

            .delivery-method-radio-price {
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: #282828;
            }
          }
        }
      }

      .delivery-method-radio-last-child {
        margin-bottom: 0 !important;
      }
      .ant-space {
        .ant-space-item {
          .ant-radio-wrapper-disabled {
            .ant-radio-disabled {
              .ant-radio-input {
                cursor: not-allowed;
              }
            }
            .delivery-method-radio-label {
              .delivery-method-radio-name,
              .delivery-price {
                .delivery-method-radio-price {
                  color: #939393;
                }
              }
            }
            .delivery-method-grab-express {
              color: #939393;
            }
          }
        }
      }
    }
  }
}

// Adapt mobile screen
@media screen and (max-width: 600px) {
  .theme2-checkout-delivery-method {
    .ant-card-body {
      .delivery-method-radio-group {
        width: calc(100% + 32px);

        .ant-radio-wrapper {
          width: calc(100% - 24px);
          margin-bottom: 16px;
        }
      }
    }
  }
}
