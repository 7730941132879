.check_out_header_theme2 {
  align-items: center;
  text-align: center;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 48px;
  padding-bottom: 48px;
  .header_title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    align-items: center;
    text-align: center;
    color: #282828;
    margin: auto !important;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 639px) {
  .check_out_header_theme2 {
    margin-top: 0 !important;
    .header_title {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
