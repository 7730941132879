.point-history-filter-theme2 {
  display: flex;
  justify-content: space-between;
  margin: 24px 0px;
  .search {
    .input-search {
      width: 400px;
    }
  }

  .filter {
    position: relative;
    .btn-filter {
      width: 56px;
      height: 48px;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.154px;

      color: #000000;
      &:hover {
        color: #000000;
        border-color: #ffffff !important;
      }

      .close-circle-icon {
        margin-left: 8px;
        svg {
          fill: #ccc;
        }
      }
    }
    .btn-filter-desktop-devices {
      width: auto !important;
    }
  }
}

.popover-point-history-customize-theme2 {
  // border-radius: 16px;
  // background: #fff;

  .ant-popover-content {
    .ant-popover-inner {
      width: 865px;
      height: auto;
      padding-right: 0px !important;
      padding-top: 24px;
    }
  }
  .ant-popover-inner-content {
    padding: 24px 12px;
    border-radius: 16px;
  }
}

.content-theme2 {
  .title-name {
    color: #000;
    font-size: 18px;
    
    font-weight: 600;
    line-height: 24px;
    min-width: 140px;
    padding-left: 8px;
  }
  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      .ant-radio-group {
        display: flex;
        flex-wrap: wrap;
        margin-left: 24px;
        .ant-radio-button-wrapper {
          height: 52px;
          margin-right: 16px;
          border: none;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 12px;
          border-radius: 12px;
          background: #fff;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
          color: #000;
          font-size: 18px;
          
          line-height: 21px;
          letter-spacing: 0.3px;
          .check-icon {
            display: none;
          }
        }

        .ant-radio-button-wrapper-checked {
          height: 52px;
          margin-right: 16px;
          border-radius: 12px;
          background: #db4d29;
          border: none;
          outline: none;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 12px;

          .check-icon {
            margin-right: 8px;
            display: inline-flex;
            align-items: center;
            color: inherit;
            font-style: normal;
            line-height: 0;
            text-align: center;
            text-transform: none;
            vertical-align: -0.125em;
            position: relative;
            left: -4px;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
          }
          .ant-radio-button {
            ::before {
              display: none !important;
            }
          }
        }

        .ant-radio-button-wrapper::before {
          display: none;
        }
      }
    }
  }

  .filter-by {
    display: flex;
    width: 100%;
    flex-wrap: inherit;
  }

  .from-time,
  .to-time {
    .date-picker-customize-theme2 {
      height: 52px;
      border-radius: 12px;
      width: 490px;
      margin-left: 24px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
      &:hover {
        border-color: #fff !important;
      }

      .ant-picker-input > input {
        color: #000;
        font-size: 18px;
        
        line-height: 21px;
        letter-spacing: 0.3px;
      }

      ::-webkit-input-placeholder,
      :-ms-input-placeholder,
      ::placeholder {
        font-size: 18px;
        
        line-height: 21px;
        letter-spacing: 0.3px;
      }
    }
  }

  .submit-form {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn-reset {
      color: #000;
      text-align: center;
      font-size: 18px;
      
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.3px;
      cursor: pointer;
    }
    .btn-apply {
      margin: 0px 24px;
      button {
        width: 108px;
        height: 40px;
        border-radius: 12px;
        background: #db4d29;
        color: #fff;
        text-align: center;
        font-size: 18px;
        
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.3px;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: unset;
      }
    }
  }
}

.popup-picker-customize-theme2 {
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    width: 40px;
    height: 40px;
    border-radius: 40px !important;
    background: #db4d29 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none !important;
  }

  .ant-picker-content {
    tr th {
      color: #19181a;
      text-align: center;
      font-size: 18px;
      
      font-weight: 600;
      line-height: 24px;
    }
  }

  .ant-picker-header {
    padding: 0 40px;
    .ant-picker-header-view {
      .ant-picker-month-btn {
        color: #db4d29;
        font-size: 18px;
        
        font-weight: 500;
      }

      .ant-picker-year-btn {
        color: #000;
        font-size: 18px;
        
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .popover-point-history-customize-theme2 {
    .ant-popover-content {
      .ant-popover-inner {
        max-width: 655px !important;
        width: fit-content;
      }
    }
  }
}

@media (max-width: 576px) {
  .point-history-filter-theme2 {
    justify-content: initial !important;
    .search .input-search {
      max-width: 296px !important;
      width: 100%;
    }

    .filter .btn-filter {
      width: 56px;
      margin-left: 12px;
    }
  }
  .content-theme2
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-radio-group
    .ant-radio-button-wrapper {
    font-size: 15px;
  }
  .content-theme2 {
    .date-picker-customize-theme2 {
      margin-left: 0px !important;
      width: 300px !important;
    }
    .filter-by,
    .sort-by,
    .from-to {
      flex-wrap: wrap;
      flex-direction: column
    }
    .ant-form-item-control-input .ant-form-item-control-input-content .ant-radio-group {
      margin-left: 0px;
    }
  }

  .popover-point-history-customize-theme2 .ant-popover-content .ant-popover-inner {
    width: auto !important;
    margin: 0px 12px;
  }

  .popover-point-history-customize-theme2 .ant-popover-content .ant-popover-arrow {
    display: none;
  }
}
