.delivery-address-popover {
  z-index: 1000;
  background-color: white;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 3px;
  font-size: 1em;
  .delivery-address-popover-scroll {
    overflow-y: auto;
    max-height: 25vh;
    .address-popover-item {
      padding: 12px;
      border-bottom: 1px solid #d9d9d9;
      .icon-box {
        display: flex;
        justify-content: center;
        padding-top: 4px;
      }

      .address-box {
        .address-name,
        .street-text {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #282828;
        }
        .address-name {
          font-weight: 700;
        }
        .ward-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #626262;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
    .address-popover-item:last-child {
      border-bottom: unset;
    }
  }
}
.has-customer-address {
  position: absolute;
}
.delivery-select-address-input {
  max-width: 752px;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .delivery-address-popover {
    max-height: 150%;
    padding: 2px;
    .delivery-address-popover-scroll {
      overflow-y: auto;
      max-height: 270px;
    }
  }
}

@media screen and (max-width: 576px) {
  .delivery-address-popover {
    padding: 2px;
    width: 100%;
    .delivery-address-popover-scroll {
      overflow-y: auto;
      max-height: 270px;
    }
  }
  .has-customer-address {
    padding: 2px;
    width: calc(100% - 32px);
  }
  .delivery-select-address-input {
    width: 100%;
    height: 60px;
  }
}
