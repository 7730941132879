.product-cart {
  background: var(--color-default-body-text-1, #fff);
  border-radius: 16px;
  .product-cart-image {
    border-radius: 12px 12px 0 0;
  }
  .fnb-display-image {
    cursor: pointer;
  }
  .content {
    padding: 8px 12px 16px 12px;
    .product-rating {
      display: flex;
      height: 24px;
      margin-top: 5.75px;
      align-items: center;
      min-width: 100%;
      .customize-rating {
        cursor: pointer;
      }
    }
    .title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.3px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 4px;
      margin-top: 8px;
      min-width: 195px;
      max-height: 22px;
      cursor: pointer;
    }
    .product-description {
      color: #959595;
      font-size: 14px;
      margin-top: 4px;
      text-align: justify;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-width: 195px;
      margin-bottom: 8px;
      height: 36px;
      margin-right: 12px;
    }
    .price {
      display: flex;
      align-items: start;
      flex-direction: column;
      height: 48px;
      .price-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .original-price {
          overflow: hidden;
          color: var(--color-default-button-topping-option-original-price-time-quantity-price, #959595);
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          text-decoration-line: line-through;
          height: 24px;
        }
        .selling-price {
          height: auto;
          color: var(--color-customize-title-text-color, #db4d29);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          display: flex;
          align-items: center;
          &.center {
            margin-bottom: 12px;
          }
        }
      }
    }
    .button {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 16px;
      svg {
        border-radius: 33px;
        cursor: pointer;
        background: rgba(219, 77, 41, 0.8);
        padding: 8px;
      }
    }
  }
}
.cart-list-out-of-stock {
  .display-image {
    opacity: 0.5;
  }
  .content {
    opacity: 0.5;
  }
  .out-of-stock-badge {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: rgba(40, 40, 40, 0.9);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 0;
    position: absolute;
    padding: 12px 24px;
    border-radius: 24px;
    span {
      width: fit-content;
      height: 24px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
  }
}

@media (max-width: 575px) {
  .cart-list-out-of-stock {
    .display-image {
      opacity: 0.5;
    }
    .content {
      opacity: 0.5;
    }
    .out-of-stock-badge {
      padding: 8px 16px;
      position: absolute;
      height: 36px;
      background: rgba(49, 49, 49, 0.9);
      border-radius: 12px;
      span {
        width: fit-content;
        height: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .product-cart {
    .content {
      padding: 0px;
      .product-rating {
        display: flex;
        height: 21px;
        margin-top: 4.83px;
        align-items: center;
        .customize-rating {
          cursor: pointer;
          max-width: 24px;
        }
      }
      .title {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 4.83px;
        min-width: 80px;
        max-height: 20px;
      }
      .product-description {
        margin-top: 0px !important;
        max-height: 36px;
        margin-bottom: 4.83px;
        min-width: 130px;
        margin-right: 4.83px;
      }
      .price {
        .price-container {
          .original-price {
            font-size: 10px;
            height: 16px;
            font-weight: 500;
            line-height: 16px;
          }
          .selling-price {
            font-size: 14px;
            height: 20px;
            line-height: 20px;
          }
        }
      }
      .button {
        padding-bottom: 9.66px;
        svg {
          border-radius: 24.846px;
          cursor: pointer;
          background: #db4d29;
          padding: 8px;
        }
      }
    }
  }
}
