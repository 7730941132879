@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-spinner {
    display: block;
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    box-shadow: inset 2px 0 0 rgb(2, 111, 48), -2px 0 0 rgb(2, 111, 48);
    animation: spin 1s infinite linear;
    border-radius: 100%;
    transform-origin: center;
  }
}
