.my-reservation-theme2 {
    .swiper-wrapper {
        .swiper-slide {
            width: fit-content;
            max-width: 100%;
        }
    }
}

@media (max-width: 1199px) and (min-width: 576px) {
    .my-reservation-status-theme2 {
        margin: 0px;
    }
}