.ordered-order-detail-item {
    .checkout-order-item-theme-2 {
        .order-item {
            .order-item-info {
                flex: 3;
            }

            .order-item-total,
            .order-item-quantity {
                flex: 1;
            }

            .order-item-quantity {
                color: #282828;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
            }
        }
    }
}

@media (max-width: 575px) {
    .ordered-order-detail-item {
        .checkout-order-item-theme-2 {
            .order-item {
                .order-item-quantity {
                    flex: auto;
                }
            }
        }
    }
}