.check-out-momo-theme2 {
  margin: auto;
  border-radius: 12px;
  .ant-modal-content {
    height: 264px;
    align-items: center;
    text-align: center;
    background: white;
    border-radius: 12px;
    padding: 0;

    .check-out-momo-title1 {
      width: 258px;
      padding-top: 10px;
      padding-bottom: 10px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #000000;
      margin: auto;
    }
    .ant-modal-header {
      background: #db4d29;
      border-radius: 12px 12px 0px 0px;
      height: 48px;
      display: flex;
      .ant-modal-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        align-items: center;
        color: #ffffff;
        width: 100%;
        height: 30px;
        margin: auto;
      }
    }
    .ant-modal-footer {
      border-top: 0 none !important;
      display: flex;
      flex-flow: column nowrap;
      flex-direction: column-reverse;
      justify-content: space-between;
      display: flex;

      .ant-btn-default {
        width: fit-content;
        height: 48px;
        background: #ffffff;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #959595;
        border-radius: 12px;
        color: #959595;
        margin: auto !important;
        margin-top: 10px !important;
      }
      .ant-btn-primary {
        background: #db4d29;
        border-radius: 12px;
        width: 170px;
        height: 48px;
        margin: auto !important;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .check-out-momo-theme2 {
    margin: auto;
    width: 100%;
  }
}
