.history-item {
  * {
    font-family: inherit;
  }
  margin-bottom: 20px;
  border-radius: 30px;
  .history-item-card {
    padding: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    display: flex;
    .date-time {
      color: #848484;
      font-size: 12px;
      padding-right: 10px;
    }
    .action-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
    .area-name {
      display: flex;
      margin-top: 5px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.3px;
      gap: 5px;
    }
    .content {
      margin-top: 10px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.3px;
      .order-detail {
        background-color: #fff;
        border-radius: 24px;
        padding: 24px;
      }
    }

    .item-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.3px;
    }
    .total-item {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
    }

    .date-time-item-order {
      display: flex;
      justify-content: flex-end;
      padding-top: 12px;
    }

    .info-code {
      color: #6245ff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-right: 10px;
    }
  }

  .history-content-wrapper {
    padding-left: 8px !important;
    width: calc(100% - 76px);
    height: 100%;
  }

  .history-icon-wrapper {
    width: 48px;
  }
}
.history-content {
  * {
    font-family: inherit;
  }
  .history-content-not-found {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text-not-found {
      margin: 24px 0;
    }
    .button-let-order {
      border-radius: 12px;
      background: var(--global-button-background-color, #026f30);
      height: 48px;
      padding: 8px 16px;
      span {
        color: var(--global-button-text-color, #fff);
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .history-title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    span {
      color: #6245ff;
    }
  }
}

.history-label {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.pl-24 {
  padding-left: 24px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-14 {
  padding-top: 14px;
}

@media (min-width: 769px) {
  .history-item {
    .history-item-card {
      .date-time {
        font-size: 16px;
      }
      .action-name {
        font-size: 24px;
      }
      .area-name {
        font-size: 16px;
      }
      .content {
        font-size: 16px;
      }
    }
    .history-content-wrapper {
      padding-left: 16px !important;
    }
    .history-icon-wrapper {
      width: 60px;
    }
  }
}

@media (max-width: 575px) {
  .history-item {
    .history-item-card {
      .date-time {
        padding-right: 0px;
        span {
          line-height: 32px;
        }
      }
      .info-code {
        font-size: 12px;
      }
    }

    .history-content-wrapper {
      width: calc(100% - 64px);
      height: 100%;
    }
  }
  .history-content {
    .history-content-not-found {
      .img-not-found {
        width: 80px;
        height: 80px;
      }
    }
    .history-title {
      font-size: 16px;
    }

    .item-option-order-history {
      font-size: 12px;
    }
    .order-detail-content-message {
      font-size: 12px;
    }
  }
}
