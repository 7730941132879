.modal-title-time {
  width: 100%;
  height: 52px;
  position: absolute;
  top: -15px;
  
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  background: #db4d29;
  text-transform: none;
}
.custom-dropdown-class {
  width: 190px !important;
  position: absolute;
  padding: 24px 16px 24px 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
  .rc-virtual-list-scrollbar-thumb {
    width: 0px !important;
  }
  .rc-virtual-list-holder-inner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    .ant-select-item-option.ant-select-item-option-selected .ant-select-item-option-state {
      color: red;
      background-image: url("../../../../assets//icons/check-select-item.svg");
      background-size: cover;
    }
    .ant-select-item {
      padding: 0px;
      height: 56px;
      background-color: transparent;
      .ant-select-item-option-content {
        height: 56px;
        
      }
      .ant-select-item-option-state {
        height: 24px;
        width: 24px;
      }
    }
  }
  .ant-select-item-option-content {
    color: #000;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selected-option {
    background-color: #e6f7ff; /* Màu nền khi option đã chọn */
    font-weight: bold; /* Đậm cho option đã chọn */
    position: relative; /* Để điều chỉnh vị trí biểu tượng dấu tick */
  }
  .selected-option::before {
    content: "✓"; /* Biểu tượng dấu tick */
    margin-right: 4px; /* Khoảng cách giữa biểu tượng và nội dung */
    position: absolute;
    left: 8px; /* Điều chỉnh vị trí của biểu tượng */
  }
}
.select-delivery-time-modal {
  .ant-modal {
    .ant-modal-content {
      padding: 0px;
      border-radius: 36px;
      background: #fff;
      width: auto;
      height: auto;
      flex-shrink: 0;
    }
    .ant-modal-close {
      top: 19px;
      .ant-modal-close-x {
        display: none;
      }
    }
  }
  .select-delivery-time-content {
    padding-top: 59px;
    padding-left: 24px;
    padding-right: 24px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    .title-schedule {
      margin-bottom: -18px;
      color: var(--TEXT, #282828);
      
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .row-delivery-time {
      display: inline-flex;
      height: 71px;
      padding: 24px 24px 24px 24px;
      justify-content: center;
      align-items: flex-start;
      flex-shrink: 0;
      border-radius: 12px;
      border: 1px solid var(--colordefault-border-background, #e6e6e6);
      background: var(--colordefault-background-color, #fff);
      span {
        color: #494949;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .select-component:focus {
        border: none;
        box-shadow: none;
        border-color: white;
      }
      .select-component {
        width: 100%;
        height: 23px;
        .ant-select-selector {
          border: none !important;
          border-color: white !important;
          box-shadow: none !important;
          padding: 0px;
          margin-left: 4px;
          span {
            color: var(--TEXT, #282828);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: 16px;
            padding-top: 3px;
          }
        }
        .ant-select-arrow {
          margin-right: -12px;
        }
      }
    }
    .modal-footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      .cancel-buttom {
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-right: 11px;
        border-radius: 12px;
        border: 1px solid #e2e2e2;
        background: #fff;
        height: auto;
        width: 111px;
      }
      .confirm-buttom {
        display: inline-flex;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #db4d29;
        color: #fff;
        text-align: center;
        color: #fef7ed;
        
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        width: 99px;
        height: auto;
      }
    }
  }
}
