$profile-pY: 22px;
$profile-pX: 12px;
$profile-img: 32px;
$height-header-theme2: 96px;

html {
  scroll-behavior: smooth;
}

body.disable-scroll {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px !important;
}

.theme2-header {
  height: var(--heightHeader);
  background-size: cover !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  margin: 0 auto;

  .page-container {
    height: 100%;

    .nav-top-right {
      height: 100%;

      .header-language {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: 76px;
        height: 25px;

        .link-language {
          cursor: pointer;

          .Flag-Default {
            padding-right: 0px;
          }
        }
      }

      .header-language-customize-theme2 {
        background-color: transparent;
        min-width: auto;

        .header-language {
          min-width: 100%;
          background-color: transparent !important;
          border: none;
          outline: none;
        }
      }

      .delivery-address-selector-theme2 {
        .delivery-address-header-box {
          width: 248px;
        }
      }

      .popover-header-theme2 {
        .login_content_theme2 {
          height: 100%;
          width: 100%;

          .title {
            width: 100%;
            white-space: nowrap;
          }
        }
      }
    }

    nav .nav-list {
      display: flex;
      height: 100%;
      align-items: center;

      .active-item-menu-theme2 {
        text-decoration: underline;
        text-underline-offset: 6px;
      }
    }
  }
}

.user-icon {
  cursor: pointer;
}

.icon-stroke-color :hover,
.icon-fill-color :hover {
  opacity: 80%;
}

.user-profile-contain {
  height: fit-content;
  width: 210px;
  display: flex;
  flex-direction: column;
  margin-top: 6px;

  .user-profile-icon {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #000000;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 12px;
    text-decoration: none;
  }
}

.userNameLogin {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 100px;
}

.fullName-over-length {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}

#themeHeader {
  height: unset;
}

.theme-2-header {
  height: $height-header-theme2;
}

.theme-2-header-overide-display {
  height: $height-header-theme2;
  z-index: 99;
}

.login_content_theme2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #000000;
  cursor: pointer;
  padding: 20px;
}

.logout_content_theme2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  color: #000000;
  cursor: pointer;
  padding: 20px;
}

body {
  margin: 0px;
  padding: 0px;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 0 !important;
}

.h1 {
  /* H1 - Title */
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #ffffff;
}

.h3 {
  /* H3 - Subtitle 1 */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.1em;
  color: #db4d29;
  margin-block-start: unset;
  margin-block-end: unset;
}

.h4 {
  /* H4 - Subtitle 2 */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.body-text {
  /* Body text */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.body-text-2 {
  /* Body text 2 _ Footer */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.text-size-16px {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  clear: both;
}

.display-flex {
  display: flex;
}

.page-container {
  max-width: var(--max-width-container);
  margin: 0 auto;
}

.header-theme2-new {
  height: 96px;

  .page-container-header-theme2 {
    margin: 0 auto;
  }

  .page-container-customize-header-theme2,
  .page-container-header-theme2 {
    .delivery-address-selector-theme2 {
      .delivery-address-header-box {
        .right-box {
          display: flex;
          justify-content: end;
          flex: 1;
          padding-right: 8px;
        }
      }
    }
  }

  .page-container-customize-header-theme2 {
    .nav-list {
      padding-left: 16px;
    }
    margin: 0px;
  }
}

.introduction-wrapper {
  width: 100%;
  height: 100%;

  .introduction-container {
    max-width: var(--max-width-container);
    margin: 0 auto;
  }
}

.banner-top {
  max-width: 1920px;
}

.banner-top .slick-slide {
  width: 100%;
  height: 20px;
}

.banner-top,
.review-slide,
.our-menu-banner {
  .slick-arrow {
    display: none !important;
  }
}

.banner-homepage-theme2 {
  .swiper-slide-banner-theme2 {
    a {
      display: flex;
      height: 100%;
    }
  }
}

.slick-dots {
  bottom: 25px;
}

.slick-dots li {
  width: unset !important;
  height: unset !important;
  margin: 0 7px !important;
  vertical-align: middle;
}

.slick-dots li img:nth-child(1) {
  display: block;
  opacity: 0.8;
}

.slick-dots li img:nth-child(2) {
  display: none;
}

.slick-dots li.slick-active img:nth-child(1) {
  display: none;
}

.slick-dots li.slick-active img:nth-child(2) {
  display: block;
}

.navigation {
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  transition: 0.5s;
  box-sizing: content-box;
  width: 100%;
}

.scroll-header-theme2 {
  position: fixed;
  top: 0;
  transition: top 0.5s ease-in-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -moz-transform: translateY(0);
  }
}

.brand {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 138px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;

  .ant-image {
    width: fit-content;
    height: 72px;

    .ant-image-img {
      height: 100%;
      max-width: 100%;
      width: auto;
    }
  }
}

.nav-top-right {
  float: right;
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-list {
  position: absolute;
}

.btn-view-more {
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-left: 20px;
  background: transparent;
  border: none;
}

.nav-list li:hover .nav-dropdown {
  display: block;
}

nav {
  float: left;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li a {
  display: flex;
  align-items: center;
  margin: 16px 12px;
  color: #fff;
  text-decoration: none;
}

nav ul li a.active {
  color: #ffeabc;
  border-bottom: 1px solid #ffeabc;
}

nav ul li a:hover {
  color: #fff;
  opacity: 0.8;
}

nav .nav-list .nav-dropdown .scroll-border-radius li a {
  opacity: 1;
}

nav ul li ul li {
  min-width: 256px;
}

.custom-menu-header-item {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-sub-menu-header-item {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;

  a {
    div:first-child {
      margin-right: 8px;
    }
  }
}

.nav-dropdown {
  max-height: 70vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  overflow-y: scroll;
  position: absolute;
  top: 40px;
  left: -45px;
  display: none;
  z-index: 2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: #ffffff;
  height: auto;
}

.scroll-border-radius {
  margin: 12px;
  margin-right: 6px;
  max-width: 264px;
  overflow-y: auto;
}

.nav-dropdown li a {
  background: #ffffff;
  color: #282828;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px !important;
  padding: 8px 12px !important;
  margin: 0;
}

/* Mobile navigation */
.menu_logo {
  display: none;
  padding-top: 8px;
  padding-left: 12px;
  margin-bottom: 34px;
}

.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 58px;
  width: 58px;
}

#nav-toggle {
  position: absolute;
  left: 18px;
  bottom: 19px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
}

#nav-toggle-close {
  display: none;
  position: absolute;
  right: 46px;
  top: 8px;
  cursor: pointer;
  height: 28px;
  width: 28px;
  background-image: url(../images/menu_close.svg);
  background-repeat: no-repeat;
  z-index: 2;
}

.banner-top .slick-list {
  .slick-track {
    display: flex;
    gap: 0px;
  }
}

.banner-top-img {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  height: auto;
  width: 100%;
}

/*top view*/
.top-view {
  margin-top: 80px;
  display: flex;
  gap: 38px;
}

.top-view-info {
  margin-top: 0px;
  display: flex;
  gap: 38px;
  padding-top: 60px;
}

.top-view-info img {
  width: 582px;
  height: 455px;
}

.top-view-left {
  width: 571px;
  height: 447px;
  background-image: url(../images/top-view-1.png);
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.top-view-left-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 51px;
  color: #fff;
  margin-top: 95px;
  text-align: center;
  width: 261px;
}

.top-view-left-title > span {
  color: #db4d29;
}

.top-view-left-content {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
  color: #fff;
  margin-top: 18px;
  text-align: center;
  width: 261px;
  padding-right: 29.5px;
  text-transform: capitalize;
}

.top-view-left-btn {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 38px;
  text-align: center;
  width: 261px;
  height: 60px;
  line-height: 60px;
  cursor: pointer;

  &::before {
    content: "";
    background: url(../images/btn-default-orange.png) no-repeat center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.top-view-left-btn > span {
  position: relative;
}

.top-view-left-btn:hover {
  &::before {
    opacity: 0.8;
  }
}

.top-view-right {
  width: 571px;
  height: 447px;
  background-image: url(../images/top-view-2.png);
  background-repeat: no-repeat;
  border-radius: 20px;
}

.top-view-right-p1 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 51px;
  color: #fff;
  margin-top: 64px;
  margin-left: 15px;
  float: left;
}

.top-view-right-p2 {
  color: #db4d29;
  font-weight: 900;
  font-size: 120px;
  line-height: 144px;
  margin-top: 50px;
  margin-bottom: -20px;
  float: left;
}

.top-view-right-p3 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 51px;
  color: #fff;
  clear: left;
  width: 258px;
  height: 102px;
  text-align: center;
  margin-left: 15px;
}

.top-view-right-btn {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 17px;
  text-align: center;
  width: 261px;
  height: 60px;
  line-height: 60px;
  cursor: pointer;

  &::before {
    content: "";
    background: url(../images/btn-default-orange.png) no-repeat center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.top-view-right-btn > span {
  position: relative;
}

.top-view-right-btn:hover {
  &::before {
    opacity: 0.8;
  }
}

.top-view-more-btn {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 59px;
  text-align: center;
  width: 261px;
  height: 60px;
  cursor: pointer;

  .button-label {
    text-transform: uppercase;
  }
}

.top-view-more-btn > span {
  position: relative;
  bottom: 75%;
}

.top-view-more-btn:hover {
  &::before {
    opacity: 0.8;
  }
}

.top-view-right-content {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 50px;
  margin-left: 15px;
}

.top-view-intro {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #db4d29;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.top-view-title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #282828;
  margin-bottom: 20px;
}

.mid-view {
  margin-top: 74px;
  margin-bottom: 0;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.mid-view-content {
  text-align: center;
  height: 100%;
}

.mid-view-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #db4d29;
  padding: 20px 0;
  text-transform: uppercase;
}

.mid-view-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.mid-view-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
  padding: 0 10px;
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.menu-special {
  height: 100%;
  background-size: cover !important;
}

.discount-img {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 8px;
  border-radius: 30px;
  width: fit-content;
}

.star-icon {
  width: 24px;
  height: 24px;
  background-image: url(../images/star-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  float: left;
}

.star-checked {
  width: 24px;
  height: 24px;
  background-image: url(../images/star-checked.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.menu-special-food {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #282828;
  text-align: left;
  padding-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.menu-special-describe {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #959595;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.banner-menu-special .slick-list {
  .slick-track {
    display: flex;
    gap: 32px;
  }
}

.banner-menu-special .swiper-slide {
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  height: 415px;
  background-color: #fff;
  text-align: center;

  .product-main-theme2 {
    width: 273px;
    text-align: left;
    height: 100%;

    .product-rating {
      margin-top: 6px;
    }
  }
}

.menu-special-image {
  cursor: pointer;
  border-radius: 20px;
  width: 249px;
  height: 249px;
  margin-bottom: 12px;
  background-size: cover !important;
}

.promote-price {
  height: 36px;
  line-height: 36px;
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #959595;
  margin-right: 12px;
}

.original-price {
  height: 36px;
  line-height: 36px;
  float: left;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #db4d29;
}

.menu-special-cart {
  height: 36px;
  line-height: 36px;
  position: absolute;
  right: 0px;
}

#themeBanner {
  .swiper-pagination-bullet {
    width: 19px;
    height: 19px;
    background: url(../images/slide-dot-active.svg) no-repeat;
    scale: 0.7;
    opacity: 0.5;
  }

  .swiper-pagination-bullet-active {
    scale: 1;
    opacity: 1;
  }
}

.banner-menu-special {
  width: 891px;
  position: relative;

  .button-left-arrow {
    position: absolute;
    top: 39%;
    cursor: pointer;
    left: -154px;
  }

  .button-right-arrow {
    position: absolute;
    top: 39%;
    cursor: pointer;
    right: -154px;
  }

  .swiper-pagination-bullet {
    width: 19px;
    height: 19px;
    scale: 0.7;
    opacity: 0.5;
    background-color: unset;
  }

  .swiper-pagination-bullet-active {
    scale: 1;
    opacity: 1;
    background-color: unset;
  }

  .swiper-pagination {
    position: relative;
    padding-top: 40px;
  }

  .swiper-pagination-bullet-active-next,
  .swiper-pagination-bullet-active-next-next,
  .swiper-pagination-bullet-active-prev,
  .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0) !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

.banner-menu-item {
  :focus-visible {
    outline: none !important;
  }
}

.banner-menu-item:focus-visible {
  outline: none !important;
}

.banner-menu-special .slick-next {
  right: -97px;
}

.banner-menu-special .slick-prev {
  left: -162px;
}

.banner-menu-special .slick-prev:before {
  content: url(../images/back-arrow-left.png);
}

.banner-menu-special .slick-next:before {
  content: url(../images/back-arrow-right.png);
}

.menu-special-intro {
  color: #282828;
  text-align: center;
  padding-top: 44px;
}

.menu-special-title {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #db4d29;
  text-align: center;
  padding-top: 6px;
}

.menu-special-nav {
  text-align: center;
}

.menu-special-ul {
  margin-top: 44px;
  margin-bottom: 36px;
  background: #fff;
  display: inline-block;
  padding-left: 0;
  height: 60px;
  border-radius: 29px;

  .ant-popover {
    z-index: 100;
  }

  .ant-popover-inner-content {
    ul {
      margin-top: 15px;
      width: 300px;
      text-align: left;
      max-height: 400px;
      overflow: auto;
      padding: 8px;

      li a {
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;

        div {
          z-index: 100;
        }
      }
    }
  }
}

.menu-special-nav li {
  list-style: none;
  display: inline-block;
}

.menu-special-nav a {
  display: block;
  color: #282828;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 0 20px;
  line-height: 60px;
  border-radius: 50px;
  cursor: pointer;
}

.menu-special-nav a.active {
  background-color: #db4d29;
  color: #fff;
  border-radius: 50px;
}

.menu-special .slick-dots {
  bottom: -75px;
  left: 0;
}

.our-menu-banner-intro {
  color: #282828;
  text-align: center;
  margin-top: 11px;
}

.our-menu-banner-title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  text-transform: capitalize;
  color: #db4d29;
  text-align: center;
  margin-bottom: 50px;
}

.our-menu-slide .slick-slide:nth-of-type(even) {
  margin-top: 48px;
}

.our-menu-slide .slick-list {
  .slick-track {
    display: flex;
    gap: 37px;
  }
}

.our-menu-img {
  width: 272px !important;
  height: 462px;
}

.our-menu-slide .slick-slide {
  border-radius: 20px;
  width: 272px;
}

.review {
  height: 905px;
  background-image: url(../images/review-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 79px;
}

.review-intro {
  color: #282828;
  text-align: center;
  margin-bottom: 6px;
}

.review-title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  text-transform: capitalize;
  color: #db4d29;
  margin-bottom: 63px;
}

.review-slide {
  width: 788px;
  float: right;
}

.review-slide .slick-list {
  .slick-track {
    display: flex;
    gap: 36px;
  }
}

.review-slide-item {
  width: 376px !important;
}

.review-slide .slick-slide {
  border-radius: 20px;
  height: 430.27px;
  width: 376px;
  text-align: center;
  background-color: #fff;
}

.reviewer-img {
  margin: 22px auto 25px auto;
}

.reviewer-name {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  color: #282828;
}

.reviewer-location {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
  margin-bottom: 17px;
}

.review-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 19.73px;
}

.review-slide-item {
  .review-content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #282828;
    margin: 0 30px;
  }
}

.review-slide .slick-dots {
  bottom: -76.19px;
}

.promotion-news-title {
  text-align: center;
  color: #282828;
  margin-bottom: 8px;
}

.post-title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  text-transform: capitalize;
  color: #db4d29;
  margin-bottom: 36px;
}

.post-item {
  display: flex;
  gap: 40px;
}

.post-img {
  height: 367px;
  width: 367px;
  margin-bottom: 17px;
}

.post-item-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #282828;
  margin-bottom: 7px;
}

.post-item-date {
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #b1b1b1;
  margin-bottom: 16px;
}

.post-item-describe {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
  margin-bottom: 19px;
}

.read-more {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #db4d29;
  cursor: pointer;
}

.btn-read-more {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
  width: 261px;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  margin: 38px auto 0 auto;

  &::before {
    content: "";
    background: url(../images/btn-default-orange.png) no-repeat center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.btn-read-more > span {
  position: relative;
}

.btn-read-more:hover {
  &::before {
    opacity: 0.8;
  }
}

.booking {
  margin-top: 49px;
  padding-top: 115px;
  height: 576px;
  background-image: url(../images/booking-bg.png);
  background-repeat: no-repeat;
  background-position: center;
}

.booking-card {
  width: 983px;
  height: 362px;
  margin: auto;
  background-image: url(../images/booking-card.png);
  background-repeat: no-repeat;
  background-position: bottom;

  text-align: center;
}

.booking-card-left {
  margin-left: 127px;
  float: left;
  width: 317px;
}

.booking-logo {
  margin-top: 24px;
  width: 143.62px;
  height: 114.68px;
}

.booking-card-right {
  margin-left: 153.5px;
  float: left;
  width: 366px;
}

.booking-time {
  display: flex;
  text-align: left;
}

.booking-time-left,
.booking-time-right {
  flex: 1 1 0;
  width: 0;
}

.booking-btn {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: #db4d29;
  text-align: center;
  width: 261px;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  margin: 29px auto 0 auto;

  &::before {
    content: "";
    background: url(../images/btn-default-white.png) no-repeat center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.booking-btn > span {
  position: relative;
}

.booking-btn:hover {
  &::before {
    opacity: 0.8;
  }
}

.booking-card-left .h4 {
  margin-top: 11.32px;
  color: #f9f1df;
}

.booking-card-left .h1 {
  margin-top: 10px;
}

.booking-card-right .text-size-16px {
  margin-top: 24px;
}

.booking-card-right .h1 {
  margin-top: 8px;
}

.booking-card-right .h4 {
  margin-bottom: 8px;
}

.brand-text {
  margin-top: 11px;
  margin-bottom: 3px !important;
}

.location-text {
  margin-top: 8px;
  margin-bottom: 2px !important;
}

.system-brand-text {
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.line-footer {
  height: 72px;
  background-image: url(../images/line-footer.png);
  background-repeat: no-repeat;
  background-position: center;
}

.footer-bg {
  height: 351px;
}

.footer-info {
  display: flex;
  justify-content: center;
  gap: 67px;
  padding-top: 32px;
  padding-bottom: 25px;
  margin-bottom: 20px;
}

.logo-footer {
  width: 209px;
  min-height: 160px;
}

.store-info {
  width: 316px;
}

.footer-info .h3 {
  line-height: 24px;
}

.store-info-flex {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 22px;
}

.store-contact {
  width: 179px;
}

.store-contact-flex {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 22px;
  text-transform: uppercase;
}

.store-linked {
  width: 304px;
}

.store-linked-flex {
  display: flex;
  gap: 19px;
  margin-top: 22px;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 0;
  border-radius: 0;
  z-index: 99;
}

.location-icon,
.phone-icon,
.sms-icon,
.shop-icon {
  display: flex;
  gap: 16px;
}

.location-icon {
  margin-bottom: 16px;
}

.phone-icon {
  margin-bottom: 17px;
}

.sms-icon {
  margin-bottom: 18px;
}

.policy-information {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 9px;

  div:not(:last-child) {
    border-right: 1px solid #fff;
    padding-right: 16px;
  }
}

.copyright {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.menu_arrow_right {
  display: none;
}

.expand-clicked {
  color: #f1d89f !important;
}

.menu_point {
  display: none;
}

.active-item {
  background: #db4d29 !important;
  color: #f1d89f !important;
  border-radius: 12px;
}

.shop-icon .text-blue {
  color: #0065fd;
}

.advertisement-custom-container {
  display: flex;
  width: 100%;
  padding: 40px 72px;
  margin: auto;
}

.advertisement-custom-content {
  display: flex;
  gap: 40px;
  margin: auto;
  justify-content: center;

  .advertisement-item {
    height: 450px;
    width: 580px;
    border-radius: 20px;
  }
}

.border-image {
  border-radius: 7px !important;
}

.cart-wrap {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header-wrap {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 74px;
}

.d-none {
  display: none;
}

/*template responsive*/
@media only screen and (max-width: 1336px) {
  #themeHeader {
    margin-bottom: unset;
  }

  /* Header Mobile */
  .header-theme2-new {
    .nav-input-header:checked ~ .overlay {
      display: block;
      z-index: 998;
    }

    .nav-input-header:checked ~ .nav-menu-mobile {
      transform: translateX(0%);
      opacity: 1;
      z-index: 999;
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: none;
    }

    .nav-menu-mobile {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: calc(100% - 50px);
      max-width: 500px;
      background-color: #fff;
      transform: translateX(-100%);
      opacity: 0;
      transition: all linear 0.2s;
    }

    .nav-menu-mobile .close-nav-bar {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 999;
    }

    .brand-mobile {
      width: unset;
      height: 64px;

      margin: 8px 0px 22px 12px;
    }

    .profile-mobile {
      position: absolute;
      bottom: 0;
      padding: $profile-pY $profile-pX;
      display: flex;
      align-items: center;
      gap: 14px;
      width: 100%;
      background: #f8f8f8;
      color: #000;
      text-decoration: none;
      
      img {
        border-radius: 16px;
        width: $profile-img;
        height: $profile-img;
      }
    }

    .profile-mobile-top {
      position: static;
    }

    #nav-menu-mobile-list {
      max-height: 30em;
      overflow-y: scroll;
    }

    #nav-menu-mobile-list li {
      position: relative;
    }

    #nav-menu-mobile-list {
      max-height: calc(100% - 212px);
      overflow-y: scroll;
    }

    .arrow-right-mobile {
      position: absolute;
      right: 10px;
    }

    .menu_arrow_rotate {
      transform: rotate(-90deg);
      transition: transform 0.4s;
    }

    .menu_arrow_rotate_left {
      transform: rotate(90deg);
    }

    .menu_arrow_rotate_reset {
      transform: rotate(0deg);
      transition: transform 0.4s;
    }

    .menu_arrow_hide {
      display: none;
    }
  }

  .delivery-address-selector-mobile-mode {
    position: relative;
    z-index: 10;
    top: 83px;

    .delivery-address-selector-theme2 {
      padding-bottom: 16px;

      .delivery-address-header-box {
        .middle-box {
          width: auto;
        }

        .right-box {
          display: flex;
          justify-content: end;
          flex: 1;
          padding-right: 8px;
        }
      }
    }
  }

  .theme2-header {
    position: unset;
    top: unset;
    width: 100%;
    max-width: 100%;
    z-index: 999;
    margin: unset;
    height: calc(var(--heightNewHeader) - 2px);

    .page-container {
      .brand {
        .ant-image {
        }
      }
    }
  }

  .nav-mobile {
    display: block;
    height: 100%;
  }

  .user-icon {
    display: none;
  }

  .nav-top-right {
    position: absolute;
    top: 0;
    right: 16px;
    display: flex;
    align-items: center;
    height: 58px;
    gap: 25px;
  }

  .brand {
    z-index: 10;
  }

  .brand > img {
    width: 122px;
    height: 116.77px;
  }

  nav ul li {
    float: none;
  }

  nav ul li ul li {
    margin: 0 8px;
  }

  nav ul li ul li a {
    padding-left: 30px;
  }

  .menu_point {
    margin-left: -18.5px;
    margin-right: 8px;
  }

  .menu_point.active-menu-point {
    display: inline-block;
  }

  .menu_collapse {
    display: none;
  }

  .menu_arrow_right {
    position: relative;
    float: right;
    display: block;
  }

  .expand-clicked {
    color: #000 !important;
  }

  .nav-list {
    left: 0;
    top: 0;
    z-index: 1;
    width: 398px;
    height: 100vh;
    background: #fff;
    overflow: auto;
  }

  .nav-dropdown {
    position: absolute;
    height: unset;
    box-shadow: unset;
    border-radius: unset;
    margin: -12px auto;
  }

  .scroll-border-radius {
    height: unset;
    width: unset;
  }

  nav ul li a {
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    line-height: 32px;
    color: #000;
    padding: 12px !important;
    line-height: 20px;
    margin: 0;
    word-break: break-word;
  }

  nav ul li a:hover {
    background: #fff;
    color: #000;
    opacity: 1;
  }

  .banner-top {
    max-width: 428px;
    margin: 0 auto;
  }

  .banner-top .slick-dots {
    line-height: 12.26px;
    bottom: 10.61px;
  }

  .banner-top-img {
    max-width: 100%;
    height: auto;
    background-position: center;
    position: relative;
  }

  .slide-dot {
    width: 8.36px;
    height: 8.32px;
  }

  .slide-dot-active {
    width: 12.32px;
    height: 12.26px;
  }

  .top-view {
    margin-top: 2px;
    flex-direction: column;
  }

  .top-view-left,
  .top-view-right {
    height: 310px;
    width: 100%;
    background-size: 396px;
  }

  .top-view-left-title {
    width: 181.01px;
    height: 70.74px;
    margin-top: 65.88px;
    line-height: 42px;
    font-size: 20px;
  }

  .top-view-left-content {
    width: 140.09px;
    height: 27.74px;
    margin-top: 17.38px;
    line-height: 14.63px;
    font-size: 10px;
    padding-right: 20.81px;
  }

  .top-view-left-btn {
    width: 187.93px;
    height: 48px;
    background-size: 187.93px;
    margin-top: 17.26px;
    font-size: 16px;
    line-height: 48px;

    &::before {
      background-size: 187.93px;
    }
  }

  .top-view-right-p1 {
    line-height: 42px;
    font-size: 20px;
  }

  .top-view-right-p2 {
    font-size: 80px;
    line-height: 95.76px;
    margin-top: 35px;
  }

  .top-view-right-p3 {
    line-height: 42px;
    font-size: 20px;
    width: 220px;
    height: 70px;
    margin-left: 0;
  }

  .top-view-right-btn {
    width: 187.93px;
    height: 48px;
    margin-top: 17.26px;
    font-size: 16px;
    line-height: 48px;
    margin-left: 15px;

    &::before {
      background-size: 187.93px;
    }
  }

  .top-view-right-content {
    margin-top: 28px;
    font-size: 12px;
    line-height: 14.63px;
  }

  .top-view-info {
    margin-top: 42px;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .top-view-info img {
    height: 100%;
  }

  .top-view-info img,
  .top-view-intro,
  .top-view-title {
    width: 100%;
  }

  .top-view-intro {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 8px;
    padding-left: 16px;
  }

  .top-view-title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 21px;
    padding-left: 16px;
  }

  .top-view-more-btn {
    margin-top: 21px;
    width: 234.91px;
    margin-left: 16px;
  }

  .mid-view {
    flex-direction: column;
    margin-top: 102px;
    margin-bottom: 65px;
  }

  .menu-special-intro {
    padding-top: 56px;
    line-height: 32px;
  }

  .menu-special-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    padding-top: 4px;
  }

  .mid-view-text {
    text-align: center;
    padding: 0 84px;
  }

  .menu-special-ul {
    margin-top: 37px;
    height: 48px;
    width: 100%;
    text-align: left;
    margin-bottom: 47px;
    overflow: hidden;
  }

  .menu-special-nav a {
    line-height: 48px;
    font-size: 14px;
    text-align: center;
  }

  .banner-menu-special {
    max-width: 100% !important;

    .swiper-slide {
      width: 316px !important;

      .product-main-theme2 {
        width: 340px;
        margin-left: 0 !important;
        margin-top: 0 !important;

        .product-img,
        .product-description {
          width: 316px;
        }

        .price-box {
          .price-box-left {
            flex-direction: column !important;
          }
        }
      }
    }
  }

  .banner-menu-special .swiper-slide {
    height: 489px;
  }

  .button-left-arrow,
  .button-right-arrow {
    display: none;
  }

  #themeBanner,
  .banner-menu-special {
    .swiper-pagination-bullet {
      scale: 0.5;
    }

    .swiper-pagination-bullet-active {
      scale: 0.7;
    }
  }

  .banner-menu-special {
    .swiper-pagination {
      padding-top: 65px;
    }
  }

  .menu-special .slick-dots {
    bottom: -93px;
    line-height: 12.26px;
  }

  .menu-special-describe,
  .discount-img,
  .wishlist {
    display: none !important;
  }

  .menu-special-food {
    margin-bottom: 12px;
  }

  .menu-special-cart {
    height: 48px;
    line-height: 48px;
    right: 12px;
  }

  .menu-special-image {
    width: 268.25px;
    height: 264.59px;
    margin: 0 auto;
  }

  .menu-special {
    height: 861px;
    background-position-x: -1030px;
    padding: 0px 16px 16px 16px;
  }

  .our-menu-slide .slick-slide:nth-of-type(even) {
    margin-top: 33px;
  }

  .our-menu-banner-intro {
    font-size: 16px;
    line-height: 32px;
    margin-top: 19px;
  }

  .our-menu-banner-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #db4d29;
    margin-bottom: 42px;
  }

  .our-menu-img {
    width: 187.33px !important;
    height: 318.18px !important;
    margin-bottom: 19.82px;
  }

  .our-menu-slide .slick-list {
    .slick-track {
      display: flex;
      gap: 0px;
    }
  }

  .our-menu-slide .slick-slide {
    margin-right: 21px;
    margin-left: 0;
    width: 187.33px !important;
  }

  .review-intro {
    font-size: 16px;
    line-height: 32px;
    margin-top: 68px;
    margin-bottom: 4px;
  }

  .review-title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 36px;
  }

  .review-slide .slick-dots {
    bottom: -73.19px;
    line-height: 12.26px;
  }

  .review-slide .slick-list {
    height: 342px;
  }

  .review-slide-item {
    width: 241px !important;
  }

  .review-slide .slick-slide {
    width: 241px;
    height: 342px;
  }

  .review-slide {
    width: 241px;
    margin: auto;
    float: none;
    margin-left: 100px;
  }

  .review {
    height: 641px;
    margin-top: 45.83px;
    background-size: 1371px;
    background-position-x: -307px;
  }

  .promotion-news-title {
    font-size: 16px;
    line-height: 32px;
    margin-top: 32px;
    margin-bottom: 4px;
  }

  .post-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #db4d29;
  }

  .promotion-news {
    overflow: hidden;
  }

  .post-img {
    height: 323px;
    width: 323px;
    margin-bottom: 15px;
  }

  .post-item-title {
    margin-bottom: 4px;
    line-height: 22px;
  }

  .post-item-date {
    margin-bottom: 16px;
  }

  .btn-read-more {
    margin: 0;
    width: 234.91px;
  }

  .read-more {
    margin-bottom: 36px;
  }

  .booking-card {
    width: 396px;
    height: 847px;
    border-radius: 20px;
    background-image: url(../images/booking-card-mobile.png);
  }

  .booking-card-left,
  .booking-card-right {
    margin-left: 0;
    clear: left;
    width: inherit;
  }

  .booking-logo {
    margin-top: 39px;
    width: 129px;
    height: 103px;
  }

  .booking-card-left .h4 {
    margin-top: 24px;
    font-size: 12px;
    line-height: 15px;
  }

  .booking-card-left .h1 {
    margin-top: 2px;
    font-size: 32px;
    line-height: 39px;
  }

  .booking-btn {
    margin-top: 32px;
    width: 199.2px;
    height: 48px;
    line-height: 48px;

    &::before {
      background-size: 199.2px;
    }
  }

  .booking-card-right .text-size-16px {
    margin-top: 47px;
  }

  .location-text {
    margin-top: 32px;
    margin-bottom: 9px !important;
    font-size: 16px;
    line-height: 20px;
  }

  .brand-text {
    margin-top: 42px;
    margin-bottom: 9px !important;
    font-size: 16px;
    line-height: 20px;
  }

  .booking-card-right .body-text {
    font-size: 16px;
    line-height: 20px;
  }

  .system-brand-text {
    padding-bottom: 33px;
    margin-bottom: 33px;
    font-size: 18px !important;
    line-height: 22px !important;
  }

  .booking-card-right .h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .booking-time .h4 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 21px;
  }

  .booking {
    height: 1007px;
    background-repeat: unset;
    background-position: unset;
    margin-top: 67px;
  }

  .booking-time {
    padding-left: 20px;
  }

  .footer-bg {
    height: 1045px;
    background-repeat: unset;
    background-position: unset;
  }

  .footer-info {
    flex-direction: column;
    gap: 22px;
    padding-top: 29px;
    padding-bottom: 42px;
  }

  .footer-info .h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .logo-footer {
    margin: auto;
    width: 164px;
    height: 145px;
    background-size: 164px 145px;
  }

  .store-contact-flex {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
  }

  .store-linked-title .h3,
  .store-contact-title .h3 {
    letter-spacing: unset;
    line-height: 24px;
  }

  .location-icon {
    margin-bottom: 21px;
  }

  .phone-icon {
    margin-bottom: 22px;
  }

  .sms-icon {
    margin-bottom: 26px;
  }

  .policy-information {
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-top: 39px;
    gap: 12px;
    padding-bottom: 13px;
  }

  .policy-information-border {
    width: fit-content;
  }

  .store-linked-flex {
    margin-top: 20px;
  }

  .store-info-flex {
    margin-top: 18px;
  }

  .store-info-flex .body-text-2 {
    font-size: 18px;
    line-height: 22px;
  }

  .shop-icon .text-blue {
    margin-bottom: 34px;
  }

  .copyright {
    margin: 23px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .banner-menu-special .slick-prev,
  .banner-menu-special .slick-next {
    display: none !important;
  }

  .selected-menu-mobile {
    display: block !important;
  }

  .nav-dropdown li a:hover {
    background: unset;
    color: unset;
    border-radius: unset;
  }

  .advertisement-custom-container {
    padding: 0;
    max-width: 396px;
    margin: auto;
  }

  .advertisement-custom-content {
    flex-direction: column;
    max-width: 396px;
    margin: 0 auto;

    .advertisement-item {
      height: 310px;
      width: 395px;
      border-radius: 20px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .banner-top-img {
  }

  .theme-2-header {
    height: calc($height-header-theme2 - 12px);
  }
}

/*template responsive*/
@media only screen and (max-width: 390px) {
  .page-container {
    max-width: 358px;
  }

  .advertisement-custom-content {
    .advertisement-item {
      height: 310px;
      width: 364px;
    }
  }

  .banner-menu-special {
    .swiper-slide {
      .product-main-theme2 {
        margin-left: 0 !important;
        margin-top: 0 !important;

        .ant-card-body > div {
          height: 455px;
          justify-content: space-between;
        }

        .product-img,
        .product-description {
          width: 273px;
        }
      }
    }
  }
}

/*template responsive*/
@media only screen and (max-width: 375px) {
  .page-container {
    max-width: 343px;
  }

  .advertisement-custom-content {
    .advertisement-item {
      height: 310px;
      width: 349px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .banner-menu-special {
    .swiper-slide {
      .product-main-theme2 {
        .product-img,
        .product-description {
          width: 244px;
        }
      }
    }
  }
}

@media (max-width: 1336px) {
  .header-theme2-new {
    position: fixed;
    top: 0px;
    height: calc($height-header-theme2 - 12px);

    .page-container {
      display: flex;
      align-items: center;

      .brand {
        height: 100%;

        .ant-image {
          width: auto;
          height: 72px;

          .ant-image-img {
            height: 100%;
            max-width: 100%;
            width: auto;
          }
        }
      }

      .nav-list {
        display: none !important;
      }

      .nav-mobile {
        top: -12px;
      }

      .delivery-address-tablet-theme2 {
        position: relative;
        left: 10%;

        .delivery-address-selector-theme2 {
          .delivery-address-header-box {
            width: 228px;
          }
        }
      }
    }

    .user-icon {
      display: block;
    }
  }

  .advertisement-custom-container {
    .advertisement-custom-content {
      gap: 24px;
      padding: 16px 0px;
    }
  }
}

.custom-language {
  left: 0;
  bottom: calc(0px + $profile-img + 2 * $profile-pY) !important;
}

.sub-nav-menu-header-theme2 {
  display: none;
  position: absolute;
  right: -102%;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  z-index: 999;
  max-height: 264px;
  overflow-y: auto;
  top: 0px;

  .subMenuHover {
    margin: 4px 8px;
  }
}

#nav-menu .sub-nav-menu-header-theme2-parent::after {
  content: "";
  position: absolute;
  top: 10%;
  background-color: red;
  width: 100px;
  height: 50px;
  z-index: 10;
  right: -20px;
  opacity: 0;
}

#nav-menu .sub-nav-menu-header-theme2 li:hover,
#nav-menu .sub-nav-menu-header-theme2 li:hover a {
  text-decoration: none;
}

.submenu-item-parent-them2:hover + .sub-nav-menu-header-theme2,
.sub-nav-menu-header-theme2:hover {
  display: block !important;
}

.nav-dropdown-header-theme2 {
  position: absolute;
  top: 32px;

  .scroll-border-radius {
    overflow-y: initial;
  }
}

@media only screen and (max-width: 575px) {
  nav .nav-list {
    display: none !important;
  }

  #nav-toggle {
    display: flex;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 72px;
  }

  .delivery-address-selector-mobile-mode {
    top: var(--heightHeader);

    .delivery-address-selector-theme2 {
      padding: 8px 16px 16px 16px;

      .delivery-address-header-box {
        width: 100%;
      }
    }
  }

  .theme-2-header {
    height: calc($height-header-theme2 + var(--heightHeader) - 16px);
  }

  .header-theme2-new {
    position: fixed;
    top: 0px;
    height: 72px;

    .nav-top-right {
      .user-icon {
        display: none;
      }
    }

    .page-container {
      max-width: 396px;
      margin: 0 auto;

      .ant-image {
        width: 154px;
        height: 48px;

        .ant-image-img {
          height: 100%;
          max-width: 100%;
        }
      }

      .nav-mobile {
        top: 0;
      }
    }

    .scroll-header-delivery-theme2 {
      position: fixed;
      z-index: 99999;
      top: 83px;
      left: 0;
      right: 0;
      transition: top 0.5s ease-in-out;
    }
  }

  .advertisement-custom-container {
    padding-bottom: 16px;
    padding-top: 16px;
    width: 100%;
    max-width: 100%;

    .advertisement-custom-content {
      gap: 24px;
      padding: 0px !important;
      max-width: 100%;

      .advertisement-item {
        width: auto;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: center;
        padding: 0px 16px;
        min-height: 310px;
        max-height: 310px;
      }
    }
  }

  .banner-menu-special {
    max-width: 100% !important;

    .swiper-slide {
      width: 189px !important;

      .product-main-theme2 {
        width: 189px;
        height: auto;

        .ant-card-body > div {
          height: 100% !important;
        }

        .product-img,
        .product-description {
          width: 189px !important;
        }

        .price-box {
          .price-box-left {
            flex-direction: column !important;
          }
        }
      }
    }
  }

  .menu-special {
    height: auto;
    padding-bottom: 16px;
    padding: 0px 16px 16px 16px;

    .menu-special-nav {
      .overlay-menu-special-theme2 {
        left: 0px !important;
        width: 100%;
        padding: 0px 12px;

        .ant-popover-content {
          .ant-popover-inner-content {
            ul li a {
              line-height: normal;
              padding: 12px 0px;
            }
          }
        }
      }
    }
  }

  .introduction-container {
    .top-view-info {
      margin-top: 0px;
      padding-top: 16px;
    }

    .mid-view {
      margin-bottom: 0px;
      gap: 49px;
      padding-left: 68px;
      padding-right: 68px;
      padding-bottom: 47px;
      margin-left: 16px;
      margin-right: 16px;
      flex-direction: column;
      display: flex;

      .mid-view-content {
        padding-left: 20px;
        padding-right: 20px;

        .mid-view-text {
          padding: 0px 0px;
        }
      }

      .mid-view-text {
        -webkit-line-clamp: unset;
      }
    }

    .button-introduction {
      display: flex;
      justify-content: center;
      text-decoration: none;
    }
  }

  .delivery-address-selector-mobile-mode {
  }

  .banner-homepage-theme2 {
    .banner-top-img {
    }

    .swiper-slide-banner-theme2 {
      a {
        display: flex;
        height: 100%;
      }
    }
  }

  .introduction-container {
    .top-view-info {
      margin-top: 0px;
    }
  }
}

@media (min-width: 1920px) {
  .theme-2-header {
    .page-container-customize-header-theme2 {
      .nav-list {
        padding-left: 176px;
      }
      .nav-top-right {
        padding-right: 28px;
      }
    }
  }
}
