.address-popover {
  position: absolute;
  left: 0;
  z-index: 1000;
  background-color: white;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 3px;
  font-size: 1em;
  .address-popover-item {
    padding: 12px;
    border-bottom: 1px solid #d9d9d9;

    &:hover {
      cursor: pointer;
    }

    .ant-row {
      align-items: center;
    }
  }
}
