#themeRelatedProductsCheckout {
  scroll-margin-top: 70px;
}
.check_out_theme2_related_products {
  padding-top: 10px;
  display: flex;
  flex-flow: column nowrap;
  .header_title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    color: #db4d29;
    margin: auto !important;
    cursor: pointer;
  }
  .product_list {
    display: flex;
    justify-content: center;

    .product-main-theme2 {
      width: 100% !important;
      max-width: 350px;
    }
    .product_row {
      display: flex;
      padding: 30px 0;
      width: 80%;
      position: relative;
    }
    .swiper {
      .swiper-button-prev,
      .swiper-button-next {
       display: none; 
      }
    }
    .button-left-arrow {
      cursor: pointer;
      left: -100px;
      position: absolute;
      top: 39%;
      width: 100px;
      height: auto;
    }
    .button-right-arrow {
      cursor: pointer;
      right: -100px;
      position: absolute;
      top: 39%;
      width: 100px;
      height: auto;
    }
  }
}

@media (max-width: 639px) {
  .check_out_theme2_related_products {
    .product_list {
      width: 100%;
      .product_row {
        display: flex;
        padding: 16px;
        .swiper-related-product {
          .swiper-wrapper {
            .swiper-slide-related-product {
              width: 273px !important;
              .product-main-theme2 .m-content,
              .checkout-related-product-item-theme2 .m-content {
                margin: 4px 0px 4px 0px;
                padding: 0px 12px 0px 12px;
              }
              .product-main-theme2 .price-box,
              .checkout-related-product-item-theme2 .price-box {
                margin: 4px 12px 16px 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .product_list {
    overflow: scroll;
    max-width: 375px;
    flex-flow: unset !important;
    .product_row {
      padding: 16px 0 !important;
    }
  }
}
