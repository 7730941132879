.post-detail-theme2 {
  .tag-list-theme2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    max-width: 784px;
    .tag-title {
      color: #282828;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .tag-blog-them2 {
      height: 40px;
      background-color: #eee;
      border: 1px solid #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1px 8px;
      padding: 0px 8px;
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    margin-bottom: 40px;
    gap: 10px;
    .btn-blog-next {
      width: 183px;
      height: 58px;
      padding: 16px 24px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: #db4d29;
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      svg path {
        fill: #fff;
      }
    }
    .btn-blog-pre {
      width: 183px;
      height: 58px;
      padding: 16px 12px ;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: #db4d29;
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      svg path {
        fill: #fff;
      }
    }

    .btn-blog-next {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}


@media (max-width: 575px) {
  .post-detail-theme2 {
    .btn {
      margin: 24px 0px;
      .btn-blog-next, .btn-blog-pre {
        font-size: 14px;
        padding: inherit;
      }
    }
  }
}
