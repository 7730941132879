.toast-message-calculation-change-password {
  padding: 12px;
  background-color: #effbf2;
  border-radius: 12px;
  border: 1px solid #299c44;
  width: fit-content;
  .ant-notification-notice-content {
    .ant-notification-notice-message {
      color: #299C44;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      margin-bottom: 0;
      padding-inline-end: 0;

      @media (max-width: 429px) {
        font-size: 15px;
      }

      @media (max-width: 409px) {
        font-size: 14px;
      }

      @media (max-width: 389px) {
        font-size: 13px;
      }

      @media (max-width: 369px) {
        font-size: 12px;
      }
    }
  }
}
