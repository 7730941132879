.my-profile-delete-address-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-modal-content {
    width: 257px;
    border-radius: 12px;
    button.ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      position: relative;
      width: 257px;
      left: -24px;
      top: -20px;
      background-color: rgb(219, 77, 41);
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-modal-title {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: white;
      }
    }
    .ant-modal-body {
      .delete-container {
        .content {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      button {
        font-size: 1.2rem;
        height: 32px;
        min-width: 69px;
        text-align: center;
      }

      .ant-btn-default {
        border: 1px solid #e2e2e2;
        border-radius: 12px;

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #282828;
      }
      .ant-btn-primary {
        background: #026f30;

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
