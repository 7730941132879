.blog-wrapper {
  // min-height: 50vh;
  // background-color: white;

  max-height: 322px;
  height: 100%;

  .container-fluid {
    width: 100%;
  }
  .container {
    width: 80%;
    margin: 0 auto;
  }

  .blog-section {
    padding-bottom: 80px;
  }

  .blog-header {
    width: 100%;
    height: 300px;
    text-align: center;
    background-size: cover !important;
  }
  .blog-header h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #282828;
    padding-top: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.blog-header-customize-theme2 {
}
