.about-us-wrapper {
  min-height: 50vh;
  background-color: white;

  .container-fluid {
    width: 100%;
  }
  .container {
    width: 80%;
    margin: 0 auto;
  }

  .about-us-section {
    padding-bottom: 80px;
  }

  .about-us-header {
    width: 100%;
    height: 300px;

    line-height: 300px;
    text-align: center;

    background: url(../images/about-us/Frame_6022.png) no-repeat center center;
    background-size: cover;
  }
  .about-us-header h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #282828;
  }

  .about-us-intro {
  }
  .about-us-intro .about-us-intro-wrapper {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
  }

  .about-us-intro-wrapper .intro-thumbnail {
    flex: 1;
    margin: 10px;
    display: flex;
    justify-content: center;
  }
  .about-us-intro-wrapper .intro-thumbnail img {
    content: url(../images/about-us/japanese-man-making-coffee-restaurant.png);
    width: 100%;
    max-width: 582px;
  }

  .about-us-intro-wrapper .intro-content {
    flex: 1;
    margin: 10px;
  }

  .about-us-intro-wrapper .intro-content h2 {
    color: #db4d29;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
  }

  .about-us-description-wrapper {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  .description-signature h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 79px;
  }

  .about-us-highlight {
  }

  .about-us-highlight-wrapper {
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: space-evenly;
  }
  .about-us-highlight-wrapper .highlight-section {
    display: inline-block;
    min-width: 200px;
    text-align: center;
    padding: 0 18px;
  }

  .about-us-highlight-wrapper .highlight-section a {
    text-decoration: none;
    color: black;
  }
  .about-us-highlight-wrapper .highlight-section a img {
    max-height: 60px;
  }

  .about-us-description-wrapper .highlight-section a p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .about-us-highlight-wrapper .highlight-section a h3 {
    margin-top: 20px;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #db4d29;
  }

  #highlight-1 {
    content: url(../images/about-us/ico/salad.png);
    content: url(../images/about-us/ico/salad.png);
  }
  #highlight-2 {
    content: url(../images/about-us/ico/fast-time.png);
  }
  #highlight-3 {
    content: url(../images/about-us/ico/chef.png);
  }
  #highlight-4 {
    content: url(../images/about-us/ico/tray.png);
  }

  .about-us-video {
  }
  .about-us-video-wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .about-us-video-wrapper iframe {
    display: block;
    margin: 0 auto;
    max-height: 600px;
    max-width: 900px;
    border-radius: 20px;
  }

  @media screen and (max-width: 480px) {
    .container,
    .about-us-intro .about-us-intro-wrapper,
    .about-us-description-wrapper {
      width: 80%;
    }

    .about-us-highlight {
      .about-us-highlight-wrapper {
        flex-direction: column;
      }
    }

    .about-us-video-wrapper {
      width: 90%;
    }
  }

  @media screen and (max-width: 800px) {
    .about-us-intro-wrapper {
      flex-direction: column;
    }

    .about-us-highlight {
      flex-wrap: wrap;
    }

    .about-us-video-wrapper {
      position: relative;
      padding-top: 56.25%;
    }
    .about-us-video-wrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
