.blog-custom-container {
  padding-bottom: 60px;
  .blog-header-text {
    
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 60px;
  }
  .blog-read-more {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
    text-align: center;
    width: 261px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    margin: 40px auto 0;
    .blog-read-more-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
    .blog-read-more-text {
      position: relative;
      margin-right: 10px;
      text-transform: uppercase;
    }
  }
  .blog-read-more:hover {
    opacity: 0.8;
  }
  .blog-title {
    
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #db4d29;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 8px;
  }
  .blog-custom-content {
    display: flex;
    height: 100%;
    justify-content: center;
    .blog-content-item-right {
      display: flex;
      flex-direction: column;
      .blog-content-item-card {
        text-decoration: none;
        cursor: pointer;
        width: 624px;
        .blog-content-item-img {
          padding: 18px;
          display: flex;
          .blog-content-item-img-style {
            flex-direction: column;
            min-width: 264px;
            min-height: 220px;
            max-width: 264px;
            max-height: 220px;
          }
          .blog-content-item-child {
            flex-direction: column;
            justify-content: center;
            padding-left: 12px;
            .blog-content-item-channel {
              color: #db4d29;
              
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
            }
            .blog-content-item-title {
              margin-top: 8px;
              margin-bottom: 8px;
              color: var(--color-customize-text-color, #282828);
              
              font-size: 20px;
              font-style: normal;
              font-weight: 700;

              display: inline-block;
              display: -webkit-box !important;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 360px;
            }
            .blog-content-item-info {
              .blog-content-item-info-item {
                display: inline-flex;
                align-items: center;
                margin-bottom: 18px;
                margin-top: 8px;
                .blog-content-item-info-item-by {
                  margin-bottom: 12px;
                  color: var(--color-default-button-topping-option-selling-price-time-quantity-price, #959595);
                  
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  .blog-content-item-info-item-by-name {
                    color: #282828;
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                  }
                  .blog-content-item-info-item-by-text {
                    margin-right: 5px;
                    font-size: 14px;
                  }
                }
                .blog-content-item-info-item-date {
                  margin-left: 8px;
                  color: var(--color-default-button-topping-option-selling-price-time-quantity-price, #959595);
                  
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                }
                .blog-content-item-info-item-view {
                  margin-left: 8px;
                  margin-right: 24px;
                  color: #282828;
                  
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                }
              }
              .blog-content-item-info-item-by {
                margin-bottom: 12px;
                .blog-content-item-info-item-by-text {
                  margin-right: 5px;
                  font-size: 14px;
                  color: var(--color-default-button-topping-option-selling-price-time-quantity-price, #959595);
                }
                .blog-content-item-info-item-by-name {
                  color: #282828;
                  
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                }
              }
            }
            .blog-content-item-description {
              color: var(--color-customize-text-color, #282828);
              
              font-size: 14px;
              font-style: normal;
              font-weight: 400;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box !important;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              display: inline-block;
              width: 360px;
              max-height: 100px;
            }
          }
        }
      }
    }
    .blog-content-item-left {
      display: flex;
      flex-direction: column;
      .blog-content-item-card {
        text-decoration: none;
        cursor: pointer;
        .blog-content-item-img {
          flex-grow: 1;
          padding: 18px 18px 0 18px;
          .blog-content-item-img-style {
            min-height: 420px;
            min-width: 100%;
            max-width: 540px;
            max-height: 420px;
          }
          .blog-content-item-child {
            flex-direction: column;
            justify-content: center;
            padding-top: 12px;
            .blog-content-item-channel {
              color: #db4d29;
              
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 8px;
            }
            .blog-content-item-title {
              color: var(--color-customize-text-color, #282828);
              
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px; /* 140% */

              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              width: 540px;
              text-overflow: ellipsis;
            }
            .blog-content-item-info {
              margin-bottom: 12px;
              margin-top: 12px;
              .blog-content-item-info-item {
                display: inline-flex;
                align-items: center;
                .blog-content-item-info-item-by {
                  margin-left: 30px;
                  color: var(--color-default-button-topping-option-selling-price-time-quantity-price, #959595);
                  
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  .blog-content-item-info-item-by-name {
                    color: #282828;
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                  }
                  .blog-content-item-info-item-by-text {
                    margin-right: 5px;
                    color: var(--color-default-button-topping-option-selling-price-time-quantity-price, #959595);
                  }
                }
                .blog-content-item-info-item-date {
                  margin-left: 8px;
                  color: var(--color-default-button-topping-option-selling-price-time-quantity-price, #959595);
                  
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }
                .blog-content-item-info-item-view {
                  margin-left: 8px;
                  color: #282828;
                  
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  margin-right: 24px;
                }
              }
            }
            .blog-content-item-description {
              color: var(--color-customize-text-color, #282828);
              text-overflow: ellipsis;
              
              font-size: 14px;
              font-style: normal;
              font-weight: 400;

              display: inline-block;
              display: -webkit-box !important;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 540px;
              max-height: 120px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .blog-custom-container {
    .blog-custom-content {
      display: block;
      height: auto;
      .blog-content-item-left {
        .blog-content-item-card {
          text-decoration: none;
          cursor: pointer;
          .blog-content-item-img {
            .blog-content-item-img-style {
              max-width: 786px;
              max-height: 420px;
              min-height: 420px;
              min-width: 100%;
            }
            img {
              height: 420px;
            }
            .blog-content-item-child {
              margin-left: 4px;
              .blog-content-item-title {
                width: 780px;
                width: 100%;
              }
              .blog-content-item-description {
                width: 100%;
                white-space: normal;
              }
            }
          }
        }
      }
      .blog-content-item-right {
        .blog-content-item-card {
          text-decoration: none;
          width: 100%;
          cursor: pointer;
          .blog-content-item-img {
            width: 100%;
            .blog-content-item-child {
              .blog-content-item-title {
                width: 100%;
              }
              .blog-content-item-description {
                width: 100%;
              }
              .blog-content-item-info {
                .blog-content-item-info-item .blog-content-item-info-item-by {
                  margin-bottom: unset;
                }
                .blog-content-item-info-item-by {
                  margin-bottom: unset;
                }
              }
            }
          }
        }
      }
    }
    .blog-read-more {
      margin-top: 10px;
    }
    padding-bottom: 30px;
  }
}

@media (max-width: 576px) {
  .blog-custom-container {
    .blog-custom-container-padding-bottom {
      padding-bottom: 130px;
    }
    .button-arrow-blog {
      .button-left-arrow {
        display: inline-block;
        position: absolute;
        z-index: 2;
        margin-top: 25%;
        left: 0;
      }
      .button-right-arrow {
        display: inline-block;
        position: absolute;
        z-index: 2;
        margin-top: 25%;
        right: 0;
      }
    }
    .blog-content-item-card {
      text-decoration: none;
      cursor: pointer;
      img {
        display: block;
        margin: 0 auto !important;
        min-width: 88%;
        min-height: 264px;
        max-width: 88%;
        max-height: 264px;
        margin-left: 2%;
      }

      .blog-content-item-img-style{
        border-radius: 20px;
      }

      .blog-content-item-child {
        padding-left: 24px;
        padding-right: 24px;
        .blog-content-item-channel {
          margin-top: 12px;
          margin-bottom: 12px;
          color: #db4d29;
          
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }
        .blog-content-item-title {
          .blog-content-item-title-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            width: 338px !important;
            display: block;
          }
        }
        .blog-content-item-info {
          margin-top: 12px;
          font-size: 14px;
          .blog-content-item-info-item {
            margin-top: 12px;
            display: flex;
            align-items: center;
            .blog-content-item-info-item-view {
              margin-left: 12px;
              margin-right: 24px;
            }
            .blog-content-item-info-item-date {
              margin-left: 12px;
            }
            .blog-content-item-info-item-by {
              .blog-content-item-info-item-by-text {
                margin-right: 5px;
                color: var(--color-default-button-topping-option-selling-price-time-quantity-price, #959595);
              }
            }
          }
        }
        .blog-content-item-description {
          margin-top: 12px;

          color: var(--color-customize-text-color, #282828);
          text-overflow: ellipsis;
          
          font-size: 14px;
          font-style: normal;
          font-weight: 400;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box !important;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: inline-block;
        }
      }
    }
  }
  .blog-read-more {
    margin: 15px auto 0 !important;
  }
  .blog-home-page-theme2 {
    padding-bottom: 16px; 
  }
}
