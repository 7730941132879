.discount-code-instore {
  .customize-dialog-modal {
    * {
      font-family: inherit;
    }
    top: 40% !important;
    width: 532px !important;
    .ant-modal-body {
      padding: 24px 0px !important;
    }
  }
}
.check_out_shipping {
  position: relative;
  .toast-message-scan-qr-code {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    z-index: 10;
    left: 0;
    right: 0;
    margin: 0 auto;
    .toast-message-discount-code-scan-qr-code {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 16px;
      border-radius: 16px;
      border: 1px solid #12b178;
      background: #f2fbf8;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
.pos-page {
  padding-top: 86px !important;
}

.modal-confirm-table-being-used-theme2 {
  .ant-modal-content {
    min-width: 500px;
  }
}
