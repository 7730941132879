.table-customize-loyalty-point-theme2 {
  .ant-table-content {
    background: #fafafa;
  }
  .order-id-theme2 {
    color: #256cd8;
    font-size: 14px;
    
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  }

  .branch-name-theme2 {
    overflow: hidden;
    color: #282828;
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-size: 14px;
    
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  }

  .total-amount-theme2 {
    color: #282828;
    font-size: 14px;
    
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  }

  .point-theme2-positive {
    color: #46aa46;
    font-size: 14px;
    
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  }

  .point-theme2-minus {
    color: #e80f1c;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  }

  .point-theme2-minus {
    color: #e80f1c;
    font-size: 14px;
    
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  }

  .time-theme2 {
    

    color: #282828;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
  }
  .platform-theme2 {
    color: #282828;
    font-size: 14px;
    
    line-height: 24px;
  }

  .ant-table-pagination.ant-pagination {
    display: flex;
    justify-content: center;
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 46px;
    height: 52px;
    border-radius: 16px;
    background: #fbfbfb;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-pagination .ant-pagination-item-active {
    border-radius: 16px;
    background: #db4d29;
    width: 46px;
    height: 52px;
    border: none;
    outline: none;
    a {
      color: white;
    }
  }
}
