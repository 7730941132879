@-webkit-keyframes zoom {
    0% {
        transform: scale(.9)
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px transparent
    }

    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 transparent
    }
}

@keyframes zoom {
    0% {
        transform: scale(.9)
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px transparent
    }

    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 transparent
    }
}

.messenger-chat-container {
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 999;

    .button-contact {
        transition: 1.6s all;
        -moz-transition: 1.6s all;
        -webkit-transition: 1.6s all;

        .phone-vr {
            position: relative;
            visibility: visible;
            background-color: transparent;
            width: 80px;
            height: 80px;
            cursor: pointer;
            z-index: 11;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0);
            transition: visibility .5s;
            left: 0;
            bottom: 0;
            display: block;

            .phone-vr-circle-fill {
                width: 65px;
                height: 65px;
                top: 12px;
                left: 12px;
                position: absolute;
                border-radius: 50%;
                border: 2px solid transparent;
                transition: all .5s;
                -webkit-transform-origin: 50% 50%;
                -ms-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-animuiion: zoom 1.3s infinite;
                animation: zoom 1.3s infinite;

                box-shadow: 0 0 0 0 #2196F3;
                background-color: rgba(33, 150, 243, 0.7);
            }

            .phone-vr-img-circle {
                width: 40px;
                height: 40px;
                line-height: 40px;
                top: 25px;
                left: 25px;
                position: absolute;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;

                svg {
                    position: absolute;
                    margin-top: -4px;
                }
            }
        }
    }
}