.order-status-tabs {
  display: flex;
  justify-content: space-around;
  background: #efefef;
  max-width: 380px;
  margin: 0 auto;
  border-radius: 8px;
  margin-bottom: 16px;

  .order-ordering,
  .order-ordered {
    flex: 1;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    border-radius: 8px;
    padding: 12px 16px;
    width: calc(100% / 2);
    text-align: center;
    cursor: pointer;
    color: #b8b8b8;
  }

  .order-ordered-active,
  .order-ordering-active {
    border-radius: 8px;
    background: var(--global-button-background-color, #db4d29);
    color: var(--global-button-text-color, #fff);
  }
}

@media (max-width: 575px) {
  .order-status-tabs {
    margin-bottom: 16px;

    .order-ordering,
    .order-ordered {
      font-size: 15px;
    }
  }
}
