.bc-form {

  // style not in form control
  .ant-picker,
  .ant-input-affix-wrapper,
  input {
    background-color: var(--formControlBg);
    border: unset;
    box-shadow: unset;
  }

  // style in form control
  .ant-form-item-control-input {
    .ant-picker,
    .ant-input-affix-wrapper,
    input {
      background-color: var(--formControlBg);
      border: unset;
      box-shadow: unset;
    }
  }
  .ant-select,
  .ant-select-single {
    background-color: var(--formControlBg);
    border: unset;
  }

  button {
    opacity: 0.8;
  }
  button:hover {
    opacity: 1;
  }
  .ant-btn-default:not(:disabled):hover,
  button:hover {
    color: #ffffff;
    border-color: unset;
    background: var(--bcButtonBg);
  }
  button[type="submit"] {
    background: var(--bcButtonBg);
  }
  .ant-btn-default:disabled {
    background: var(--bcButtonDisabled);
  }
  button[type="submit"]:disabled {
    background: var(--bcButtonDisabled);
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--formControlBg) inset !important;
  }
}
