.close-branch-theme-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #282828;
    margin-left: 24px;
    .closed {
      font-weight: 700;
      color: #ff4646;
    }
    .time {
      font-weight: 700;
    }
  }
}

/*Mobile:  <576px*/
@media (max-width: 575px) {
  .close-branch-theme-2 {
    margin: 0 16px;
    p {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 24px;
      margin-left: 16px;
    }
  }
}
