.sub-collapse-customize {
  .fnb-collapse .ant-collapse-header div:last-child {
    margin-left: 0;
  }

  .ant-collapse-expand-icon {
    margin: 0 !important;
  }
  .ant-collapse-item {
    margin: 24px 0 !important;
  }
  .ant-collapse-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px 12px 16px !important;
    height: 60px !important;
    color: #50429b !important;
    border-radius: 12px !important;
    fill: #a5abde;
    background: #fff !important;
  }
  .ant-collapse-header-text {
    color: #50429b !important;
    font-weight: 700;
    font-size: 20px !important;
    line-height: 24px;
    .ant-col {
      padding: 0 !important;
      margin-left: 0 !important;
    }
    .sub-title {
      padding-left: 0 !important;
      margin-left: 18px !important;
    }
  }
  .ant-collapse-item.ant-collapse-item-active {
    .ant-collapse-header {
      background: #f7f5ff !important;
      fill: #fff;
    }
    .ant-collapse-header-text {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #50429b;
    }
  }
  .icon-collapse {
    margin: 18px 16px 18px;
  }
  .ant-collapse.ant-collapse-icon-position-start.fnb-collapse.collapse-customize {
    background: #fff;
  }
  .ant-collapse-item {
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-left: 28px !important;
      }
    }
  }
  .sub-content {
    margin-top: 16px;
  }
  .disable-branch-contact {
    .ant-select-selector {
      background: #f3f3f3 !important;
    }
  }
}
.ant-collapse-content-box {
  padding: 0 0 24px 40px !important;
}

.icon-right-svg-hover:hover path {
  fill: #50429b;
}
.ant-collapse-item.fnb-collapse.collapse-sub {
  .ant-collapse-header {
    .ant-collapse-header-text {
      .ant-row {
        .col-icon-right {
          margin: unset !important;
          text-align: center;
          padding: 0 12px !important;
          .display-none-icon {
            display: none;
          }
        }
      }
    }
  }
}

.color-group-customize {
  .ant-collapse-item.fnb-collapse.collapse-sub {
    .ant-collapse-header {
      .ant-collapse-header-text {
        .ant-row {
          .col-icon-right {
            padding: 0 !important;
          }
        }
      }
    }
  }
}
