.flash-sale-banner {
  margin-top: 10px;
  margin-bottom: 24px;
  padding: 15px 22px 12px 12px;
  min-height: 88px;
  background-color: #c41b0e;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  align-items: center;
  justify-content: space-between;
  .flash-sale-banner-logo {
    img {
      margin: auto;
      width: 100%;
      height: auto;
    }
  }
  .flash-sale-banner-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    text-align: right;
    width: auto;
    display: flex;
    align-items: center;
  }
  .flash-sale-banner-price {
    display: none;
  }
  .flip-countdown {
    justify-content: flex-end;
    margin-left: 10px;
    ._2EBbg:first-child {
      margin-right: 2px;
    }
    ._2hRXr {
      margin-left: 9px;
      margin-right: 9px;
    }
  }
  .flash-sale-banner-countdown-title {
    justify-content: flex-end;
  }
  .flash-sale-banner-countdown-calendar {
    margin-top: 8px;
    justify-content: flex-end;
  }
  .flash-sale-zero {
    display: none;
  }
}

/* Mobile - ant-xs */
@media (max-width: 575px) {
  .flash-sale-banner {
    padding: 12px;
    margin-top: 24px;

    .flash-sale-banner-title {
      font-size: 13px;
      line-height: 35px;
    }
    .flash-sale-banner-price {
      display: flex;
      align-items: baseline;
      .selling-price {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #ffffff;
      }
      .original-price {
        padding-left: 12px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #ffffff;
        text-decoration: line-through;
      }
    }
    .flip-countdown {
      --fcc-digit-block-width: 20px !important;
      --fcc-digit-block-height: 40px !important;
      --fcc-digit-font-size: 20px !important;
      --fcc-separator-size: 3px !important;
      font-family: "Montserrat";
      ._3cpN7 {
        ._2EBbg:first-child {
          margin-right: 1px;
        }
        ._2EBbg {
          div:first-child {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
          }
          div:nth-child(2) {
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
      }
      ._2hRXr {
        margin-left: 3px;
        margin-right: 3px;
      }
    }
    .flash-sale-banner-countdown-calendar {
      margin-top: 0px;
    }
  }
}
