.radio-horizontal {
  .group-multiple-price-content {
    display: grid;
    align-items: center;

    .group-multiple-price-item {
      height: 56px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: unset;
      border-bottom: 1px solid #e6e6e6;

      .price-name {
        height: 100%;
        display: grid;
        align-items: center;
        padding: 8px 0;
        margin-left: 16px;

        .name {
          font-size: 16px;
          font-weight: 500;
        }

        .price-name-value {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .checkbox-price {
        margin-right: 16px;
      }

      .price-name-value-radio {
        width: 24px;
        height: 24px;
      }

      .ant-radio-wrapper {
        .ant-radio {
          .ant-radio-inner {
            border: 1px solid #a5abde;
          }
        }

        .ant-radio-checked {
          .ant-radio-inner {
            border: 1px solid #ff8c21;
          }
        }
      }
    }
  }

  .group-multiple-price-content *:last-child {
    border: unset;
  }
}

.radio-vertical {
  .options {
    .radio-option {
      color: #969696;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      height: 100%;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #9f9f9f !important;
      .radio-option-price {
        margin-bottom: 0px !important;
        padding-top: 10px;
      }
    }
  }

  /* Mobile - ant-xs */
  @media (max-width: 575px) {
    .options {
      .radio-option {
        color: #969696;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        height: 100%;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #9f9f9f !important;
        .radio-option-price {
          margin-bottom: 0px !important;
          padding-top: 10px;
        }
      }
    }
  }
}
