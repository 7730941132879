.banner-detail-content {
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-delay: 1s;
  transition-duration: 3s;
  opacity: 1;

  .banner-detail-header {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    font-size: 24px;
    font-weight: 700;
    color: #50429b;
    margin-left: 10px;

    .banner-detail-title {
      margin-left: 16px;
    }

    .banner-detail-icon-title:hover {
      cursor: pointer;
    }
  }

  .banner-detail-body {
    .banner-detail-body-general,
    .banner-detail-body-content {
      .ant-collapse-item.fnb-collapse.collapse-sub {
        .ant-collapse-header {
          fill: #50429b;
        }
      }

      .ant-collapse-item.ant-collapse-item-active.fnb-collapse.collapse-sub {
        .ant-collapse-header {
          fill: #fff;
        }
      }
    }

    .banner-detail-body-general {
      .ant-collapse-item.fnb-collapse.collapse-sub {
        margin-top: unset !important;

        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0 10px 0 20px !important;
            font-size: 20px;

            .backgroundBox {
              .colorPickerBox {
                .fnb-color-picker {
                  width: 48px;
                  height: 48px;
                  border-radius: 16px !important;
                  background: none !important;
                  box-shadow: 0px 1px 5px rgb(0 0 0 / 50%) !important;

                  div {
                    width: 100% !important;
                    height: 100% !important;
                    border-radius: 16px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.label-banner-field {
  font-size: 20px;
}

.banner-field-margin-top {
  margin-top: 22px;
}

.btn-add-new-banner {
  margin-top: 40px;
}

.size-general {
  font-size: 20px;
}

.banner-group-trash-icon {
  display: none;
}

.hyperlink-group {
  margin-top: 22px;
}
