.out-of-stock-label-box {
  width: fit-content;
  height: 72px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 12px;
  background: rgba(40, 40, 40, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  .text-label {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
}

.out-of-stock-label-box.small-size,
.small-size-pos {
  width: 149px;
  height: 56px;
  .text-label {
    font-size: 16px;
  }
}

.out-of-stock-label-box.is-cart-item {
  width: fit-content;
  height: 20px;
  border-radius: 8px;
  padding: 12px;
  .text-label {
    font-size: 8px;
  }
}

.out-of-stock-opacity {
  opacity: 0.5;
}

.btn-out-of-stock-disabled {
  cursor: not-allowed !important;
  svg {
    rect {
      fill: #eda694 !important;
    }
  }
}

@media (max-width: 1199px) {
  .out-of-stock-label-box {
    height: 56px;
    padding: 16px 20px;
    .text-label {
      /* Out of stock */
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}
@media (max-width: 575px) {
  .out-of-stock-label-box.small-size {
    width: fit-content;
    height: fit-content;
    padding: 8px 12px;
    .text-label {
      font-size: 12px;
    }
  }
  .out-of-stock-label-box {
    height: 56px;
    padding: 16px 20px;
    .text-label {
      /* Out of stock */
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}
