.contact-wrapper {
  min-height: 50vh;
  background-color: white;

  .container-fluid {
    width: 100%;
  }
  .container {
    width: 80%;
    margin: 0 auto;
  }

  .contact-section {
    padding-bottom: 40px;
    .contact-information-wrapper-customize {
      padding: 60px 76px !important;
    }
    .contact-information-wrapper {
      padding: 60px 18.75%; //60px 360px
      display: flex;
      flex-direction: column; /* Xếp theo hàng dọc */
      height: 100%;
      .bottom-content {
        margin-top: 30px;
        .branch-info-wrapper {
          margin-top: 16px;
          max-height: 410px;
          min-height: 200px;
          overflow-y: scroll;
          overflow-x: hidden;
          .branch-row {
            padding-right: 36px;
            padding-bottom: 16px;
          }

          .border-branch-info {
            border: 1px solid #db4d29;
            padding: 16px;
            border-radius: 18px;
          }
          .branch-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.38px;
            text-align: left;
            display: flex;
            width: 100%;
            .branch-title > div:first-child {
              margin-right: auto;
            }
            .name {
              margin-left: 8px;
              margin-right: 5px;
            }
          }
          .branch-description {
            margin-top: 16px;
            .branch-text {
              font-size: 14px;
              font-weight: 500;
              line-height: 17.07px;
              text-align: left;
              color: #282828;
            }
            .mt-12 {
              margin-top: 12px;
            }
            .title-open {
              font-weight: bold;
              font-weight: 700;
              font-size: 14px;
            }
            .is-open {
              margin-left: 15px;
              padding: 8px 12px;
              gap: 12px;
              background: #db4d29;
              border-radius: 4px;
              color: #ffffff;
            }
            .open-time {
              font-weight: bold;
              margin-top: 15px;
              .working-hours {
                margin-left: 15px;
              }
            }
          }
        }
      }
      .middle-content {
        height: 15px;
      }
      .top-content {
        width: 100%;
        .top-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0.04em;
          text-align: left;
        }
        .secondary-title {
          margin-top: 24px;
          font-size: 32px;
          font-weight: 700;
          line-height: 44px;
          text-align: left;
          color: #db4d29;
        }
        .description {
          margin-top: 20px;
          font-size: 18px;
          font-weight: 400;
          line-height: 21.6px;
          text-align: left;
          margin-bottom: 20px;
        }
        .google-map-box {
          width: 100%;
          height: 355px;
        }
      }
    }
  }

  .contact-header {
    width: 100%;
    height: 300px;
    line-height: 300px;
    text-align: center;
    background-size: cover;
  }
  .contact-header h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #282828;
  }
  .contact-footer {
    width: 100%;
    height: 690px;
    display: flex;
    justify-content: center;
    background-size: cover;
    .contact-form-wrapper {
      width: 50%;
      padding-top: 40px;
      padding-bottom: 40px;
      .info-box {
        text-align: center;
        .top-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0.04em;
        }
        .secondary-title {
          margin-top: 16px;
          font-size: 32px;
          font-weight: 700;
          line-height: 44px;
          text-align: center;
          color: #db4d29;
        }
        .description {
          margin-top: 40px;
          font-size: 18px;
          font-weight: 400;
          line-height: 21.6px;
          text-align: center;
        }
      }
      .form-box {
        height: 60%;
        margin-top: 24px;
        .form-item {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          .ant-form-item-control {
            font-family: inherit;
            input {
              font-family: inherit;
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
            }
            .ant-input:placeholder-shown {
              color: #282828;
            }
            .ant-form-item-control-input {
              .ant-input-affix-wrapper {
                height: 60px;
                border-radius: 12px;
                .ant-input-prefix {
                  padding-left: 8px;
                  padding-right: 12px;
                }
              }
            }
          }
          input,
          select {
            border-radius: 12px;
          }
          label {
            width: 100%;
            .title-branch {
              display: flex;
              justify-content: space-between;
              width: 100%;
              div {
                font-size: 16px;
                color: #262626 !important;
                line-height: 19px;
                .bold-underline {
                  color: #262626;
                  line-height: 19px;
                  font-weight: 700;
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .form-item-note {
          .ant-form-item-row {
            .ant-form-item-control {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  textarea {
                    min-height: 132px !important;
                    color: #282828;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
        .input-area-custom {
          border-radius: 12px;
        }
      }
      .button-box {
        display: flex;
        justify-content: center;
        .send-message-btn {
          width: 265px;
          height: 60px;
          padding: 12px 16px;
          gap: 16px;
          border-radius: 12px;
          background: #db4d29;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .btn-text {
            font-size: 16px;
            font-weight: 700;
            line-height: 19.5px;
            text-align: center;
            color: #fef7ed;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .contact-wrapper {
    .contact-section {
      padding-bottom: 0;
      .contact-information-wrapper {
        padding: 40px 24px;
        display: block;
        height: 100%;
        .bottom-content,
        .top-content {
          width: 100%;
        }
        .middle-content {
          width: 10px;
        }
      }
      .top-content {
        margin-top: 40px;
      }
    }
    .contact-footer {
      margin-top: 0;
      height: 100%;
      .contact-form-wrapper {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}
