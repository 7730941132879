.toast-message-calculation-discount-code {
  width: 343px;
  height: 58px;
  padding: 16px 18px;
  background-color: #333e5a;
  border-radius: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 40%;
  z-index: 999;
  -webkit-animation: fadeIn 0.5s, fadeOut 0.5s 4s;
  animation: fadeIn 0.5s, fadeOut 0.5s 4s;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    margin-left: 10px;
  }
}

/* Mobile: 575px */
@media (max-width: 575px) {
  .toast-message-calculation-discount-code {
    width: calc(100% - 185px);
    min-width: calc(100% - 175px);
    left: 25%;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      color: #ffffff;
      margin-left: 10px;
    }
  }
}
