@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");
@import "./stylesheets/bc-form.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > iframe#webpack-dev-server-client-overlay {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --max-width-container: 1360px;
  --width-container: 1360px;

  --container-theme2: 100%;
  --heightHeader: 74px;
  --heightHeaderMobile: 55px;

  --errorColor: #ff4d4f;
  --errorBg: rgba(255, 0, 0, 0.05);
  --borderColor: #6d6d6d;
  --primaryColor: #50429b;

  --formControlBg: #F8F8F8;
  --bcButtonBg: #00C6BE;
  --bcButtonDisabled: #7fe2de;
  --lightBg: #E5FCFB;

}

.main-session {
  width: 100%;
  max-width: var(--container-theme2);
  margin: 0 auto;
}
.text-line-clamp-1 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-line-clamp-2 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pointer {
  cursor: pointer;
}

.product-list-layout {
  background: #fff;
}

// Common styles
.content-start {
  display: flex;
  justify-content: start;
}
.content-center {
  display: flex;
  justify-content: center;
}
.content-align-center {
  text-align: center;
}
.content-end {
  display: flex;
  justify-content: end;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.uppercase {
  text-transform: uppercase;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none !important;
}

.ant-notification-notice.ant-notification-notice-closable {
  .ant-notification-notice-message{
    padding-inline-end: 0px;
  }
}

.toast-message {
  width: max-content;
  max-width: 85vw;
  border-radius: 16px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  padding: 12px;

  .ant-notification-notice-close {
    display: none;
  }
  .ant-notification-notice-message {
    margin-bottom: 0 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &.success {
    border: 1px solid #0cbd57;
    background-color: #eefdf4;
  }
  &.error {
    border: 1px solid #d30000;
    background-color: #fff6f6;
  }
  &.theme-dark {
    border: none;
    border-radius: 16px;
    background: #333e5a;
    margin-top: 120px;
    .ant-notification-notice-content {
      .ant-notification-notice-with-icon {
        .ant-notification-notice-message {
          color: #ffffff;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }
  &.theme-light-success {
    border: 1px solid #0cbd57;
    background-color: #eefdf4;
    .ant-notification-notice-content {
      .ant-notification-notice-with-icon {
        .ant-notification-notice-message {
          color: #282828;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  &.theme-light-error {
    border: 1px solid #d30000;
    background-color: #fff6f6;
    .ant-notification-notice-content {
      .ant-notification-notice-with-icon {
        .ant-notification-notice-message {
          color: #d30000;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}

// DEBUG STYLES
.bg-red {
  background-color: red;
}
.bg-blue {
  background-color: blue;
}
.bg-yellow {
  background-color: yellow;
}

.package-expired-modal {
  .ant-modal-content {
    .ant-modal-body {
      .package-expired-modal-content {
        .text-detail {
          font-size: 16px !important;
        }
        .bottom-content {
          .left-content,
          .right-content {
            .text-box {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }
}

.ant-picker-input input {
  font-family: inherit;
}

.ant-picker-panel-container {
  * {
    font-family: inherit;
  }
}

/* Mobile */
@media (max-width: 575px) {
  .toast-message {
    &.theme-dark {
      margin-top: 0;
    }
  }
}