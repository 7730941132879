.wrapper-rated {
    .ant-rate-star svg {
        width: 20px;
        height: 20px;
    }
        
    .ant-rate-star.ant-rate-star-full {
        svg > path {
            fill: #FF8C21;
        }
    }

    .ant-rate-star.ant-rate-star-zero {
        svg > path {
            fill: #FBFBFB;
        }
    }
}