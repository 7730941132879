.package-expired-modal {
  .ant-modal-content {
    border-radius: 30px;
    padding: 24px;
    height: 100%;
    .ant-modal-body {
      .package-expired-modal-content {
        .title {
          color: #282828;
          text-align: center;
          font-size: 28px;
          font-weight: 700;
          line-height: 28px;
        }
        .image-container {
          display: flex;
          justify-content: center;
          margin-top: 24px;
        }
        .text-detail {
          margin-top: 24px;
          color: #282828;
          text-align: center;
          font-weight: 400;
          line-height: 24px;
          .store-name {
            font-weight: 700;
          }
        }
        .bottom-content {
          border-radius: 16px;
          background: #f5f5f5;
          width: 100%;
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 12px;
          padding-bottom: 12px;
          .left-content,
          .right-content {
            .icon-box {
              display: flex;
              justify-content: center;
            }
            .text-box {
              color: #282828;
              font-weight: 700;
              line-height: 24px;
              margin-top: 4px;
              a {
                color: #282828;
              }
            }
          }
          .right-content {
            margin-left: 32px;
          }
        }
      }
    }
  }
  .line-spacing {
    margin-top: 4px;
  }
}

@media screen and (max-width: 575px) {
  .package-expired-modal {
    .ant-modal-content {
      .ant-modal-body {
        .package-expired-modal-content {
          .text-detail {
            p {
              font-size: 14px !important;
            }
          }
          .bottom-content {
            align-items: normal;
            .left-content,
            .right-content {
              .text-box {
                a {
                  font-size: 14px !important;
                }
              }
            }
            .left-content {
              max-width: 58%;
              text-align: center;
            }
            .right-content {
              max-width: calc(40% - 32px);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .package-expired-modal {
    .ant-modal-content {
      .ant-modal-body {
        .package-expired-modal-content {
          .bottom-content {
            .right-content {
              margin-left: 24px;
            }
          }
        }
      }
    }
  }
}
