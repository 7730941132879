.button-create-order-and-payment {
    .create-order-button {
        background: var(--global-button-background-color, #DB4D29) !important;

        span {
            color: var(--global-button-text-color, #FFF);
        }
    }

    .create-order-button-ordered {
        display: flex;
        justify-content: center;
        align-items: center;

        .call-to-pay-btn {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            border-radius: 8px;
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}

@media (max-width: 575px) {
    .button-create-order-and-payment {
        .create-order-button-ordered {
            .call-to-pay-btn {
                white-space: pre-line;
                height: 36px;
            }
        }
    }
}