$momo_background: url("../../../../assets/images/checkout-momo-background-theme2.png");
.check-out-momo-web-theme2 {
  width: calc(100% - 32px) !important;
  max-width: 1415px !important;
  .ant-modal-content {
    background-image: $momo_background;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 1415px !important;
    height: auto !important;
    border-radius: 40px;
    padding: 36px;
    .ant-modal-close {
      color: white;
      width: 60px;
      height: 60px;
      .ant-modal-close-x {
        font-size: 24px;
      }
    }
    .checkout-display-content {
      background: #ffffff;
      border-radius: 32px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      width: 450px;
      height: 100%;
      align-items: center;
      padding: 32px;
      .logo {
        width: 120px;
        height: 120px;
        object-fit: contain !important;
      }
      .qrcode {
        position: relative;
        height: 300px;
        width: 300px;
        canvas {
          z-index: 2;
          position: absolute;
          top: 10px;
          left: 10px;
        }
        .qr-code-frame {
          width: 300px;
          height: 300px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
      .title1 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        color: #282828;
        margin-top: 24px;
      }
      .title2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        align-items: center;
        text-align: center;
        color: #282828;
        margin-top: 24px;
      }
      .amount {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        align-items: center;
        text-align: center;
        color: #b60e6c;
        margin-top: 24px;
      }
      .code {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        align-items: center;
        text-align: center;
        color: #000000;
        margin-top: 24px;
      }
    }
    .success-box-detail,
    .fail-box-detail {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .checkout-icon {
        width: 120px;
        height: 120px;
        margin-top: 80px;
        object-fit: contain !important;
      }
      .checkout-title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #db4d29;
        margin-top: 70px;
        height: 80px;
      }

      .checkout-message {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        margin-top: 20px;
      }
    }

    .success-box-footer-button {
      display: flex;
      flex-direction: column;
      margin: 60px 50px 80px;
      width: calc(100% - 100px);
      align-items: center;

      .btn-view-order {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;
        width: 100%;
        max-width: 320px;
        height: auto;
        background: #db4d29;
        border-radius: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        padding: 12px 20px;

        a {
          color: #ffffff;
        }
      }

      .btn-navigate-to {
        margin: 10px 0 0;
        padding: 12px 20px;
        height: auto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #282828;
        cursor: pointer;
      }
    }

    .btn-navigate-to,
    .btn-close {
      margin-top: 80px;
      margin-bottom: 90px;
      height: 60px;

      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #282828;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 575px) {
  .check-out-momo-web-theme2 {
    top: 50px;
    height: calc(100vh - 160px);
    top: 100px;

    .ant-modal-content {
      padding: 0;
      height: 100% !important;

      .ant-modal-body {
        height: 100%;

        .checkout-display-content {
          width: 100%;
          height: 100%;
          padding: 0px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .success-box-detail {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .checkout-icon {
              width: 120px;
              height: 120px;
              object-fit: contain !important;
              margin: 0;
            }

            .checkout-title {
              margin-top: 30px;
            }
          }

          .success-box-footer-button {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            width: 100%;
            align-items: center;

            .btn-view-order {
              margin: 0;
              padding: 12px 20px;
              font-size: 20px;
              line-height: 25px;
              font-weight: 400;
              height: auto;
              text-align: center;
            }

            .btn-navigate-to {
              margin: 0;
              padding: 12px 20px;
              font-size: 20px;
              line-height: 25px;
              font-weight: 400;
              height: auto;
            }
          }
        }
      }
    }
    .ant-modal-close {
      display: none;
    }
  }
}
