$FONT_SIZE_18: 18px;
$FONT_WEIGHT_700: 700;
$WHITE_COLOR: #FFF;
$FONT_STYLE_NORMAL: normal;
$LINE_HIGHT_NORMAL: normal;

.name-and-value-popover {
  padding-top: 12px;
  .ant-popover-content {
    max-width: 80vw !important;
    max-height: 100%;
    margin: auto;
    width: 379px;
    .ant-popover-inner {
      padding: 16px 24px !important;
      .ant-popover-inner-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .discount-item {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          margin-top: 6px;
          .name {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #000000;
            user-select: none;
            height: fit-content;
            max-width: 240px;
            overflow: hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .value {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #000000;
            user-select: none;
            margin-left: 20px;
          }
        }
        .discount-title {
          margin-top: 0px;
          padding-bottom: 6px;
          border-bottom: 0.5px dashed #d1d1d1;
        }
        .weight-700 {
          font-weight: 700 !important;
        }
      }
    }

    .ant-popover-arrow {
      left: auto;
    }
  }
}

.btn-promotions-product {
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 10.401px;
  background: #FF9636;
  padding: 8px;
  margin-top: 26px;
  cursor: pointer;
  padding-right: 12px;
  .promotion-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .promotion {
    color: $WHITE_COLOR;
    font-size: $FONT_SIZE_18;
    font-style: $FONT_STYLE_NORMAL;
    font-weight: $FONT_WEIGHT_700;
    margin-left: 4px;
    margin-right: 12px;
    text-transform: uppercase;
  }
  .promotion-down-icon {
    position: relative;
    svg {
      width: 9px;
      height: 9px;
    }
    svg path {
      fill: $WHITE_COLOR;
    }
  }
}

@media (max-width: 575px) {
  .name-and-value-popover {
    .ant-popover-content {
      margin-left: unset !important;
      margin-top: unset !important;
    }
  }
  .popover-promotion-product-detail-theme2.name-and-value-popover {
    .ant-popover-content {
      max-width: calc(100vw - 32px) !important;
    }
  }
  .btn-promotions-product {
    margin: 12px 0px;
  }
}
