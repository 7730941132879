.checkout-discounts-theme2 {
  max-width: 100%;
  .customerDiscount {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    .title {
      padding: 10px;

      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
      user-select: none;
      padding-bottom: 10px;
      border-bottom: 1px solid #eeeeee;
      margin-top: 10px;
    }
    .detail {
      padding: 10px 10px 10px 0px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .discountName {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #282828;
        user-select: none;
        width: fit-content;
        height: fit-content;
      }
      .discountAmount {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #282828;
        user-select: none;
      }
    }
  }
  .promotionTitle {
    margin: 0px 12px 0 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #282828;
    user-select: none;
    padding-bottom: 8px;
    padding-top: 12px;
    border-bottom: 1px dashed #e6e6e6;
  }
  .discountList {
    margin-top: 0px;
    .discountItem {
      padding: 4px 12px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .name {
        .discountItem-ellipse {
          margin-right: 7px;
        }
        .discountItem-loyaltyPoint {
          color: #ff8c21;
        }
      }
      .discountName {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #282828;
        user-select: none;
        width: fit-content;
        max-width: 65%;
        height: fit-content;
        .discountItem-ellipse {
          margin-right: 7px;
        }
      }
      .discountAmount {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #282828;
        user-select: none;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .checkout-discounts-theme2 {
      max-width: 343px;
  }
}
