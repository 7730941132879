.product-item-card {
  transition: 0.5s;
  margin-bottom: 16px;

  .ant-card-body {
    padding: unset;
    border-radius: unset;

    .product-main-content-theme2 {
      margin-bottom: 16px;
    }
  }

  .fnb-display-image {
    overflow: hidden;

    img {
      transition: 0.5s;
    }
  }
}

.product-main-theme2,
.checkout-related-product-item-theme2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(25% - 27px);
  max-width: 313px;
  min-width: 150px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 16px;

  &:last-child {
    margin-right: 0;
  }

  .product-image {
    height: 240px;
    width: 240px;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    margin: auto;
    border-radius: 16px;
    position: relative;

    .discount-box {
      padding: 4px 8px;
      position: relative;
      width: fit-content;
      height: 24px;
      left: 0px;
      top: 0px;
      background: #ffa318;
      border-radius: 30px;

      .discount-text {
        width: fit-content;
        height: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
  }

  .product-flash-sale {
    position: absolute;
    top: -12px;
    margin-left: -12px;
    border: 6px solid #c41b0e;
    width: calc(100% + 24px);
    height: calc(100% + 12px);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: 42px solid #c41b0e;

    .flash-sale-tag {
      position: absolute;
      bottom: -36px;
      left: 2px;
    }
  }

  .product-img {
    width: 100%;
    margin-bottom: 5px;

    &:hover {
      cursor: pointer;
    }

    .fnb-display-image,
    .display-image {
      border-radius: 16px 16px 0px 0px;
    }
  }

  .out-of-stock {
    opacity: 0.5;
  }

  .product-rating {
    display: flex;
    height: 24px;
    align-items: center;
  }

  .product-rating img {
    width: 24px;
    height: 24px;
  }

  .product-name {
    width: 100%;
    height: 22px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-transform: capitalize;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-bottom: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  .product-description {
    width: 100%;
    height: 46px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    align-items: center;
    justify-content: center;
    color: #959595;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .m-content {
    margin: 4px 0px 4px 0px;
    padding: 0px 12px 0px 12px;
  }

  .price-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 48px;
    margin: 4px 12px 16px 12px;

    .price-box-left {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .product-price {
        width: fit-content !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: line-through;
        color: #959595;
        user-select: none;
        margin-right: 10px;
      }

      .product-price-with-discount {
        width: fit-content !important;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #db4d29;
        line-height: 24px;
        border: solid 1px transparent;
        background: none !important;
      }
    }

    .cart-item {
      transition: 0.5s;
      opacity: 0.8;
    }

    .cart {
      transition: 0.5s;
      opacity: 0.8;
      border-radius: 30px;
      cursor: pointer;
      background: #db4d29;
      margin: auto;
    }

    .cart:hover,
    .cart-item:hover {
      opacity: 1;
    }
  }

  .hyper-link {
    text-decoration: none;
  }
}

.ant-card-bordered {
  border: none;
}

.product-item-card-out-of-stock {
  .ant-card-body {
    .product-main-content-theme2 {
      .product-img {
        .fnb-display-image {
          .display-image {
            opacity: 0.5;
          }

          .out-of-stock-label-box {
            height: 48px;
            width: fit-content;
            padding: 12px 24px;

            .text-label {
              font-size: 16px;
              font-weight: 700;
            }
          }
        }
      }

      .product-rating,
      .m-content,
      .price-box {
        opacity: 0.5;
      }
    }
  }
}

@media screen and (min-width: 1001px) {
  .product-item-card:hover {
    box-shadow: 0px 0px 20px 0px #00000014;

    .fnb-display-image>img {
      transform: scale(1.1);
      transform-origin: 50% 50%;
    }
  }

  .cart,
  .cart-item {
    width: 48px;
    height: 48px;
    padding: 12px;
    display: block !important;
  }
}

@media screen and (max-width: 1300px) {

  .cart,
  .cart-item {
    height: 48px;
    width: 48px;
    padding: 12px;
    display: block !important;
  }
}

@media screen and (max-width: 1000px) {

  .product-main-theme2,
  .checkout-related-product-item-theme2 {
    width: calc(25% - 15px);

    .product-rating img {
      width: 20px;
      height: 20px;
    }

    .m-content {
      margin: 2.4px 0px 2.4px 0px;
      padding: 0px 4.8px 0px 4.8px;
    }

    .price-box {
      margin: 2px 4px 10px 4px;
    }
  }

  .cart-item,
  .cart {
    height: 40px;
    width: 40px;
    padding: 8px;
    display: block !important;
  }
}

@media screen and (max-width: 639px) {

  .product-main-theme2,
  .checkout-related-product-item-theme2 {
    width: calc(50% - 10px);
    border-radius: 12px;

    .fnb-display-image,
    .display-image {
      border-radius: 12px 12px 0px 0px !important;
    }

    .product-image {
      width: 140px !important;
      height: 140px !important;
      margin-left: 0 !important;
      align-items: center;
      margin-top: 0 !important;
      border-radius: 12px;
    }

    .product-name {
      width: 100%;
      height: 22px;
      font-size: 14px;

      .item {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        overflow-x: hidden;
      }
    }

    .product-description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      align-items: center;
      color: #959595;
      font-family: 'Montserrat';
      line-height: 20px;
    }

    .price-box {


      .price-box-left {
        .product-price {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          align-items: center;
          text-decoration-line: line-through;
          color: #959595;
          margin-top: 0 !important;
          margin-right: 12px;
        }

        .product-price-with-discount {
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          align-items: center;
          margin-top: 0 !important;
        }
      }

      .cart,
      .cart-item {
        display: unset;
        padding: 8px;
      }
    }

    .hyper-link {
      text-decoration: none;
    }

    .product-item-card:hover {

      .fnb-display-image,
      .display-image {
        border-radius: 12px 12px 0px 0px;
      }
    }
  }

  .checkout-related-product-item-theme2 {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .product-main-theme2 {
    .ant-card-body {
      width: 100%;

      .product-main-content-theme2 {
        margin-bottom: 0px;
        padding-bottom: 12px;
      }
    }

    display: flex;
    justify-content: center;
    align-items: center;

    .product-img {
      width: 200px;
      margin-bottom: 2px;
      height: auto !important;
    }

    .product-description {
      max-width: 20ch;
      text-overflow: ellipsis;
      font-size: 13px;
      max-height: 45px;
    }
  }

  .product-item-card-out-of-stock {
    .ant-card-body {
      .product-main-content-theme2 {
        .product-img {
          .fnb-display-image {
            .out-of-stock-label-box {
              height: 36px;
              padding: 8px 16px;

              .text-label {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .product-main-theme2 {
    .product-img {
      width: 100%;
    }

    .price-box {
      .price-box-left {
        .product-price {
          font-size: 12px;
        }

        .product-price-with-discount {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .product-main-theme2 {
    .product-img {
      padding-top: unset;
      margin: unset;
    }
  }
}