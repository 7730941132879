.toast-message-container {
  position: fixed;
  z-index: 99999;
  bottom: 50px;
  left: 0;
  width: 100%;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 50px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 50px;
      opacity: 1;
    }
  }
  .toast-message-success {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    border-radius: 16px;
    width: fit-content;
    background: linear-gradient(
      88.17deg,
      rgba(255, 255, 255, 0.8) 1.13%,
      rgba(255, 255, 255, 0.08) 21.19%,
      rgba(255, 255, 255, 0.8) 98.26%
    );
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20.5px);
    transition: all linear 0.3s;
  }
  .toast-message-warning {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    border-radius: 16px;
    width: fit-content;
    background: linear-gradient(
      88.17deg,
      rgba(255, 240, 241, 0.8) 1.13%,
      rgba(255, 240, 241, 0.408) 21.19%,
      rgba(255, 240, 241, 0.8) 98.26%
    );
    border: 1px solid #e80f1c;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20.5px);
    transition: all linear 0.3s;
  }
  .icon {
    margin-right: 16px;
  }
}
