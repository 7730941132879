.my-reservation-theme2 {
  border: none;
  width: 100%;
  height: 100%;
  background: rgba(244, 244, 244, 100);

  .my-reservation-title {
    display: flex;
    justify-content: center;
    height: 52px;
    margin-top: 12px;

    .reservation-status-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #282828;
    }

    .total-reservation {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #282828;
    }

    .reverse-table-now {
      text-align: end;
      margin-top: 12px;

      .reservation-btn {
        width: fit-content;
        background-color: #f7931e;
        border-radius: 8px;
        margin-top: 43px;
        padding: 12px;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #ffffff;
        text-decoration-line: none;
      }
    }
  }

  .my-reservation-nav {
    padding: 0px 16px;
    background: #ffffff;
    border-radius: 20px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .my-reservation-status-list::-webkit-scrollbar {
    display: none;
  }

  .my-reservation-status-list {
    width: 100%;
    height: 72px;
    padding: 12px 0px;
    background: #ffffff;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;
    /*Hide scrollbar for Firefox */

    .my-reservation-status-list-rd {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 16px;

      .my-reservation-status-rd {
        border: none;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        padding: 12px 16px;
        border-radius: 30px;
        white-space: nowrap;
        height: 100%;

        &::before {
          width: 0px;
        }
      }

      .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.my-reservation-status-rd {
        border-radius: unset;
        border-radius: 30px;
        border: none;
      }
    }

    .my-reservation-status-desktop-theme2 {
      justify-content: space-between;
    }
  }

  .my-reservation-list {
    padding-top: 12px;
    max-height: 1100px;
    overflow-y: scroll;

    .loading-reservation {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .no-reservation {
      display: flex;
      align-items: center;
      background-image: url(./background_my_order.png);
      height: 340px;
      border-radius: 20px;

      .reservation-description {
        display: flex;
        flex-direction: column;
        align-items: center;

        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
        margin-left: 12%;

        .reservation-btn {
          width: fit-content;
          background-color: #f7931e;
          border-radius: 8px;
          margin-top: 43px;
          padding: 12px;

          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          color: #ffffff;
          text-decoration-line: none;
        }

        span {
          color: #282828;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          padding: 0px 48px;
          max-width: 437px;
        }
      }
    }
  }
}

.content-cancel-deposit {
  font-size: 15px;
  line-height: 18px;

  .hotline-text {
    font-weight: 700;
    text-decoration: underline;
    color: #db4d29;
    margin-top: 16px;
  }
}

/* Mobile - ant-xs */
@media (max-width: 575px) {
  .my-reservation-theme2 {
    .my-reservation-status-list {
      width: 100%;
      height: 76px;
      border-radius: 20px;
      padding: 12px 0px;
      background: #ffffff;
      overflow-x: scroll;

      .my-reservation-status-list-rd {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .my-reservation-status-rd {
          border: none;

          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #000000;
          padding: 12px 16px;
          border-radius: 30px;

          &::before {
            width: 0px;
          }
        }

        .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.my-reservation-status-rd {
          border-radius: unset;
          border-radius: 30px;
          border: none;
        }
      }
    }

    .my-reservation-list {
      padding-top: 12px;
      max-height: calc(600px);
      overflow-y: scroll;

      .loading-reservation {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 50px;
          height: 50px;
        }
      }

      .no-reservation {
        display: flex;
        align-items: center;
        background-image: url(./bg-reservation-mobile.png);
        height: 531px;
        border-radius: 20px;

        .reservation-description {
          display: flex;
          flex-direction: column;
          align-items: center;

          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          color: #000000;
          margin-left: 0%;
          padding-bottom: 80%;

          .reservation-btn {
            width: fit-content;
            background-color: #f7931e;
            border-radius: 8px;
            margin-top: 24px;
            padding: 12px;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) and (min-width: 576px) {
  .my-reservation-theme2 {
    .my-reservation-list {
      .no-reservation {
        background-image: url(./bg-no-reservation-tablet.png);
        height: 342px;
      }
    }
  }
}