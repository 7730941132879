.success-message {
    .ant-message-success {
        display: flex;
        padding: 0 2px;
        border-radius: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #0CBD57;

        svg {
            width: 20px;
            height: 20px;
        }

        strong {
            font-weight: 600;
        }
    }
}