.min-header {
  background-color: #db4d29;
  width: 100%;
  height: 96px;
  align-items: center;
  padding: 8px;
  z-index: 10;

  .addon-before {
    display: flex;
    justify-content: flex-start;

    .arrow-left-icon {
      margin: 0px 8px;
      width: 32px;
      height: 32px;
    }
  }

  .addon-between {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .addon-after {
    display: flex;
    justify-content: flex-end;

    .cart-quantity {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: rgb(255, 255, 255);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -17px;
      user-select: none;
    }

    .cart-icon {
      height: 24px;
      margin: 0px 35px;
    }

    .close-icon {
      height: 24px;
      margin: 0px 8px;
      cursor: pointer;
    }

    .cart-quantity {
      margin-right: 20px;
    }
  }
}

.confirm-leave-notify-dialog-instore {
  .ant-modal-content {
    .ant-modal-header {
      background: var(--global-button-background-color, #db4d29);
    }

    .ant-modal-body {
      padding: 18px;

      .notification-dialog-content {
        .confirm-leave {
          color: #282828;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.15px;
        }
      }
    }

    .ant-modal-footer {
      .ignore-btn {
        background: none;
        color: var(--global-button-background-color, #db4d29);
        border: none;
        outline: none;
        cursor: pointer;
        box-shadow: none;
      }

      .confirm-leave-btn {
        background: var(--global-button-background-color, #fff);
        color: var(--global-button-text-color, #db4d29);
        border: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .confirm-leave-notify-dialog-instore .notification-modal-theme2 .ant-modal-content {
    .ant-modal-body {
      padding: 16px;
    }
    .ant-modal-footer {
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 16px;
      button {
        font-size: 13px;
      }
    }
  }
}
