.fnb-display-image {
  width: 100%;
  padding-top: 100%;
  position: relative;
  .flash-sale-border {
    position: absolute;
    z-index: 1;
    top: -12px;
    left: -12px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: calc(100% + 24px);
    height: calc(100% + 12px);
    border: 6px solid #c41b0e;
    border-bottom: 42px solid #c41b0e;
  }
  .display-image {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  .flash-sale {
    border-top-right-radius: 6px;
    position: absolute;
    bottom: 3px;
    left: 0;
    object-fit: cover;
    .flash-sale-logo {
      width: 100%;
      height: 100%;
    }
    z-index: 2;
  }
}

.promotion-label {
  padding: 4px 8px;
  position: absolute;
  width: fit-content;
  height: 28px;
  left: 4px;
  top: 4px;
  background: #ffa318;
  border-radius: 16px;
  z-index: 1;
  transform: none;
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 0px;
  }
}

/* Mobile */
@media (max-width: 575px) {
  .fnb-display-image {
    .flash-sale-border {
      position: absolute;
      z-index: 1;
      top: -12px;
      left: -12px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      width: calc(100% + 24px);
      height: calc(100% + 12px);
      border: 6px solid #c41b0e;
      border-bottom: 42px solid #c41b0e;
    }
    .flash-sale {
      border-top-right-radius: 6px;
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 185px;
      object-fit: cover;
      .flash-sale-logo {
        width: 100%;
        height: 100%;
      }
      z-index: 2;
    }
  }
  .promotion-label {
    padding: 4px 8px;
    position: absolute;
    width: fit-content;
    height: 28px;
    left: 4px;
    top: 4px;
    background: #ffa318;
    border-radius: 12px;
    z-index: 1;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #ffffff;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
}
