.post-category-theme2 {
  margin-top: 56px;
  margin-bottom: 56px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px 24px;

  .title {
    color: #db4d29;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
  }

  .category-list {
    margin-top: 24px;
    width: 368px;

    .category {
      overflow: hidden;
      color: #282828;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      flex-direction: column;

      .category-item {
        margin-bottom: 16px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          opacity: 0.7;
        }
      }

      .category-item-default {
        color: #db4d29;
      }

      .active {
        color: #db4d29;
      }
    }
  }
}

@media (max-width: 1199px) {
  .post-category-theme2 {
    margin-top: 0px;
    margin-bottom: 12px;
    padding: 10px 10px;

    .category-list {
      width: fit-content;
      padding: 12px 0px;
      margin-top: 0px;

      .category {
        display: flex;
        flex-direction: row !important;
      }

      .swiper-category-list-theme2 {
        .swiper-wrapper {
          width: 100% !important;
          word-break: inherit;

          .swiper-slide {
            flex-shrink: inherit;
            max-width: 100%;

            span {
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .swiper-slide:first-child {}
        }
      }
    }
  }

}