.profile-right-side-order-detail {
  background: transparent !important;
}
.toast-message-show-error-theme1 {
  margin-top: 74px !important;
}
.confirm-modal-config {
  .confirmation-modal-theme2 .ant-modal-content {
    width: 378px !important;
    left: 0 !important;
  }

  .confirmation-modal-theme2 .ant-modal-content .ant-modal-body {
    padding: 24px 24px 24px 24px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }

  .confirmation-modal-theme2 .ant-modal-content .ant-modal-footer .ant-btn-default {
    min-width: -moz-fit-content;
    min-width: fit-content;
    background: #ffffff;
    width: 111px;
  }

  .confirmation-modal-theme2 .ant-modal-content .ant-modal-footer button {
    height: 48px;
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 211px;
  }
}
.order-detail-container {
  width: 100%;
  height: max-content;
  display: grid;
  row-gap: 24px;
  .back-component {
    display: flex;
    align-items: center;
    .back-icon {
      margin-right: 12px;
    }

    .back-icon:hover {
      cursor: pointer;
      path {
        font-weight: bold;
      }
    }
  }
  .order-detail-header {
    background: #ffffff;
    border-radius: 8px;
    .header-image {
      position: relative;
      display: grid;
      grid-template-columns: 1fr repeat(2, 130px);
      gap: 12px;
      background: #f4fbf8;
      .image {
        width: 100%;
        height: 332px;
      }

      .header-status {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        vertical-align: middle;
        text-align: left;
        position: relative;
        line-height: 2;
        margin-right: 16px;
        margin-bottom: 16px;
        margin-top: 45px;
        margin-left: 30px;

        .content-review {
          display: flex;
          flex-direction: column;
          gap: 15px;
  
          &-star {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            margin-top: 12px;
          }
          &-label {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
          }
          &-comment {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            max-width: 400px;
            word-wrap: break-word;
          }
          :where(.css-dev-only-do-not-override-snrpu6).ant-rate {
            height: 32px;
          }
        }

        .btn-review-order {
          background-color: transparent;
          height: 48px;
          border-radius: 12px;
          padding: 12px 16px;
          gap: 16px;
          margin-right: 16px;
          border: 1px solid #DB4D29;

          span {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #DB4D29;
          }
        }
        
        .order-delivery {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 8px;
          .order-detail-delivery-method {
            color: var(--TEXT, #282828);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: 0.3px;
            text-transform: uppercase;
            display: block;
          }
          .order-detail-code {
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: 36px; /* 128.571% */
            display: block;
          }
        }
        .order-detail-code {
          font-size: 20px;
          font-weight: 700;
          color: #282828;
        }

        .order-detail-status {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 175% */
          color: #ff8c21;
          width: fit-content;
          border-radius: 8px;
          display: flex;
          padding: 4px 8px;
          background: #e4eff6;
          margin-bottom: 4px;
        }

        .order-detail-reason-title {
          display: flex;
          font-size: 16px;
          font-weight: 700;
        }
        .order-detail-reason-content {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          overflow-wrap: anywhere;
        }

        .order-detail-created-platform {
          font-size: 16px;
          font-weight: bold;
          color: #282828;

          .order-detail-created-time {
            color: #959595;
          }
        }

        .receive-point-container {
          max-width: 383px;
          .receive-point-text {
            font-style: normal;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding-top: 10px;
            display: flex;
            width: 150%;
            margin-bottom: 8px;
            .point-logo {
              vertical-align: middle;
              margin-left: 12px;
            }
            .earn-points {
              color: #ffa318;
              font-weight: 500;
              line-height: 20px;
              font-size: 14px;
            }
          }
        }

        .group-btn {
          width: 100%;
          height: max-content;
          margin-top: 15px;
          display: flex;
          .ant-btn.btn-status {
            width: 135px;
            border: 1px solid #6a6a6a;
            height: 48px;
            text-align: center;
            border-radius: 12px;
            background: transparent;
            font-size: 16px;
            font-weight: 500;
            color: #6a6a6a;
          }

          .ant-btn.btn-status-re-order {
            border: 1px solid #db4d29;
            color: #ffffff;
            background: #db4d29;
          }

          .ant-btn.btn-retry-payment {
            border: 1px solid #db4d29;
            color: #ffffff;
            background: #db4d29;
            width: auto;
          }

          .ant-btn.btn-status-add-item {
            color: #ffffff;
            padding: unset;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .add-item-my-order {
            padding-left: 8px;
          }

          .ant-btn.btn-checkout {
            background: #fff;
            color: #db4d29;
            border: 1px solid #db4d29;
          }
        }
        .platform-group {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 8px;
          margin-bottom: 12px;
        }
      }
    }
    .in-store-color {
      background: #f7f5ff;
    }
    .delivery-color {
      background: #f4fbf8;
    }
    .take-away-color {
      background: #f7f5ff;
    }
    .online-delivery-color {
      background: #f4fbf8;
    }
    .pick-up-color {
      background: #fff6ed;
    }
    .header-icon-detail {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      margin-right: 32px;
      right: 0px;
      position: absolute;
    }
    .progress-bar {
      width: 100%;
      height: 271px;
      display: inline-flex;
      align-items: center;
      justify-content: space-evenly;

      .progress-bar-item {
        display: grid;
        gap: 12px;
        vertical-align: middle;
        text-align: center;
        width: max-content;

        .created-status-icon {
          width: 100%;
          text-align: center;
        }

        .status-describe {
          .created-time {
            display: grid;
            font-size: 16px;
            font-weight: 500;
            color: #888a98;
            padding: 12px;
          }

          .created-status-name {
            font-size: 20px;
            font-weight: 600;
            color: #000000;
          }

          .status-schedule {
            color: #888a98;
          }
        }
      }

      .straight {
        width: 110px;
        height: 4px;
      }

      .straight-schedule {
        background: #888a98;
      }
    }
  }

  .order-detail-information {
    width: 100%;
    height: max-content;
    background: #ffffff;
    border-radius: 8px;

    .title {
      width: 100%;
      height: 68px;
      display: flex;
      align-items: center;
      background: #f9f9f9;
      font-size: 24px;
      font-weight: 600;
      color: #282828;

      .straight {
        width: 16px;
      }
    }
    .order-detail-content-theme2 {
      padding-bottom: 24px;
      .receiver-information {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 24px 0 0 0;

        .left-contain {
          width: 50%;
          padding: 0 16px;

          .group-item {
            display: grid;
            text-align: left;
            vertical-align: middle;
            row-gap: 10px;

            .label {
              font-size: 16px;
              font-weight: 500;
              color: #959595;
            }

            .value {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 150% */
              letter-spacing: 0.3px;
              color: #282828;
            }

            .location-store {
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
            }
            .location-address {
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }

            .note-contain {
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
            }

            .schedule-value {
              background: #ff8c21;
              width: fit-content;
              padding: 8px;
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              border-radius: 8px;
            }
          }
        }

        .location-contain {
          width: 100%;
        }

        .right-contain {
          width: 50%;
          padding: 0 16px;

          .group-item {
            display: grid;
            text-align: left;
            vertical-align: middle;
            row-gap: 10px;

            .label {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              letter-spacing: 0.3px;
              color: #959595;
            }

            .value {
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 150% */
              letter-spacing: 0.3px;
            }
          }
        }
      }

      .receiver-information-2 {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 24px 0 0 0;

        .left-contain {
          width: 100%;
          padding: 0 16px;

          .group-item {
            display: grid;
            text-align: left;
            vertical-align: middle;
            row-gap: 10px;

            .label {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              letter-spacing: 0.3px;
              color: #737373;
            }

            .value {
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 150% */
              letter-spacing: 0.3px;
              color: var(--TEXT, #282828);
            }

            .location-store {
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
            }
            .location-address {
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }

            .note-contain {
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
            }

            .schedule-value {
              background: #ff8c21;
              width: fit-content;
              padding: 8px;
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              border-radius: 8px;
            }
          }
        }

        .location-contain {
          width: 100%;
        }

        .right-contain {
          width: 50%;
          padding: 0 16px;

          .group-item {
            display: grid;
            text-align: left;
            vertical-align: middle;
            row-gap: 10px;

            .label {
              color: #737373;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              letter-spacing: 0.3px;
            }

            .value {
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 150% */
              letter-spacing: 0.3px;
            }
          }
        }
      }
      .receive-address {
        width: 100%;
        height: max-content;
        padding: 0 0 36px 0;

        .group {
          display: flex;
          align-items: center;
          padding: 0 23px;
          gap: 30px;

          .from-address {
            width: 45px;
            text-align: center;
            .straight {
              height: 23px;
              border: 1px dashed #db4d29;
              height: max-content;
              margin: 14px 0;
            }
          }

          .from-address-straight {
            padding: 5px 0;
          }

          .from-address-detail {
            display: grid;
            row-gap: 5px;

            .from-address-detail-title {
              color: #959595;
              font-size: 16px;
              font-weight: 500;
            }

            .from-address-detail-content {
              font-weight: 600;
              color: #282828;
              font-size: 20px;
            }
          }
        }
      }

      .order-information-address {
        padding-top: 24px;
        .sender-address,
        .receiver-address {
          display: flex;
          gap: 24px;
          .right-information-address {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .label {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              color: #959595;
            }
          }
        }
        .center-address {
          display: flex;
          width: 60px;
          .border-address {
            border: 1px dashed #db4d29;
            height: 20px;
            margin: 10px auto;
          }
        }
      }

      .bank-transfer-detail-theme2 {
        padding: 16px;
        .bank-tranfer-payment-detail-theme2 {
          .title {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }
          .content-bank-account {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 12px;
            .bank-account-info {
              margin-left: 32px;
              flex: 4;
            }
            .qr-code {
            }
          }
        }
      }
    }
  }

  .order-detail-product-list {
    width: 100%;
    height: max-content;
    background: #ffffff;
    border-radius: 8px;

    .title {
      width: 100%;
      height: 68px;
      display: flex;
      align-items: center;
      background: #f9f9f9;
      font-size: 24px;
      font-weight: 700;
      color: #282828;
      line-height: 32px;

      .straight {
        width: 16px;
      }
    }

    .order-detail-product-list-content {
      width: 100%;
      height: max-content;
      background: #fff;

      .group {
        display: flex;
        gap: 24px;
        padding: 24px 16px;

        .image {
          width: 80px;
          height: 80px;
          border-radius: 12px;
        }

        .wrapper-product-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .product-item {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .topping-group {
              padding: unset !important;
            }

            .product-item-group {
              display: flex;
              align-items: start;

              .product-header-left {
                width: calc(100% / 2);
                display: flex;

                .product-item-name {
                  .ant-typography {
                    font-size: 16px;
                    font-weight: 600;
                    color: #282828;
                    line-height: 24px;
                    margin-bottom: 0;

                    .product-item-option {
                      font-size: 16px;
                      font-weight: 500;
                      color: #959595;
                    }
                  }
                }
              }

              .product-header-right {
                min-width: calc(100% / 2);
                display: flex;
                align-items: start;
                justify-content: space-between;
                max-width: 100vw;
                .product-item-quantity {
                  width: 50%;
                  text-align: center;
                  font-size: 16px;
                  color: #282828;
                  font-weight: 600;
                  &::before {
                    content: "x";
                    margin-right: 4px;
                  }
                }

                .product-item-price {
                  text-align: right;
                  width: 50%;
                  display: grid;
                  vertical-align: middle;
                  font-size: 16px;
                  font-weight: 600;
                  color: #282828;

                  .product-item-price-discount {
                    font-size: 14px;
                    font-weight: 500;
                    color: #959595;
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    .price {
                      text-decoration: line-through;
                    }

                    .discount-percent-value {
                      background: #ffa318;
                      border-radius: 20px;
                      color: #fff;
                      font-size: 14px;
                      font-weight: 600;
                      min-width: 46px;
                      min-height: 24px;
                      text-align: center;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-left: 5px;
                      padding: 3px 10px;
                    }
                  }
                }
              }

              .product-topping-name {
                font-size: 14px;
                font-weight: 500;
                color: #959595;
                line-height: 20px;
              }

              .product-option {
                width: 100%;
              }

              .product-topping-quantity {
                width: calc(50% / 2);
                text-align: center;
                font-size: 16px;
                color: #959595;
                font-weight: 500;
                white-space: nowrap;
              }

              .product-topping-price {
                text-align: right;
                width: calc(50% / 2);
                font-size: 16px;
                font-weight: 500;
                color: #959595;
                .topping-price-col {
                  display: flex;
                  flex-direction: column;
                  .topping-original-price {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    text-decoration-line: line-through;
                    color: #959595;
                    display: flex;
                    justify-content: flex-end;
                  }
                }
              }

              .product-name {
                color: #282828;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
              }
            }

            .product-item-group-not-padding {
              padding: 12px 0 0;
            }
          }

          .item-note {
            display: flex;
            width: 100%;
            position: relative;
            align-items: center;
            margin-top: 4px;
            .note-text {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #282828;
              margin-left: 8px;
            }
          }
        }
      }

      .straight {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .item {
          width: calc(100% - 32px);
          border: 1px solid #f3f3f3;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: end;
      .image {
        width: 100%;
        height: 100%;
      }

      .total-order-container {
        display: grid;
        align-items: center;
        position: absolute;
        justify-content: end;
        row-gap: 8px;
        width: 434px;
        padding-right: 16px;

        .group {
          display: flex;
          width: 434px;
          .label,
          .value {
            width: 50%;
            text-align: right;
            font-size: 16px;
            font-weight: 500;
            color: #282828;

            .order-detail-arrow {
              margin-left: 10px;
              position: relative;
              top: -2px;
            }
          }

          .payment-method {
            display: grid;
            justify-content: end;
          }

          .label-total {
            font-size: 20px;
            font-weight: 700;
            color: #282828;
          }

          .value-total {
            font-size: 20px;
            font-weight: 700;
            color: #db4d29;
          }

          .value {
            .paid-status {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 28px;
              padding: 6px 8px;
            }
            .status-unpaid {
              color: #fecc2f;
              background: rgba(254, 204, 47, 0.1);
            }

            .status-paid {
              color: #4ead4e;
              background: rgba(78, 173, 78, 0.2);
            }
          }
        }
        .group-note {
          flex-direction: column;
          align-items: end;
          padding-left: 12px;
          .title-note {
            font-weight: 700;
          }
          .content-note {
            text-align: right;

            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #282828;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
.product-name-has-none-options {
  min-height: 80px;
  display: flex;
  align-items: center;

  @media (max-width: 425px) {
    min-height: 0;
  }
}
.product-quantity-has-none-options {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 425px) {
    min-height: 0;
  }
}
.product-price-has-none-options {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 425px) {
    min-height: 0;
  }
}
//Responsive
@media (max-width: 880px) {
  .order-detail-container {
    .order-detail-header {
      .header-image {
        grid-template-columns: 1fr repeat(2, 90px);
        .header-status {
          .receive-point-container {
            .receive-point-text {
              width: 110%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 740px) {
  .order-detail-popover {
    padding-left: calc((100vw - 363px) / 2) !important;
  }
}
.ant-popover.order-detail-popover {
  padding: unset;
  position: absolute;
  .order-detail-popover-content {
    width: 363px;
    display: grid;
    padding: 12px;
    gap: 16px;

    .group-body {
      padding-top: 4px;
      .list-item {
        margin-left: 24px;
        font-size: 20px;
      }
      .discount-fee-text {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;
        align-items: center;

        .discount-text {
          text-transform: capitalize;
          max-width: 75%;
          color: #282828;
          .discount-loyalty-point {
            color: #ff8c21;
          }
        }

        .discount-value {
          white-space: nowrap;
          color: #282828;
        }
      }
    }

    .discount-content-label {
      display: flex;
      font-weight: 700;
      font-size: 16px;
      padding-bottom: 6px;
      border-bottom: 1px dashed #d1d1d1;
      height: 24px;
    }

    .discount-content-text {
      display: flex;
      justify-content: space-between;
      border-top: 0.650038px dashed #d1d1d1;
      padding-top: 4px;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
    }

    .discount-fee-label {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4px;
      border-bottom: 1px dashed #d1d1d1;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: #282828;
    }
  }
}

@media (max-width: 740px) {
  .profile-right-side-order-detail {
    padding: unset !important;
  }

  .order-detail-container {
    width: 100%;
    .order-detail-header {
      .header-icon-detail {
        max-width: 50%;
        margin-right: 0px;
        margin-top: 23px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .header-image {
        width: 100%;

        .back-icon {
          left: 5%;
        }

        .image {
          width: 100%;
        }

        .header-status {
          margin-top: 12px;
          margin-bottom: 12px;
          margin-left: 16px;
          .order-detail-code {
            font-size: 16px;
          }
          .order-delivery {
            display: grid;
            grid-template-columns: none;
            gap: 8px;
            .order-detail-delivery-method {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 150% */
              letter-spacing: 0.3px;
            }
          }

          .order-detail-status {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            width: max-content;
            max-width: 200px;
          }

          .order-detail-created-platform {
            font-size: 14px;
          }
          .content-review {
            &-star {
              margin-top: 30px;
              min-width: 350px;
            }
            &-label {
              margin-top: 2px;
            }
            &-comment {
              max-width: 350px;
            }
          }
        }
      }
      .progress-bar {
        display: grid;
        align-items: center;
        height: max-content;
        row-gap: 10px;
        padding: 16px;
        justify-content: start;

        .progress-bar-item {
          display: flex;
          gap: 16px;

          .status-describe {
            display: grid;
            row-gap: 8px;
            white-space: nowrap;
            align-items: center;

            .created-status-name {
              font-size: 16px;
            }

            .created-time {
              font-size: 14px;
              text-align: left;
              padding: 12px;
            }
          }

          .created-status-icon {
            svg {
              width: 60px;
              height: 60px;
            }
          }
        }

        .straight {
          width: 3px;
          height: 20px;
          background: #db4d29;
          margin-left: 14%;
          border-radius: 20px;
        }

        .straight-schedule {
          background: #888a98;
        }
      }
    }

    .order-detail-information {
      .order-detail-content-theme2 {
        padding-top: 24px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        .receiver-information {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 24px;
          align-items: center;
          padding: 0px;
          row-gap: 24px;

          .left-contain,
          .right-contain {
            width: 100%;
            .group-item {
              row-gap: 4px;
              .value {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              }
            }
          }
          .location-contain {
            width: 100%;
          }
        }
        .receiver-information-2 {
          gap: 24px;
          align-items: center;
          padding: 0px;
          row-gap: 10px;

          .left-contain,
          .right-contain {
            width: 100%;
            .group-item {
              .label {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                letter-spacing: 0.3px;
              }
              .value {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
                letter-spacing: 0.3px;
              }
            }
          }
          .location-contain {
            width: 100%;
          }
        }
        .receive-address {
          .group {
            .from-address-detail {
              .from-address-detail-content {
                font-size: 16px;
              }
            }

            .from-address {
              svg {
                width: 45px;
                height: 56px;
              }
            }
          }
        }

        .bank-transfer-detail-theme2 {
          .bank-tranfer-payment-detail-theme2 {
            .content-bank-account {
              .bank-account-info {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }

    .order-detail-product-list {
      width: 100%;

      .order-detail-product-list-content {
        width: 100%;
        display: grid;

        .group {
          padding: 12px;
          padding-right: 16px;

          .product-item {
            .product-item-group {
              display: grid;

              .product-header-left {
                width: 100%;

                .image {
                  width: 60px;
                  height: 60px;
                  margin-right: 16px;
                }

                .product-item-name {
                  .ant-typography {
                    font-size: 16px;
                  }
                }
              }

              .product-header-right {
                .product-item-quantity {
                  width: 60px;
                  text-align: left;
                }

                .image {
                  width: 60px;
                  height: 0px;
                  margin: unset;
                }
              }

              .product-option {
                padding: unset;
              }

              @media (max-width: 425px) {
                display: flex;
                flex-direction: column;

                .product-header-left {
                  width: 100% !important;
                }

                .product-header-right {
                  width: 100%;

                  .product-item-quantity {
                    display: flex;
                    justify-content: flex-start;

                    &::before {
                      content: "x";
                      margin-right: 4px;
                    }
                  }
                }
              }
            }

            .topping-group {
              display: flex;

              .product-option {
                width: calc(100% - 60px);
                white-space: unset;
              }

              .product-topping-quantity {
                width: calc((100% - 50%) / 2);
                text-align: right;
              }

              .product-topping-price {
                width: calc((100% - 50%) / 2);
              }

              .image {
                width: 60px;
                height: 0px;
                margin-right: 16px;
              }

              .product-name {
                margin-left: -2px;
              }

              @media (max-width: 425px) {
                flex-direction: row;
              }
            }
          }
        }
      }

      .footer {
        display: flex;
        align-items: self-start;
        justify-content: center;
        width: 100%;
        border-top: 1px solid #f3f3f3;
        .total-order-container {
          display: grid;
          align-items: center;
          position: absolute;
          justify-content: space-between;
          row-gap: 16px;
          width: calc(100% - 32px);
          padding-top: 16px;
          padding-right: unset;

          .group {
            width: calc(100vw - 36px);
            .label {
              text-align: left;
              margin-left: 12px;
            }

            .value {
              margin-right: 12px;
            }
          }
          .group-note {
            flex-direction: column;
            align-items: start;
            .content-note {
              text-align: left;

              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #282828;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .order-detail-container {
    .order-detail-header {
      .header-image {
        grid-template-columns: repeat(2, 1fr);
        .header-status {
          .order-detail-reason-content {
            width: calc(100vw - 80px);
            display: flex;
            line-height: 20px;
          }
          .content-review {
            &-comment {
              max-width: 330px;
            }
          }
        }
      }
    }
    .order-detail-product-list {
      .order-detail-product-list-content {
        .group {
          .wrapper-product-item {
            .product-item {
              .product-item-group {
                .product-header-right {
                  justify-content: space-evenly;
                  .product-item-price {
                    width: unset;
                    min-width: 50%;
                  }
                }
              }
              .topping-group {
                .product-topping-quantity {
                  width: 25%;
                }
              }
            }
          }
        }
      }
    }
    .order-detail-information .order-detail-content-theme2 .order-information-address .center-address {
      width: 9%;
    }
  }
}

@media (max-width: 500px) {
  .order-detail-container {
    .order-detail-product-list {
      .order-detail-product-list-content .group .wrapper-product-item {
        .product-item .product-item-group {
          .product-topping-price {
            width: 35%;
            word-break: break-word;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .order-detail-container {
    .order-detail-header {
      .header-image {
        grid-template-columns: repeat(2, 1fr);
        .header-status {
          .receive-point-container {
            .receive-point-text {
              width: calc(100vw - 80px);
            }
          }
          .content-review {
            &-comment {
              max-width: 300px;
            }
          }
        }
      }
    }
  }
}