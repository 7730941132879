.loyalty-point-member-ship-theme2 {
  .header {
    padding: 16px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-left {
      .member-text {
        color: #000;
        font-size: 24px;

        font-weight: 600;
        line-height: 21.135px;
      }
    }

    .header-right {
      display: flex;
      justify-content: space-between;
      width: 200px;

      .member-priority-text {
        color: #282828;
        text-align: center;
        font-size: 14px;

        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .container {
    min-height: 88px;
    background: #f4f4f4;
    padding-right: 24px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .avaiable-point {
      padding: 16px;
      display: flex;

      .avaiable-point-left {
        .text {
          color: #282828;
          font-size: 14px;

          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.3px;
        }

        .score {
          color: #ffb758;
          font-size: 24px;

          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0.3px;
        }
      }

      .premium-icon {
        width: 40px;
        height: 40px;
      }
    }

    .money {
      .icon {
        margin-right: 12px;
      }

      .total-money-text,
      .money-number {
        display: flex;
        align-items: center;

        .currency {
          min-width: 120px;
          text-align: end;
          color: #ffb758;
          font-size: 14px;

          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0.3px;
        }

        .purchase-order,
        .total-amount-spent {
          min-width: 140px;
          color: #282828;
          font-size: 14px;

          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.3px;
        }
      }
    }

    .exprie-date {
      color: #b8b8b8;
      font-size: 14px;

      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.3px;
      padding-left: 28px;

      .score {
        color: #282828;
      }
    }
  }

  .history-point {
    display: block;

    &::after {
      content: "";
      border-radius: 12px;
      background-color: red;
      /* Other styles */
    }
  }

  .header-menmber-text {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ccc;

    .member-text {
      margin-left: 8px;
    }
  }

  .member-content {
    flex-direction: column;
    padding: 16px;

    .member-ship-level {
      display: flex;
      flex-flow: row;
      gap: 16px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .rank {
        line-height: 32px;
      }

      .text {
        max-width: unset;
        flex: 1;
      }

      .description {
        margin-top: 16px;
      }

      .thumbnail {
        height: 32px;
        overflow: hidden;

        &__image {
          width: 32px;
          height: 32px;
          border-radius: 32px;
          box-shadow: 0px 0px 13.333333969116211px 0px #0000001A;
        }
      }

      .description {
        color: #000;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        word-break: break-all;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .page-container-loyalty-point {
    .profile-right-side {
      padding: 0 !important;
    }
  }
}

@media (max-width: 575px) {
  .loyalty-point-member-ship-theme2 {
    .header {
      display: flex;
      align-items: unset;
      justify-content: start !important;
      text-align: start;
      flex-direction: column;
      width: 100%;
      height: auto;
      border-radius: 12px;
      background: #fff;
      padding: 8px 12px;
      position: relative;
      top: 0px;

      .header-left {
        display: flex;
        align-items: center;

        .member-text {
          font-size: 20px;
          margin-left: 12px;
        }
      }

      .header-right {
        margin-top: 16px;
        width: 100% !important;

        .member-priority-text {
          width: 100% !important;
          margin-left: 12px;
          text-align: start;
        }
      }
    }

    .container {
      padding-right: 0px;
      height: auto;

      .avaiable-point {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;

        .avaiable-point-left .score {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .money {
        width: 100%;

        .total-money-text,
        .money-number {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .purchase-order,
          .total-amount-spent {
            position: absolute;
            transform: translateX(30px);
          }

          .icon {
            margin-right: -40px;
          }
        }
      }

      .exprie-date {
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media (max-width: 576px) {
  .loyalty-point-member-ship-theme2 {
    .member-content {
      .description {
        width: 100%;
      }
    }

    .container {
      .exprie-date {
        padding-left: 0px;
      }
    }
  }
}