.popover-overwrite {
    .ant-modal-content {
        .ant-modal-header {
            .ant-modal-title {
                font-size: 20px;
                font-weight: 700;
                line-height: 25px;
                text-align: center;
                color: #1E1E1E;
                text-transform: uppercase;
            }
        }

        .ant-modal-body {
            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: transparent;
              }
              
              &::-webkit-scrollbar {
                width: 0 !important;
                height: 0;
              } 
              
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #eeeeee;
              }
        }

        .ant-modal-footer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;

            .ant-btn {
                font-size: 18px;
                font-weight: 600;
                line-height: 22px;
                border-radius: 12px;
                padding: 16px 24px;
                width: calc(100% / 2);
                height: auto;

                &:disabled {
                    opacity: 0.5;
                }
            }

            .ant-btn-default {
                color: #DB4D29;
                border: 1px solid #DB4D29;
            }

            .ant-btn-primary {
                color: #FBFBFB;
                border: 1px solid #DB4D29;
                background-color: #DB4D29;
            }
        }

        @media (max-width: 1199px) {
            .ant-modal-footer {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .ant-btn {
                    width: 100%;
                }
            }
        }
    }
}

.review-completed-order {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .wrapper-order-items {
        padding: 20px 16px;
        border: 1px solid #CDCDCD;
        border-radius: 12px;

        .rated-order {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            span {
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                color: #FF7B00;
            }
        }

        .order-items {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-height: 270px;
            overflow: auto;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: transparent;
            }
            
            &::-webkit-scrollbar {
                width: 4px;
                height: 0;
            } 
            
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #eeeeee;
            }

            .order-item {
                display: flex;
                gap: 16px;

                .thumbnail img {
                    width: 70px;
                    height: 70px;
                    border-radius: 10px;
                }

                .product-price {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #959595;

                    .price-name {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 22px;
                        color: #000000;
                        margin-bottom: 6px;
                    }

                    .combo-price-name {
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 18px;
                        color: #000000;
                        margin-bottom: 4px;
                    }

                    .toppings {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        margin-bottom: 6px;
                    }

                    .options {
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }

    .wrapper-comment {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        border-radius: 12px;
        padding: 12px;
        border: 1px solid #CDCDCD;

        textarea {
            border: none;
            box-shadow: none;
            resize: none;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 4px;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #e0e0e0;
            }

            &::placeholder {
                font-size: 15px;
                font-weight: 400px;
                line-height: 20px;
                color: #444242;
            }
        }

        .count {
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            color: #979797;
        }
    }
}