.order-main {
  padding-top: 20px;
}

.history-box {
  .ant-drawer-body {
    padding-bottom: 0px;
    display: flex;
  }
  .history-box-not-found {
    width: 100%;
    height: 100%;
  }
  .history-content > div:last-child {
    padding-bottom: 5px;
  }
}

.history-box-column-reverse {
  .ant-drawer-body {
    flex-direction: column-reverse;
  }
}
.history-box-column {
  .ant-drawer-body {
    flex-direction: column;
  }
}

.order {
  * {
    font-family: inherit;
  }

  height: 100vh;
  overflow: hidden auto;
  background-color: #f2f5fc;

  .ant-layout-header {
    padding-inline: 0;
    background: transparent;
  }

  .order-call-actions {
    display: grid;
    gap: 15px;
  }

  .history-button {
    display: flex;
    gap: 10px;

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }

  .store-address {
    text-align: center;
  }

  .bg-border {
    margin-top: -100px;
    width: 100%;
    //background-color: #e2e6fa;
    background: linear-gradient(#e2e6fa 0%, #f2f5fc 100%);

    svg {
      width: 100%;
    }
  }

  .action-button-group {
    margin-top: -509px;
    position: relative;
    z-index: 1;
  }

  .top-bg {
    margin-top: -75px;
  }

  .store-info {
    position: relative;
    z-index: 1;
    padding-top: 5px;
  }

  .order-food-icon {
    svg {
      width: 146px;
      margin-top: -225px;
    }
  }

  .area-info {
    padding-bottom: 19px;
  }

  .history-button {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .btn {
    &-order {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .icon-instore-action {
        width: unset !important;
        height: unset !important;
      }
    }
  }
}

.order-detail-instore {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  cursor: pointer;

  .content-center {
    height: 80px;
    border-radius: 16px;
    background: #d5e9fd;
    padding: 16px 32px;
    max-width: 50%;
    flex: 0 0 100%;
    justify-content: space-between;

    .order-detail-instore-title {
      cursor: pointer;
      float: left;
      display: flex;
      align-items: center;

      .order-detail-instore-title-child {
        padding-left: 12px;
        color: #282828;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .order-detail-instore-id {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #6245ff;
      font-size: 18px;
      font-weight: 400;
      padding-right: 8px;
    }
  }
}

// Button send message to waiter
.btn-send-message {
  width: 280px;
  height: 56px;
  border-radius: 12px;
  background-color: var(--primaryColor);
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  &:hover {
    background-color: #6640ff !important;
  }
}

.message-box {
  border-radius: 20px 20px 0 0;

  * {
    font-family: inherit;
  }
}

.call-waiter-modal {
  .ant-drawer-header {
    border-bottom: none;
  }
}

// Notify dialog confirm leave
.confirm-leave-notify-dialog-instore {
  .ant-modal-content {
    .ant-modal-header {
      background: var(--global-button-background-color, #db4d29);
    }

    .ant-modal-body {
      padding: 18px;

      .notification-dialog-content {
        .confirm-leave {
          color: #282828;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.15px;
        }
      }
    }

    .ant-modal-footer {
      .ignore-btn {
        background: none;
        color: var(--global-button-background-color, #db4d29);
        border: none;
        outline: none;
        cursor: pointer;
        box-shadow: none;
      }

      .confirm-leave-btn {
        background: var(--global-button-background-color, #fff);
        color: var(--global-button-text-color, #db4d29);
        border: none;
      }
    }
  }
}

@media (min-width: 769px) {
  .order {
    .history-button {
      padding-top: 35px;
      padding-bottom: 50px;
    }

    .area-info {
      padding-bottom: 30px;
    }

    .order-food-icon svg {
      width: 210px;
      margin-top: -145px;
    }

    .action-button-group {
      margin-top: -650px;
    }

    .bg-border {
      margin-top: -100px;
    }
  }

  .btn-send-message {
    height: 80px;
  }
}

@media (min-width: 1700px) {
  .order-detail-instore {
    .content-center {
      width: 100%;
      max-width: 41%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .order-detail-instore {
    padding-left: unset;
    padding-right: unset;

    .content-center {
      width: 100%;
      max-width: 65%;
    }
  }
}

@media (max-width: 992px) {
  .order-detail-instore {
    .content-center {
      width: 100%;
      max-width: 85%;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .order-detail-instore {
    padding-left: 10px;
    padding-right: 10px;

    .content-center {
      width: 100%;
      max-width: 65%;
    }
  }
}

//Toast style width
@media (max-width: 576px) {
  .ant-notification-bottom:has(> .toast-message) {
    min-width: 288px;
    width: auto;
  }

  .order {
    .bg-border {
      margin-top: -165px;
    }

    .history-button {
      padding-top: 32px;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .order-detail-instore {
    padding-left: 10px;
    padding-right: 10px;

    .content-center {
      padding: 16px 10px;
      width: 100%;
      max-width: 100%;

      .order-detail-instore-title {
        .order-detail-instore-title-child {
          font-size: 14px;
        }
      }

      .order-detail-instore-id {
        font-size: 13px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .order {
    .top-bg {
      margin-top: -105px;

      .rectangle {
        height: 100% !important;

        &-wrapper {
          height: 153px;
        }
      }
    }

    .store-info {
      padding-top: 30px;

      .ant-row {
        row-gap: 12px !important;
      }

      &__branch-name {
        font-size: 1.5rem;
        line-height: 32px;
        font-weight: 700;
        text-align: center;
      }

      &__address {
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
      }
    }

    .order-food-icon {
      svg {
        width: auto !important;
        height: 290px !important;
        margin-top: -160px !important;
      }
    }

    .area-info {
      padding-bottom: 60px !important;
    }

    .btn {
      &-order {
        padding: unset !important;

        .icon-instore-action {
          padding: unset !important;

          > div {
            width: 120px;
            height: 120px;
          }
        }

        .text-wrapper {
          font-size: 2rem !important;
          font-weight: 700;
          text-align: center;
        }
      }

      &-other-action {
        .icon-instore-action {
          padding: unset !important;

          > div {
            width: 72px;
            height: 72px;
          }
        }

        .text-wrapper {
          font-size: 1.5rem !important;
          font-weight: 600;
          text-align: center;
        }
      }

      &-history {
        .history-button {
          font-weight: 500;
          padding: unset !important;
          margin: 60px 0 35px;

          p {
            font-size: 1.25rem !important;
          }
        }
      }
    }
  }

  .call-waiter-modal {
    &__title {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 32px;
    }

    &__description {
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 769px) {
  .order {
    .area-info {
      padding-block: 60px !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .order {
    .top-bg {
      margin-top: -75px;

      .rectangle {
        &-wrapper {
          height: 134px;
        }
      }
    }

    .store-info {
      .ant-row {
        row-gap: 4px !important;
      }

      &__branch-name {
        font-size: 1.25rem;
        line-height: 28px;
      }

      &__address {
        font-size: 0.875rem;
        line-height: 20px;
      }
    }

    .order-food-icon {
      svg {
        height: 191px !important;
        margin-top: -170px !important;
      }
    }

    .area-info {
      padding-block: 24px !important;
    }

    .btn {
      &-order {
        .icon-instore-action {
          > div {
            width: 80px;
            height: 80px;
          }
        }

        .text-wrapper {
          font-size: 1.3125rem !important;
        }
      }

      &-other-action {
        padding: 16px !important;

        .icon-instore-action {
          > div {
            width: 48px;
            height: 48px;
          }
        }

        .text-wrapper {
          font-size: 1rem !important;
          padding-top: 10px !important;
        }
      }

      &-history {
        .history-button {
          margin: 32px 0 27px;

          p {
            font-size: 1rem !important;
          }
        }
      }
    }
  }

  .call-waiter-modal {
    &__title {
      font-size: 1.5rem;
    }

    &__description {
      font-size: 1rem;
    }
  }
}
