.hyperlink-container-theme2 {
  display: flex;
  align-items: center;
  color: #959595;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  width: inherit;
  overflow: hidden;
  margin-bottom: 8px;
  .cursor-pointer {
    white-space: nowrap;
    cursor: pointer;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .title-text {
    font-weight: 700;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Tablet
@media screen and (max-width: 1200px) {
  .hyperlink-container-theme2 {
    .title-text {
      max-width: 90vw;
    }
  }
}

// Mobile
@media screen and (max-width: 575px) {
  .hyperlink-container-theme2 {
    border-radius: 8px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    .title-text {
      max-width: 90vw;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
