.flash-sale-banner-zero {
  justify-content: flex-end;
  margin-left: 10px;
  --fcc-flip-duration: 0.5s;
  --fcc-digit-block-width: 25px;
  --fcc-digit-block-height: 43px;
  --fcc-digit-font-size: 24px;
  --fcc-digit-color: #ffffff;
  --fcc-divider-color: rgba(0, 0, 0, 0.1);
  --fcc-divider-height: 1px;
  --fcc-background: #ff8718;
  --fcc-separator-size: 4px;
  --fcc-separator-color: #ffffff;
  ._2EBbg {
    font-weight: 700;
  }
  ._2EBbg:first-child {
    margin-right: 3px;
  }
  ._2EBbg {
    div:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    div:nth-child(2) {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    ._3luxx._2kgZC {
      transform: none!important;
    }
  }
  ._2hRXr {
    margin-left: 8px;
    margin-right: 8px;
  }
}
/* Mobile - ant-xs */
@media (max-width: 575px) {
  .flash-sale-banner-zero {
    --fcc-digit-block-width: 20px !important;
    --fcc-digit-block-height: 24px !important;
    --fcc-digit-font-size: 12px !important;
    --fcc-separator-size: 3px !important;
    
    ._3cpN7 {
      ._2EBbg:first-child {
        margin-right: 2px;
      }
      ._2EBbg {
        div:first-child {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        div:nth-child(2) {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
    ._2hRXr {
      margin-left: 6px;
      margin-right: 6px;
    }
  }
}
