.cart-page {
  height: 100%;
  .container-cart {
    padding: 86px 24px 16px;
    .check_out_product {
      height: calc(100% - 70px);
      .product_summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .total {
          display: flex;
          .shoppingCart {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #db4d29;
            margin-right: 5px;
          }
          .quantity {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #282828;

            .total-quantity {
              padding-right: 5px;
            }
          }
        }
        .add {
          display: flex;
          align-items: center;
          .add_icon {
            cursor: pointer;
            display: flex;
          }
          .add_button {
            margin-left: 14px;

            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #db4d29;
            cursor: pointer;
          }
        }
      }
      .product_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        height: 48px;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
        margin-bottom: 12px;
        padding-left: 12px;
      }
      .product_title.product-title-mobile {
        display: none;
      }
      .product_detail {
        overflow-y: auto;
        height: calc(100vh - 285px);
        .noProductInCart {
          margin-top: 127px;
          text-align: center;
        }
        .noProductInCartText {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-top: 24px;
        }
        .checkout-order-item-theme-2 .order-item .box-combo {
          width: 100%;
        }
      }
      .product_detail::-webkit-scrollbar {
        display: none;
      }
    }
    .button-create-order-and-payment {
      * {
        font-family: inherit;
      }
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      padding: 12px 16px;
      .create-order-button {
        width: 100%;
        height: 48px;
        background: #db4d29;
        border-radius: 12px;
        span {
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .create-order-button:disabled {
        background: #ecb9ac;
      }
    }
  }
}

@media (max-width: 576px) {
  .container-cart {
    padding: 16px 24px;
    .check_out_product {
      .product_summary {
        align-items: normal !important;
        flex-direction: column;
        gap: 8px;
      }
      .product_detail {
        overflow-y: auto;
        height: calc(100vh - 320px) !important;
      }
    }
  }
}
